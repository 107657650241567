import { Box, styled,Typography } from "@mui/material";

export const OuterBox = styled(Box)({
  width: "100%",
  height: "auto",
  marginTop: "70px",
  marginLeft: "51px",
  marginRight: "54px",
  "@media (max-width: 599px)": {
    marginTop: "68px",
    marginLeft: "19px",
    marginRight: "19px",
  },
  "@media (min-width: 600px) and (max-width: 680px)": {
    width: "90%",
    marginLeft: "30px",
    marginRight: "34px",
  },
  "@media (min-width: 679px) and (max-width: 690px)": {
    width: "90%",
    marginTop: "70px",
    marginLeft: "30px",
    marginRight: "50px",
  },
  "@media (min-width: 691px) and (max-width: 799px)": {
    width: "92%",
    marginTop: "70px",
    marginLeft: "30px",
    marginRight: "38px",
  },
  "@media (min-width: 800px) and (max-width: 999px)": {
    width: "96%",
    marginTop: "70px",
    marginLeft: "30px",
    marginRight: "38px",
  },
});

export const MainBox = styled(Box)({
  borderRadius: "15px",
});

export const tabsStyles = {
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTab-root": {
    textTransform: "none",
    padding: "8px 16px",
    borderRadius: "4px",
    border: "1px solid",
    borderColor: "border.tabborder",
    minWidth: "120px",
    color: "text.tabcolor",
    fontWeight: "500",
    backgroundColor: "background.tabnotselect",
    "&:not(:last-of-type)": {
      marginRight: "0px",
    },
    "&.Mui-selected": {
      backgroundColor: "background.tabselect",
      color: "#0293d2",
      borderColor: "border.tabborder",
    },
    "&:first-of-type": {
      borderRight: "none",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
    },
    "&:last-of-type": {
      borderLeft: "none",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
    },
    "@media (max-width: 600px)": {
      fontSize: "0.78rem !important",
      padding: "8px auto",
    },
    "@media (min-width: 600px) and (max-width: 1199px)": {
      fontSize: "0.78rem !important",
      padding: "8px auto",
    },
  },
};

export const AgentOuterBox = styled(Box)({
  width: "90%",
  height: "auto",
  marginTop: "70px",
  marginLeft: "51px",
  marginRight: "54px",
  "@media (max-width: 599px)": {
    marginTop: "68px",
    marginLeft: "19px",
    marginRight: "19px",
  },
  "@media (min-width: 600px) and (max-width: 680px)": {
    width: "90%",
    marginLeft: "30px",
    marginRight: "34px",
  },
  "@media (min-width: 679px) and (max-width: 690px)": {
    width: "90%",
    marginTop: "70px",
    marginLeft: "30px",
    marginRight: "50px",
  },
  "@media (min-width: 691px) and (max-width: 799px)": {
    width: "92%",
    marginTop: "70px",
    marginLeft: "30px",
    marginRight: "38px",
  },
  "@media (min-width: 800px) and (max-width: 999px)": {
    width: "96%",
    marginTop: "70px",
    marginLeft: "30px",
    marginRight: "38px",
  },
  "@media (min-width: 1000px) and (max-width: 1199px)": {
    width: "98%",
    marginTop: "70px",
    marginLeft: "40px",
    marginRight: "40px",
  },
  "@media (min-width: 1200px) and (max-width: 1299px)": {
    // 110%
    width: "87%",
  },
  "@media (min-width: 1300px) and (max-width: 1399px)": {
    // 100%
    width: "88%",
  },
  "@media (min-width: 1400px) and (max-width: 1599.98px)": {
    // 90%
    width: "89%",
  },
  "@media (min-width: 1600px) and (max-width: 1799.98px)": {
    // 80%
    width: "90%",
  },
  "@media (min-width: 1800px) and (max-width: 1999.98px)": {
    // 75%
    width: "90%",
  },
});

