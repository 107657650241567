import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
// import DrawerSidebar from "../../components/common/DrawerSidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//Import whatsapp notification audio
import notificationSound from "../../../assets/audios/whatsapp_notification.mp3";
import WhatsappContainer from "./WhatsappContainer";
import { debounce } from "../../../utils/helperFuncion";
import { getAllGroups } from "../../../store/slices/clientSlice/groupSlice";
import apiClient from "../../../middleware/utils";
import { getAllTemplates } from "../../../store/slices/clientSlice/templateSlice";
import { saveSelectedChat, updateMessageStatus, updateNewMessage, updateReceivedMsgTime } from "../../../store/slices/clientSlice/messageSlice";
import { getAllChats, updateNewMessageCount } from "../../../store/slices/clientSlice/chatSlice";
import { addNotificaton } from "../../../store/slices/clientSlice/notificationSlice";
//Set the socket URL

const url = process.env.REACT_APP_SOCKET_URL;

function WhatsappChat() {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { selectedChat } = useSelector((state) => state?.messages);
   const { isAuthenticated } = useSelector((state) => state.auth);

   const [selected, setSelected] = useState({});
   const [newChat, setNewChat] = useState({});
   const selectedChatRef = useRef(selectedChat);

   useEffect(() => {
      selectedChatRef.current = selectedChat;
   }, [selectedChat]);

   const getNewlyReceivedMessage = async (messageId) => {
      try {
         const response = await apiClient.get(`/messages/last-message/${messageId}?`);
         const msg = response.data.message;
         dispatch(updateNewMessage({ newMessage: msg }));
      } catch (err) {}
   };

   useEffect(() => {
      // if (!isAuthenticated) {
      //    navigate("/login");
      // }

      dispatch(getAllTemplates());
      dispatch(getAllGroups());
   }, [dispatch, isAuthenticated]);

   const fetchChatsDebounced = debounce(() => {
      dispatch(getAllChats({ page: 1 }));
   }, 500); // Call at most once every 500ms

   useEffect(() => {
      const socket = io(url);
      const connectHandler = () => {
         // console.log("connected to websocket");
      };
      try {
         socket.on("connect", connectHandler);

         // SOCKET FOR IF A NEW MESSAGE RECEIVED
         socket.on("newMessage", (message) => {
            const { chatId, count, messageId } = message;
            // dispatch(clearChat())
            // dispatch(getAllChats({page:1}));
            fetchChatsDebounced();

            dispatch(updateNewMessageCount(message));

            setTimeout(() => {
               if (selectedChatRef.current?._id === chatId) {
                  getNewlyReceivedMessage(messageId);
               }
            }, 1000);
            // setNewReceivedMessage(message);
            // dispatch(addNotificaton(message));
            const sound = new Audio(notificationSound);
            sound.play();
         });

         //==== SOCKET FOR IF A NEW CHAT CREATED ===
         socket.on("newChat", (chat) => {
            setNewChat(chat);
            dispatch(addNotificaton(chat._id));
            dispatch(getAllChats({ page: 1 }));
            // const sound = new Audio(notificationSound);
            // sound.play();
         });

         //=== SOCKET FOR WHEN RECEIVED MSG TIME IS UPDATED ======
         socket.on("lastMessageTime", (chat) => {
            const { chatId, fullDocument } = chat;
            if (selectedChatRef.current?._id === chatId) {
               const { last_msg_received_at } = fullDocument;
               dispatch(updateReceivedMsgTime(last_msg_received_at));
            }

            // dispatch(updateLastRecivedMsgTime());
         });

         //==== SOCKET FOR WHEN THE CUSTOMER SELECT DND ====
         socket.on("dnd", (chat) => {

            if (selectedChatRef.current?._id === chat._id) {
               dispatch(saveSelectedChat(chat));
            }
            dispatch(getAllChats({ page: 1 }));
         });

         //======  SOCKET FOR IF THE MESSAGE STATUS UPDATED =====
         socket.on("statusChange", (message) => {
            if (selectedChatRef.current?._id === message.chat_id) {
               dispatch(updateMessageStatus(message));
            }
         });
      } catch (err) {}

      // Cleanup function
      return () => {
         socket.disconnect(); // Disconnect the socket when the component unmounts
      };
   }, []);

   return (
      <>
         {/* <DrawerSidebar /> */}
         <WhatsappContainer
         // newReceicedMessage={newReceicedMessage}
         // newChat={newChat}
         />
      </>
   );
}

export default WhatsappChat;
