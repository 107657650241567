import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import apiClient from "../../../middleware/utils";
import UserSection from "./UserSection";
import Chat from "./Chat";

function ChatMainContainer() {
   const dispatch = useDispatch();
   const [profile, setProfile] = useState({});

   const getBusinessProfile = async () => {
      try {
         const response = await apiClient.get(`/user/business_profile`);
         setProfile(response.data.data[0]);
      } catch (err) {}
   };
   useEffect(() => {
      getBusinessProfile();
   }, [dispatch]);

   const { messages, selectedChat } = useSelector((state) => state.messages);

   return (
      <Grid container sx={{ height: "100%" }}>
         <Grid
            item
            xs={12}
            md={4}
            sx={{
               backgroundColor: "green",
               height: "100%",
            }}
         >
            <UserSection
               profileData={profile}
               // chats={chats}
            />
         </Grid>
         <Grid
            item
            xs={12}
            md={8}
            sx={{
               position: "relative",
               width: "100%",
               height: "100%",
            }}
         >
            <Chat messages={messages} selectedChat={selectedChat} />
         </Grid>
      </Grid>
   );
}

export default ChatMainContainer;
