import React from "react";
import { Avatar, Box, Button, Typography, styled, useTheme } from "@mui/material";

import moment from "moment";
import { parseText, sendMessageStatus } from "../../../../utils/helperFuncion";

const Message = styled(Box)(({left,right})=>(({
   display: "flex",
   flexDirection: "column",
   marginBottom: "5px",
   paddingLeft:left||0,
   paddingRight:right||0
   
})));

function ListMessage({ content }) {
   const { message_input } = content;
   let parcedMessage, type, bodyText, headerText, footerText, actions, buttons;

   if (message_input) {
      parcedMessage = JSON.parse(message_input);
      type = parcedMessage?.interactive?.type;
      bodyText = parcedMessage?.interactive?.body?.text;
      headerText = parcedMessage?.interactive?.header?.text;
      footerText = parcedMessage?.interactive?.footer?.text;
      actions = parcedMessage?.interactive?.action;
      buttons = parcedMessage?.interactive?.action?.button;
   }
   const reply = content?.message?.interactive?.list_reply;

   const time = moment(content.createdAt).format("h:mm a");

   const statusIcon = sendMessageStatus(content?.status);
   const theme = useTheme();

   return (
      <>
         {content?.flag === "incoming" && (
            <Box
               sx={{
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  maxWidth: content.flag === "incoming" ? "450px" : null,
                  justifyContent: content.flag === "outgoing" ? "end" : "start",
               }}
            >
               
               <Message left={content.flag === "incoming"?20:0} right={content.flag === "outgoing"?20:0}>
                  <Box
                     p={1}
                     sx={{
                        width: "auto",
                        height: "auto",
                        color: content.flag === "incoming" ? "text.primary" : "#fff",
                        backgroundColor:
                           content.flag === "incoming" ? "background.incomingmsg" : "background.outgoingmsg",
                        borderRadius:
                           content.flag === "incoming"
                              ? "0px 20px 20px 20px"
                              : "20px 0px 20px 20px",
                     }}
                  >
                     <Typography sx={{ fontSize: "13px",color:"text.primary" }}>
                        {reply?.title}
                     </Typography>
                     <Typography sx={{ fontSize: "12px", color: "text.primary" }}>
                        {reply?.description}
                     </Typography>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "end",
                           alignItems: "center",
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: "11px",
                              fontWeight: "400",
                              color: "grey",
                              textAlign:
                                 content.flag === "incoming" ? "start" : "end",
                           }}
                        >
                           {time}
                        </Typography>
                        {content.flag === "outgoing" && statusIcon}
                     </Box>
                  </Box>
               </Message>
               
            </Box>
         )}
         {content?.flag === "outgoing" && (
            <Box
               mt={1}
               sx={{
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
               }}
            >
               <Message right={content.flag === "outgoing"?20:0}>
                  <Box
                     p={1}
                     sx={{
                        width: "auto",
                        height: "auto",
                        backgroundColor: "background.outgoingmsg",
                        color: content.flag === "incoming" ? "black" : "#fff",
                        maxWidth: "300px",
                        borderRadius: "20px 0px 20px 20px",
                        
                     }}
                  >
                     <Typography
                        variant="body1"
                        sx={{
                           fontSize: "14px",
                           color: "text.whitetext",
                           fontWeight: "600",
                        }}
                     >
                        {headerText}
                     </Typography>
                     <span style={{ whiteSpace: "pre-line", fontSize: "13px" }}>
                        {parseText(bodyText)}
                     </span>

                     {footerText && (
                        <Typography
                           variant="body1"
                           sx={{ fontSize: "11px", color: "grey" }}
                           color="initial"
                        >
                           {footerText}
                        </Typography>
                     )}
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "end",
                           alignItems: "center",
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: "11px",
                              fontWeight: "400",
                              color: "#fff",
                              textAlign:
                                 content?.flag === "incoming" ? "start" : "end",
                           }}
                        >
                           {time}
                        </Typography>
                        {content.flag === "outgoing" && statusIcon}
                     </Box>
                  </Box>
                  {buttons && (
                     <Box
                        // borderTop="1px solid whitesmoke"
                        display="flex"
                        justifyContent="space-between"
                     >
                        <Box
                           bgcolor={theme.palette.background.outgoingmsg}
                           borderRadius="20px 0px 20px 20px"
                           width="100%"
                           textAlign="center"
                           color="white"
                           marginTop="10px"
                        >
                           <Button
                              variant="text"
                              sx={{
                                 color: "#fff",
                                 textTransform: "capitalize",
                                 cursor:"default",
                                 "&:hover": {
                                    backgroundColor: "transparent", 
                                  },
                              }}
                           >
                              {buttons}
                           </Button>
                           {actions?.sections?.map((item) => (
                              <React.Fragment key={item?.title}>
                                 <Typography fontSize="14px" color="primary">
                                    {item?.title}
                                 </Typography>
                                 <Box p={1} sx={{ textAlign: "start" }}>
                                    {item?.rows.map((row) => (
                                       <div key={row?.id}>
                                          <p style={{ fontSize: "14px" }}>
                                             {row?.title}
                                          </p>
                                          <p
                                             style={{
                                                color: "grey",
                                                fontSize: "11px",
                                             }}
                                          >
                                             {row?.description}
                                          </p>
                                       </div>
                                    ))}
                                 </Box>
                              </React.Fragment>
                           ))}
                        </Box>
                     </Box>
                  )}
               </Message>
               
            </Box>
         )}
      </>
   );
}

export default ListMessage;
