import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../../middleware/utils";

export const getAllGroups = createAsyncThunk("group/getAllGroups", async () => {
   try {
      const response = await apiClient.get(`/groups`);
      return response.data.data;
   } catch (err) {
      throw err;
   }
});

const initialState = {
   loading: null,
   error: null,
   groups: [],
};
const labelSlice = createSlice({
   name: "group",
   initialState,
   reducers: {
      setNewGroup: (state, action) => {
         state.groups = [...state.groups, action.payload];
      },
      updateGroup: (state, action) => {
         const index = state.groups.findIndex(
            (group) => group._id === action.payload._id
         );

         if (index !== -1) {
            // Update the status of the found object
            state.groups[index] = action.payload;
         }

         state.error = null;
      },
      updateGroupFailed: (state, action) => {
         state.error = action.payload;
      },
   },

   extraReducers: (builder) => {
      builder
         .addCase(getAllGroups.pending, (state) => {
            state.loading = true;
         })
         .addCase(getAllGroups.fulfilled, (state, action) => {
            state.loading = false;
            state.groups = action.payload;
         })
         .addCase(getAllGroups.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         });
   },
});

export default labelSlice.reducer;
export const { updateGroup,updateGroupFailed } = labelSlice.actions;

export function removeContacts({ groupId, contact }) {
   return async (dispatch) => {
      try {
         const response = await apiClient.put(`/groups/remove-contact/${groupId}`, {
            contact: contact,
         });


         if (response.data && response.data.status === "success") {
            dispatch(updateGroup(response.data.group));

            // navigate("/dashboard")
         } else {
            dispatch(updateGroupFailed("Failed to delete contact"));
         }
      } catch (err) {
         dispatch(updateGroupFailed("Failed to delete contact"));

      }
   };
}
