import { Avatar, Box, Typography, styled, Button } from "@mui/material";
import React from "react";
import moment from "moment";
import pdfIcon from "../../../../assets/images/pdf.png";
import excelIcon from "../../../../assets/images/excel-logo.png";

import { useTheme } from "@mui/material/styles";
import { parseText, sendMessageStatus } from "../../../../utils/helperFuncion";
import useTemplateData from "../../../../hooks/useTemplateData";

const OuterBox = styled(Box)({
  height: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
});

const ContentBox = styled(Box)(({ theme }) => ({
  width: "auto",
  height: "auto",
  backgroundColor: theme.palette.background.outgoingmsg,
  maxWidth: "300px",
  borderRadius: "20px 0px 20px 20px",
  color: theme.palette.text.primary,
}));

const Message = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingRight: 20,
});

function TemplateMessage({ content, templates }) {
  const statusIcon = sendMessageStatus(content?.status);

  const time = moment(content.createdAt).format("h:mm a");

  const { footerMessage, bodyMessage, headerMessage, buttons } =
    useTemplateData(content, templates);

  const theme = useTheme();

  return (
    <OuterBox key={content._id} mt={1}>
      <Message>
        <ContentBox p={1}>
          {headerMessage?.type === "IMAGE" && (
            <img
              src={headerMessage?.url}
              alt="whatsappimage"
              style={{
                objectFit: "contain",
                height: "100%",
                width: "100%",
              }}
            />
          )}
          {headerMessage?.type === "VIDEO" && (
            <video
              style={{
                objectFit: "contain",
                height: "100%",
                width: "100%",
              }}
              controls
            >
              <source src={headerMessage?.url} type="video/mp4" />
            </video>
          )}
          {headerMessage?.type === "DOCUMENT" && (
            <a
              href={headerMessage?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                style={{
                  height: "40px",
                  width: "30px",
                  display: "flex",
                }}
              >
                {headerMessage?.extension === "pdf" && (
                  <img
                    src={pdfIcon}
                    alt="document"
                    width="100%"
                    height="30px"
                  />
                )}
                {headerMessage?.extension === "xlsx" && (
                  <img
                    src={excelIcon}
                    alt="document"
                    width="100%"
                    height="30px"
                  />
                )}
                {headerMessage?.extension === "doc" && (
                  <img
                    src={excelIcon}
                    alt="document"
                    width="100%"
                    height="30px"
                  />
                )}
              </div>
            </a>
          )}
          {headerMessage?.type === "TEXT" && (
            <Typography fontSize="12px" fontWeight="500" 
              sx={{ color: "text.whitetext" }}       
            >
              {headerMessage?.text}
            </Typography> 
          )}
          <span
            style={{
              whiteSpace: "pre-line",
              fontSize: "13px",
              color: theme.palette.text.whitetext,
            }}
          >
            {parseText(bodyMessage)}
          </span>

          {footerMessage && (
            <Typography
              variant="body1"
              sx={{ fontSize: "11px", color: "text.whitetext" }}       
              color="initial"
            >
              {footerMessage}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: "400",
                color: "#fff",
                textAlign: content.flag === "incoming" ? "start" : "end",
              }}
            >
              {time}
            </Typography>
            {content.flag === "outgoing" && statusIcon}
          </Box>
        </ContentBox>
        {buttons.map((btn) => (
          <Box mt={1 / 2} display="flex" justifyContent="space-between">
            <Box
              sx={{
                backgroundColor: theme.palette.background.outgoingmsg,
                transition: "background-color 0.3s ease", '&:hover': {
                  backgroundColor: theme.palette.background.outgoingbtnHover, 
                }
              }}
              borderRadius="10px"
              width="100%"
              textAlign="center"
              //   border="1px solid grey"
            >
              <Button
               variant="contained"
               fullWidth
               sx={{
                  textTransform: 'capitalize',
                  color: '#fff',
                  backgroundColor:"background.outgoingbtn",
                  '&:hover': {
                     backgroundColor:"background.outgoingbtnHover",
                  },
                  }}
                  >
                {btn.text}
              </Button>
            </Box>
          </Box>
        ))}
      </Message>
      {/* <Box p={1}>
            <Avatar
               sx={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: "black",
                  color: "#fff",
               }}
            />
         </Box> */}
    </OuterBox>
  );
}

export default TemplateMessage;
