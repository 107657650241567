import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControl,
   FormControlLabel,
   InputLabel,
   ListItemIcon,
   ListItemText,
   MenuItem,
   Select,
   Typography,
   styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { assignLabel } from "../../../services/labelServide";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useTheme } from "@mui/material/styles";
import { getAllLabels } from "../../../../store/slices/clientSlice/labelSlice";

const CustomMenuItem = styled(MenuItem)({
   backgroundColor: "transparent",
   padding: "8px 16px",
   borderRadius: "4px",
   "& .MuiListItemIcon-root": {
      minWidth: "32px" ,
      display: "flex",
      justifyContent: "flex-end" 
   },

   "& .MuiListItemIcon-root svg": {
      color: "#999" 
   },
   "& .MuiListItemText-primary": {
      color: "#333",
      fontWeight: "500" ,
   },
});

// SX styles for Add Template Button
const closebtn = (theme) => ({
   backgroundColor: theme.palette.background.bgbutton,
   textTransform: "capitalize",
   border: "0.5px solid #0293d2",
   color: "#0293d2",
   fontWeight: "500",
   paddingLeft: "22px",
   paddingRight: "22px",
   borderRadius: "5px",
   boxShadow: "none !important",
   marginRight: "10px",
   "&:hover": {
      backgroundColor: "#0293d2",
      color: "#fff",
   },
   "@media (max-width: 600px)": {
      marginRight: 0,
   },
});

const savebtn = (theme) => ({
   backgroundColor: theme.palette.primary.iconmain,
   textTransform: "capitalize",
   border: "0.5px solid #0293d2",
   fontWeight: "500",
   borderRadius: "5px",
   color: theme.palette.text.whitetext,
   paddingLeft: "22px",
   paddingRight: "22px",
   boxShadow: "none !important",
   "&:hover": {
      backgroundColor: "background.iconhover",
      color: "#fff",
      border: `0.5px solid ${theme.palette.background.iconhover}`,
   },
});

function AssignLabelModal({ openAddLabel, setopenAddLabel, selectedChat }) {
   const [label, setLabel] = useState(selectedChat?.label?._id || "");
   const { labels } = useSelector((state) => state.labels);
   const [activeLabels, setActiveLabels] = useState([]);

   const removeDesabledLabels = () => {
      const active = labels.filter((item) => item.status === "active");
      setActiveLabels(active);
   };

   useEffect(() => {
      removeDesabledLabels();
   }, [labels]);

   const dispatch = useDispatch();

   const handleLabelChange = (e) => {
      setLabel(e.target.value);
   };

   const handleSave = async () => {
      // const response = await assignLabel(selectedChat?._id, label);
      // if (response.status === "success") {
      //    toast.success("Updated successfully");
      //    dispatch(saveSelectedChat(response?.data));
      //    setopenAddLabel(false);
      //    dispatch(getAllChats({page:1}));
      // }
   };

   const getLabels = () => {
      dispatch(getAllLabels());
   };

   useEffect(() => {
      getLabels();
   }, []);
   const handleClose = () => setopenAddLabel(false);

   const theme = useTheme();

   return (
      <Dialog
         maxWidth="sm"
         open={openAddLabel}
         onClose={handleClose}
         sx={{
            "& .MuiPaper-root": {
               backgroundColor: "background.modal",
               backgroundImage: "none",
            },
         }}
      >
         <DialogTitle
            sx={{
               padding: "20px 26px 5px 24px",
            }}
         >
            Assign Label
         </DialogTitle>
         <DialogContent>
            <DialogContentText sx={{ fontSize: "0.9rem" }}>
               You can assign a label for this contact to catogorize.
            </DialogContentText>
            <Box
               noValidate
               component="form"
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: "auto",
                  width: "100%",
               }}
            >
               <Typography sx={{ fontSize: "0.9rem" }}>
                  {selectedChat?.phone_number}
               </Typography>
               <Typography sx={{ fontSize: "0.9rem" }}>
                  {selectedChat?.Name}
               </Typography>
               <FormControl sx={{ mt: 2 }} fullWidth size="small">
                  <InputLabel htmlFor="max-width">Label</InputLabel>
                  <Select
                     autoFocus
                     value={label}
                     fullWidth
                     onChange={handleLabelChange}
                     label="maxWidth"
                     inputProps={{
                        name: "max-width",
                        id: "max-width",
                     }}
                  >
                     <MenuItem value="null">None</MenuItem>
                     {activeLabels?.map((label) => (
                        <CustomMenuItem value={label?._id} key={label?._id}>
                           {label?.labelCategory}
                        </CustomMenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Box>
         </DialogContent>
         <DialogActions sx={{ paddingRight: "24px", paddingBottom: "17px" }}>
            <Button onClick={handleClose} sx={closebtn}>
               Close
            </Button>
            <Button onClick={handleSave} sx={savebtn}>
               Save
            </Button>
         </DialogActions>
      </Dialog>
   );
}

export default AssignLabelModal;
