import { Avatar, Box, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";

import moment from "moment/moment";

// import EmptyImage from "../../../../assets/images/Image_not_available.png";
import MediaPreviewModal from "../Modals/MediaPreviewModal";
import { sendMessageStatus } from "../../../../utils/helperFuncion";
import ReplyMsgBox from "../components/ReplyMsgBox";
import MessageMoreOption from "../components/MessageMoreOption";

const Message = styled(Box)(({ left, right }) => ({
   display: "flex",
   flexDirection: "column",
   marginBottom: "5px",
   paddingLeft: left || 0,
   paddingRight: right || 0,
}));

function ImageMessage({ content, handleSelectedReplyMsg }) {
   const url = process.env.REACT_APP_IMAGE_URL;
   const [isHovered, setIsHovered] = useState(false);
   const [openMediaPreview, setOpenMediaPreview] = useState(false);

   const time = moment(content.createdAt).format("h:mm a");
   const statusIcon = sendMessageStatus(content?.status);
   const { message } = content;

   const imagePath = message?.image?.thumbnailPath || message?.image?.path;
   const videoPath =
      message?.video?.thumbnailPath ||
      message?.video?.path ||
      message?.image?.path;
   const caption =
      message?.video?.caption ||
      message?.image?.caption ||
      message?.document?.caption;

   const setOpenPreviewModel = () => setOpenMediaPreview(true);
   const setClosePreviewModel = () => setOpenMediaPreview(false);

   return (
      <Box
         //   key={mess._id}
         sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: content.flag === "incoming" ? "start" : "end",
         }}
      >
         {/* {content.flag === "incoming" && (
            <Box p={1}>
               <Avatar
                  sx={{
                     width: "30px",
                     height: "30px",
                     backgroundColor: "black",
                     color: "#fff",
                  }}
               />
            </Box>
         )} */}
         <Message
            left={content.flag === "incoming" ? 20 : 0}
            right={content.flag === "outgoing" ? 20 : 0}
         >
            <Box
               mt={1}
               sx={{
                  padding: "4px 4px 4px 4px",
                  width: "250px",
                  position: "relative",
                  color: content.flag === "incoming" ? "text.primary" : "#fff",
                  backgroundColor:
                     content.flag === "incoming"
                        ? "background.incomingmsg"
                        : "background.outgoingmsg",
                  borderRadius:
                     content.flag === "incoming"
                        ? "0px 20px 20px 20px"
                        : "20px 0px 20px 20px",
               }}
               onMouseEnter={() => setIsHovered(true)} // Handle hover enter
               onMouseLeave={() => setIsHovered(false)}
            >
               {isHovered && (
                  <MessageMoreOption
                     content={content}
                     handleSelectedReplyMsg={handleSelectedReplyMsg}
                  />
               )}

               {content?.reply_to && <ReplyMsgBox content={content} />}

               <Box sx={{ position: "relative" }}>
                  {content?.message?.type === "video" ? (
                     <Box
                        sx={{
                           position: "relative",
                           width: "250px",
                           borderRadius:
                              content.flag === "incoming"
                                 ? "0px 20px 20px 20px"
                                 : "20px 0px 20px 20px",
                           overflow: "hidden",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        {/* <video
                           style={{
                              objectFit: "contain",
                              height: "100%",
                              width: "250px",
                              borderRadius:
                                 content.flag === "incoming"
                                    ? "0px 20px 20px 20px"
                                    : "20px 0px 20px 20px",
                           }}
                           controls
                        >
                           <source
                              src={`${url}/${videoPath}`}
                              type="video/mp4"
                           />
                        </video> */}

                        <img
                           src={videoPath ? `${url}/${videoPath}` : "EmptyImage"}
                           alt="whatsappimage"
                           style={{
                              width: "250px",
                              borderRadius:
                                 content.flag === "incoming"
                                    ? "0px 20px 20px 20px"
                                    : "20px 0px 20px 20px",
                           }}
                        />
                        <Box
                           sx={{
                              position: "absolute",
                              width: "50px",
                              height: "50px",
                              backgroundColor: "#00000075",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                           }}
                           onClick={setOpenPreviewModel}
                        >
                           <PlayArrowRoundedIcon
                              sx={{ width: "30px", height: "50px",color:"#fff" }}
                           />
                        </Box>
                        <Box
                           sx={{
                              position: "absolute",
                              bottom: "0px",
                              left: 0,
                              width: "100%",
                              height: "50%",
                              borderRadius: "20px",
                              background:
                                 "linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.6) 100%)",
                           }}
                        />
                     </Box>
                  ) : (
                     <Box
                        sx={{
                           position: "relative",
                           width: "250px",
                           borderRadius:
                              content.flag === "incoming"
                                 ? "0px 20px 20px 20px"
                                 : "20px 0px 20px 20px",
                           overflow: "hidden",
                        }}
                     >
                        {imagePath ? (
                           <img
                              src={
                                 imagePath ? `${url}/${imagePath}` : "EmptyImage"
                              }
                              alt="whatsappimage"
                              style={{
                                 width: "250px",
                                 borderRadius:
                                    content.flag === "incoming"
                                       ? "0px 20px 20px 20px"
                                       : "20px 0px 20px 20px",
                              }}
                           />
                        ) : (
                           <img
                              src={"EmptyImage"}
                              alt="whatsappimage"
                              style={{
                                 width: "250px",
                                 borderRadius:
                                    content.flag === "incoming"
                                       ? "0px 20px 20px 20px"
                                       : "20px 0px 20px 20px",
                              }}
                           />
                        )}
                        {/* <Box
                           sx={{
                              position: "absolute",
                              bottom: "4px",
                              left: 0,
                              width: "100%",
                              borderRadius: "20px",
                              height: "50%",
                              backgroundColor: "red",
                              // background:
                              //    "linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.6) 100%)",
                           }}
                        /> */}
                     </Box>
                  )}

                  <Box
                     sx={{
                        position: "absolute",
                        bottom: 8,
                        right: 5,
                        display: "flex",
                        alignItems: "center",
                        // display: "flex",
                        // justifyContent: "end",
                        // alignItems: "center",
                     }}
                  >
                     <Typography
                        sx={{
                           fontSize: "11px",
                           fontWeight: "400",
                           color: content.flag === "incoming" ? "#fff" : "#fff",
                           marginRight:
                              content.flag === "outgoing" ? "5px" : "4px",
                           marginBottom:
                              content.flag === "outgoing" ? "0px" : "2px",

                           // textAlign:
                           //    content.flag === "incoming" ? "start" : "end",
                        }}
                     >
                        {time}
                     </Typography>
                     {content.flag === "outgoing" && statusIcon}
                  </Box>
               </Box>

               {caption && (
                  <Typography sx={{ fontSize: "13px", padding: "10px" }}>
                     {caption}
                  </Typography>
               )}
            </Box>
         </Message>
         {/* {content.flag === "outgoing" && (
            <Box p={1}>
               <Avatar
                  sx={{
                     width: "30px",
                     height: "30px",
                     backgroundColor: "black",
                     color: "#fff",
                  }}
               />
            </Box>
         )} */}
         <MediaPreviewModal open={openMediaPreview} content={content} handleClose={setClosePreviewModel}/>
      </Box>
   );
}

export default ImageMessage;
