
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";
import moment from "moment";

export const filterFileUpload = (file, error) => {
   if (!file) {
      return { error: "No file provided", type: "" };
   }

   const fileTypeMap = {
      "video/mp4": "video",
      " video/3gp": "video",
      "application/pdf": "pdf",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
         "excel",
      "application/vnd.ms-excel": "excel",
      "text/plain": "txt",
      "application/msword": "document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
         "document",
      "application/vnd.ms-powerpoint": "document",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
         "document",
   };

   const fileType = fileTypeMap[file.type];

   if (!fileType) {
      return { error: "Unsupported file type", type: "" };
   }

   return { type: fileType, error: null };
};


// Delay calling the api fetch all chats 
export const debounce = (func, delay) => {
   let timer;
   return function (...args) {
      clearTimeout(timer); // Clears the previous timeout
      timer = setTimeout(() => {
         func.apply(this, args); // Executes the function after the delay
      }, delay);
   };
};



export const sendMessageStatus = (status) => {
   if (status === 1) {
      return (
         <DoneIcon
            sx={{ fontSize: "16px", color: "#00f1ff", marginLeft: "2px" }}
         />
      );
   } else if (status === 2) {
      return (
         <DoneAllIcon
            // sx={{ fontSize: "16px", color: "#ffffff63", marginLeft: "2px" }}
            sx={{ fontSize: "16px", color: "#fff", marginLeft: "2px" }}
         />
      );
   } else if (status === 3) {
      return (
         <DoneAllIcon
            sx={{ fontSize: "16px", color: "#00f1ff", marginLeft: "2px" }}
         />
      );
   } else if (status === 4) {
      return (
         <ErrorOutlineIcon
            sx={{ fontSize: "16px", color: "red", marginLeft: "2px" }}
         />
      );
   }
};

export const fileTypes = [
   "text/plain",
   "application/vnd.ms-excel",
   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
   "application/msword",
   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
   "application/vnd.ms-powerpoint",
   "application/vnd.openxmlformats-officedocument.presentationml.presentation",
   "application/pdf",
   "image/jpeg",
   "image/png",
   "image/webp",
   "video/3gp",
   "video/mp4",
   "audio/aac",
   "audio/mpeg",
   "audio/amr",
   "audio/mp4",
];

// export const parseText = (text) => {



//    // Step 1: Replace \\n with actual line breaks
//    let formattedText = text.replace(/\\n/g, "\n");

//    // Step 2: Split the text by newline characters to handle each line separately
//    const lines = formattedText.split("\n");

//    // Step 3: Process each line to replace *text* with <strong>text</strong>
//    return lines.map((line, index) => {
//       const parts = line.split(/(\*[^*]+\*)/); // Split by *...* to identify bold parts

//       return (
//          <React.Fragment key={index}>
//             {parts.map((part, index) =>
//                part.startsWith("*") && part.endsWith("*") ? (
//                   <strong key={index}>{part.slice(1, -1)}</strong> // Remove * and wrap in <strong>
//                ) : (
//                   part
//                )
//             )}
//             <br /> {/* Add line break after each line */}
//          </React.Fragment>
//       );
//    });
// };

function urlifyFn(text,flag) {
   let urlRegex = /(https?:\/\/[^\s]+)/g;
   return text.replace(urlRegex, function(url) {
      return `<a href='${url}' style='color: ${flag === "incoming" ? "#0293d2" : "#fff"}; text-decoration: underline;' target='_blank' rel='noopener noreferrer'>${url}</a>`;   });
}
export const parseText = (text,flag) => {
   // Step 1: Replace \\n with actual line breaks
   let formattedText = text.replace(/\\n/g, "\n");

   // Step 2: Split the text by newline characters to handle each line separately
   const lines = formattedText.split("\n");

   // Regular expression to detect URLs
   // const urlPattern = /(https?:\/\/[^\s]+)/g;
   const urlPattern = /(https?:\/\/[^\s]+)/g;

   // Step 3: Process each line to replace *text* with <strong>text</strong> and make URLs clickable
   return lines.map((line, index) => {
      // Split the line into parts for both bold and URL matching
      const parts = line.split(/(\*[^*]+\*)|(\s+)/g); // Split by *...* for bold and whitespace to preserve spacing

      return (
         <React.Fragment key={index}>
            {parts.map((part, partIndex) => {
               // Check if the part is a bold text (enclosed in *)
               if (part && part.startsWith("*") && part.endsWith("*")) {
                  return (
                     <strong key={partIndex}>{part.slice(1, -1)}</strong> // Remove * and wrap in <strong>
                  );
               }
               // Check if the part is a URL (matches the urlPattern)
               // If the part contains a URL, apply urlifyFn
               if (part && /(https?:\/\/[^\s]+)/g.test(part)) {
                  const formattedLink = urlifyFn(part,flag); // Convert URL to clickable link
                  return (
                     <span
                        key={partIndex}
                        dangerouslySetInnerHTML={{ __html: formattedLink }}
                        // style={{ color: flag === "incoming" ? "#0293d2" : "#fff", textDecoration: "underline" }}
                     />
                  );
               }
               // Otherwise, return the part as is (including normal text)
               return part;
            })}
            <br /> {/* Add line break after each line */}
         </React.Fragment>
      );
   });
};

export const getMessageType = (files) => {
   const fileTypeMap = {
      "text/plain":"document",
      "application/vnd.ms-excel":"document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":"document",
      "application/msword":"document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":"document",
      "application/vnd.ms-powerpoint":"document",
      "application/pdf":"document",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":"document",
      "image/jpeg":"image",
      "image/png":"image",
      "image/webp":"image",
      "video/3gp":"video",
      "video/mp4":"video",
      "audio/mpeg":"audio",
      "audio/aac":"audio",
      "audio/amr":"audio",
      "audio/mp4":"audio",
   };
   const fileType = files.type;

   if (fileTypeMap.hasOwnProperty(fileType)) {
      return fileTypeMap[fileType];
   } else {
      // Handle unknown file types or return a default type
      return "unknown";
   }

};


/**
 * Function to check the current chat is expired or not
 *
 */

export const checkChatExpired = (selectedChat) => {
   /**
    * The below code is for check the expiration time with last_mes_received_at field
    */

   const expirationTimestamp = selectedChat?.last_msg_received_at;
   if (!expirationTimestamp) {
      return true;
   }

   const date = new Date(expirationTimestamp * 1000); // Convert timestamp to milliseconds

   date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours in milliseconds
   const newTimestamp = Math.floor(date.getTime() / 1000);
   // Create a moment object from the timestamp

   const expirationDate = moment.unix(newTimestamp);

   

   const currentDate = moment();

   if (expirationDate.isBefore(currentDate)) {
      return true;
   } else if (expirationDate.isSame(currentDate)) {
      return true;
   } else {
      return false;
   }
};

// Function to check if the selected file type is valid for documents
function isValidDocumentType(fileType) {
   const validDocumentTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/pdf",
   ];

   return validDocumentTypes.includes(fileType);
}

export const validateTemplateFile = (file, headerType) => {
   // Validation based on headerType
   if (headerType === "IMAGE" && !file.type.startsWith("image/")) {
      return { error: "Please select an image file." };
      // You may also clear the input field if needed
      // event.target.value = "";
   } else if (headerType === "VIDEO" && !file.type.startsWith("video/")) {
      return { error: "Please select a video file." };
      // event.target.value = "";
   } else if (headerType === "DOCUMENT" && !isValidDocumentType(file.type)) {
      return { error: "Please select a valid document file." };
      // event.target.value = "";
   } else {
      return true;
   }
};

// Download sample csv file
export const handleDownload = () => {
   // const fileUrl = `${process.env.PUBLIC_URL}/example.xlsx`;
   const fileUrl = `${process.env.REACT_APP_PUBLIC_URL}/Sample.xlsx`;
   const link = document.createElement("a");
   link.href = fileUrl;
   link.download = "example.xlsx";
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
};
// Download sample bulk contact upload csv file
export const handleDownloadBulkContact = () => {
   // const fileUrl = `${process.env.PUBLIC_URL}/example.xlsx`;
   const fileUrl = `${process.env.REACT_APP_PUBLIC_URL}/Bulk_contact_sample.xlsx`;
   const link = document.createElement("a");
   link.href = fileUrl;
   link.download = "example.xlsx";
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
};

// Function to check variable exist in template body

export const checkIsVariableExistInBody = (content) => {
   const parcedContent = JSON.parse(content);
   const templateComponent = parcedContent?.template?.components;
   let isVariableExist;
   if (templateComponent.length > 0) {
      const bodyObject = templateComponent?.find(
         (compo) => compo.type === "body"
      );
      isVariableExist = bodyObject?.parameters?.length;
   }
   if (isVariableExist > 0) return true;
   return false;
};

export const replaceVariables = (text, content) => {
   let replacedText = text;
   const parcedContent = JSON.parse(content);
   const templateComponent = parcedContent?.template?.components;
   const bodyObject = templateComponent?.find((compo) => compo.type === "body");

   bodyObject?.parameters?.forEach((item, index) => {
      // replacedText = replacedText.replace(`{{${index + 1}}}`, item.text);
      const regex = new RegExp(`\\{\\{${index + 1}\\}\\}`, "g"); // Create a global regex for all occurrences
      replacedText = replacedText.replace(regex, item.text);
   });
   return replacedText;
};

export const fomatMessageDate = (date) => {
   // Parse the date string using moment
   const parsedDate = moment("2024-06-28");

   // Format the date in dd-mm-yyyy format
   const formattedDate = parsedDate.format("DD-MM-YYYY");

   // Check if the date is today or yesterday
   const today = moment().utc();
   const yesterday = today.subtract(1, "days");

   let displayedDate;
   if (parsedDate.isSame(today, "day")) {
      displayedDate = "Today";
   } else if (parsedDate.isSame(yesterday, "day")) {
      displayedDate = "Yesterday";
   } else {
      displayedDate = formattedDate; // If not today or yesterday, show formatted date
   }

   return formattedDate;
};

/**
 * Function to append new send message to existing message
 */

const appendNewMessage = (messages,newMessage) => {
   // Get today's date in the format "DD-MM-YYYY"
   //   const today = new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
   const today = moment().format("DD-MM-YYYY");
   // Find the object with today's date in the "id" field
   const todayObjectIndex = messages.findIndex((obj) => obj._id === today);

   if (todayObjectIndex !== -1) {
      // Today's date object exists, add the new message to its messages array
      messages[todayObjectIndex].messages.push(newMessage);
   } else {
      // Today's date object doesn't exist, create a new object and add it to the messages
      messages.push({
         _id: today,
         messages: [newMessage],
      });
   }

   return messages;
};


