import axios from 'axios';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CryptoJS from 'crypto-js';
const API_URL = process.env.REACT_APP_API_URL;
const secretKey = process.env.REACT_APP_SECRET_KEY;

export const login = createAsyncThunk('auth/login', async (userCredentials, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${API_URL}/auth/login`, userCredentials);
        const { token, tenantID, roledashboard, clientID , agentID , clientName, privileges } = response.data;

        // Encrypt the data
        const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(token), secretKey).toString();
        const encryptedTenantID = CryptoJS.AES.encrypt(JSON.stringify(tenantID), secretKey).toString();
        const encryptedRoleName = CryptoJS.AES.encrypt(JSON.stringify(roledashboard), secretKey).toString();
        const encryptedClientID = CryptoJS.AES.encrypt(JSON.stringify(clientID), secretKey).toString();
        const encryptedAgentID = CryptoJS.AES.encrypt(JSON.stringify(agentID), secretKey).toString();
        const encryptedClientName = CryptoJS.AES.encrypt(JSON.stringify(clientName), secretKey).toString();
        const encryptedPrivileges = CryptoJS.AES.encrypt(JSON.stringify(privileges), secretKey).toString();
        const encryptedAuth = CryptoJS.AES.encrypt(JSON.stringify(true), secretKey).toString();
        localStorage.setItem('token', encryptedToken);
        localStorage.setItem('tenantID', encryptedTenantID);
        localStorage.setItem('roledashboard', encryptedRoleName);
        localStorage.setItem('clientID', encryptedClientID);
        localStorage.setItem('agentID', encryptedAgentID);
        localStorage.setItem('clientName', encryptedClientName);
        localStorage.setItem('privileges', encryptedPrivileges);
        localStorage.setItem('isAuthenticated', encryptedAuth);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data.message || 'Failed to login');
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        user: null,
        token: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        loginError: null,
    },
    reducers: {
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.status = 'idle';
            state.loginError = null;
            state.isAuthenticated = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.isAuthenticated = true;
                state.token = action.payload.token;
                state.user = action.payload.user;
                state.loginError = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.status = 'failed';
                state.loginError = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
