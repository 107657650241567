import { SvgIcon } from "@mui/material";
import React from "react";

function SidebarCase(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.21429 0.642822C2.3618 0.642822 1.54424 0.981469 0.941442 1.58426C0.338647 2.18706 0 3.00463 0 3.85711V4.92854H20V3.85711C20 3.00463 19.6614 2.18706 19.0586 1.58426C18.4558 0.981469 17.6382 0.642822 16.7857 0.642822H3.21429ZM0 13.1428V6.35711H20V13.1428C20 13.9953 19.6614 14.8129 19.0586 15.4157C18.4558 16.0185 17.6382 16.3571 16.7857 16.3571H3.21429C2.3618 16.3571 1.54424 16.0185 0.941442 15.4157C0.338647 14.8129 0 13.9953 0 13.1428ZM13.5714 10.6428C13.382 10.6428 13.2003 10.7181 13.0664 10.852C12.9324 10.986 12.8571 11.1677 12.8571 11.3571C12.8571 11.5465 12.9324 11.7282 13.0664 11.8622C13.2003 11.9961 13.382 12.0714 13.5714 12.0714H15.7143C15.9037 12.0714 16.0854 11.9961 16.2194 11.8622C16.3533 11.7282 16.4286 11.5465 16.4286 11.3571C16.4286 11.1677 16.3533 10.986 16.2194 10.852C16.0854 10.7181 15.9037 10.6428 15.7143 10.6428H13.5714Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}

export default SidebarCase;
