import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import Body from "./routes/index";
import { ThemeContextProvider } from "./theme";
import { Suspense, useEffect } from "react";
import FullScreenSpinner from "./components/common/FullScreenSpinner";
import { Toaster } from "react-hot-toast";
import { getRoleDashboard } from "./middleware/authServices";

function App() {
 
   return (
      <div className="App">
         <ThemeContextProvider>
            <Suspense fallback={<FullScreenSpinner />}>
               <Body />
               <Toaster />
            </Suspense>
         </ThemeContextProvider>
      </div>
   );
}

export default App;
library.add(fab, fas, far);
