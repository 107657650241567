import { Box, Typography } from "@mui/material";
import React from "react";
import pdfIcon from "../../../../assets/images/pdf.png";
// import wordIcon from "../../../../assets/images/word.png";
import wordIcon from "../../../../assets/images/word.jpg";
import excelfIcon from "../../../../assets/images/excel-logo.png";

function ReplyMsgBox({ content }) {
   const url = process.env.REACT_APP_IMAGE_URL;
   const path = content?.reply_to?.filePath;

   const extension = path?.split(".").pop() || path?.split(".").pop();
   const documentType =
      {
         xls: "excel",
         xlsx: "excel",
         doc: "word",
         docx: "word",
         pdf: "pdf",
      }[extension] || "unknown";

   return (
      <Box
         mb={0.5}
         sx={{
            backgroundColor:
               content.flag === "incoming" ? "#8080801a" : "#f4f4f636",
            overflow: "hidden",
            borderRadius: "5px",
            padding: "5px",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            zIndex: 1,
         }}
      >
         <Box height={50}>
            <Typography fontSize="12px" fontWeight="500">
               {content?.reply_to?.sender}
            </Typography>
            {content?.reply_to?.content && (
               <Box
                  sx={{
                     display: "-webkit-box",
                     WebkitBoxOrient: "vertical",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                     WebkitLineClamp: 2, // Limit to two lines
                     lineClamp: 2, // Line clamp for other browsers
                     fontSize: "13px",
                     maxWidth: "280px",
                  }}
               >
                  {content?.reply_to?.content}
               </Box>
            )}
            {/* <Typography
               sx={{
                  fontSize: "13px",
                  // display: "inline-block",
                  width: "580px",
                  height:"50px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
               }}
            >
               {content?.reply_to?.content}
            </Typography> */}
         </Box>
         {content?.reply_to?.filePath &&content?.reply_to?.message_type==="image" &&(
            <Box width="50px" ml={2} height="50px">
               <img src={`${url}/${path}`} width="100%" alt="message" />
            </Box>
         )}
         {content?.reply_to?.filePath && documentType === "excel" && (
            <div
               style={{
                  height: "40px",
                  width: "30px",
                  display: "flex",
               }}
            >
               <img
                  src={excelfIcon}
                  alt="document"
                  width="100%"
                  height="30px"
               />
            </div>
         )}
         {content?.reply_to?.filePath && documentType === "word" && (
            <div
               style={{
                  height: "40px",
                  width: "30px",
                  display: "flex",
               }}
            >
               <img
                  src={wordIcon}
                  alt="document"
                  width="100%"
                  height="30px"
               />
            </div>
         )}
         {content?.reply_to?.filePath && documentType === "pdf" && (
            <div
               style={{
                  height: "40px",
                  width: "25px",
                  display: "flex",
               }}
            >
               <img
                  src={pdfIcon}
                  alt="document"
                  width="100%"
                  height="30px"
               />
            </div>
         )}
      </Box>
   );
}

export default ReplyMsgBox;
