import { useEffect, useState } from "react";
import {
   Box,
   Button,
   Checkbox,
   FormControl,
   Grid,
   InputLabel,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   MenuItem,
   Select,
   TextField,
   Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateClientSubscriptionPlan } from "../../../store/slices/adminSlices/manageSubscriptionClinetPlans";
import Sidebar from "../../common/NavbarContainer";
import { OuterBox } from "../ManageAgents/UserStyles";
import { fetchFeature } from "../../../store/slices/adminSlices/manageMasterFeatureSlice";

function EditSubscriptionPlan() {
   const location = useLocation();
   const plan = location.state?.plan; // Access passed data here
   console.log(plan.features);
   const [form, setForm] = useState(plan);
   const [selectedFeatures, setSelectedFeatures] = useState(
      plan.features.map((f) => f.featureId)
   );
   const [labelValues, setLabelValues] = useState({});
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { features } = useSelector((state) => state.feature);

   useEffect(() => {
      dispatch(fetchFeature());
   }, [dispatch]);

   useEffect(() => {
      // Set initial label values for editing
      const initialLabelValues = {};
      plan.features.forEach((feature) => {
         initialLabelValues[feature.featureId] = { ...feature };
      });
      setLabelValues(initialLabelValues);
   }, [plan.features]);

   const handleToggle = (featureId) => {
      setSelectedFeatures((prev) =>
         prev.includes(featureId)
            ? prev.filter((id) => id !== featureId)
            : [...prev, featureId]
      );
   };

   const handleInputChange = (featureId, labelName, value) => {
      setLabelValues((prev) => ({
         ...prev,
         [featureId]: {
            ...(prev[featureId] || {}),
            [labelName]: value,
         },
      }));
   };

   const getSelectedFeatures = () => {
      return selectedFeatures.map((featureId) => {
         const feature = features.find((f) => f._id === featureId);
         return {
            feature: feature.featureName,
            featureId: feature._id,
            ...labelValues[featureId],
         };
      });
   };

   const handleUpdatePlan = () => {
      let updatedFeatures = [];
      console.log(selectedFeatures);
      if (selectedFeatures) {
         updatedFeatures = getSelectedFeatures();
      }
      const updatedPlan = { ...form, features: updatedFeatures };
      console.log({ updatedPlan });
      dispatch(
         updateClientSubscriptionPlan({
            planData: updatedPlan,
            planId: plan._id,
         })
      )
         .then(() => {
            navigate("/Admin-Dashboard/Subscription-Plans");
         })
         .catch((error) => console.error("Update failed:", error));
   };

   return (
      <div style={{ display: "flex" }}>
         <Sidebar />
         <OuterBox>
            <Box sx={{ width: "100%" }}>
               <Typography
                  fontSize="20px"
                  fontWeight="600"
                  sx={{ color: "text.primary", mt: 2 }}
               >
                  Edit Plan
               </Typography>

               <form>
                  <Grid container spacing={3} mt={3}>
                     <Grid item xs={12} md={6}>
                        <TextField
                           fullWidth
                           size="small"
                           label="Package/Plan Name"
                           variant="outlined"
                           value={form.planname}
                           onChange={(e) =>
                              setForm({ ...form, planname: e.target.value })
                           }
                        />
                     </Grid>
                     <Grid item xs={12} md={6}>
                        <TextField
                           fullWidth
                           size="small"
                           label="Price"
                           variant="outlined"
                           value={form.planprice}
                           onChange={(e) =>
                              setForm({ ...form, planprice: e.target.value })
                           }
                        />
                     </Grid>
                  </Grid>

                  <Grid container spacing={3} mt={2}>
                     <Grid item xs={12} md={6}>
                        <TextField
                           fullWidth
                           size="small"
                           label="Duration"
                           variant="outlined"
                           value={form.duration}
                           onChange={(e) =>
                              setForm({ ...form, duration: e.target.value })
                           }
                        />
                     </Grid>
                     <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                           <InputLabel>Type</InputLabel>
                           <Select
                              size="small"
                              value={form.plantype}
                              onChange={(e) =>
                                 setForm({ ...form, plantype: e.target.value })
                              }
                           >
                              <MenuItem value="Agent">Agent</MenuItem>
                              <MenuItem value="Client">Client</MenuItem>
                           </Select>
                        </FormControl>
                     </Grid>
                  </Grid>

                  <Typography
                     fontSize="20px"
                     fontWeight="600"
                     sx={{ color: "text.primary", pt: 2 }}
                  >
                     Features
                  </Typography>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                     {features.map((feature) => {
                        const labelId = `checkbox-list-label-${feature._id}`;
                        return (
                           <Box display="flex" width="100%" key={feature._id}>
                              <Box width={600}>
                                 <ListItem disablePadding>
                                    <ListItemButton
                                       onClick={() => handleToggle(feature._id)}
                                       dense
                                    >
                                       <ListItemIcon>
                                          <Checkbox
                                             edge="start"
                                             checked={selectedFeatures.includes(
                                                feature._id
                                             )}
                                             disableRipple
                                             inputProps={{
                                                "aria-labelledby": labelId,
                                             }}
                                          />
                                       </ListItemIcon>
                                       <ListItemText
                                          id={labelId}
                                          primary={feature.featureName}
                                       />
                                    </ListItemButton>
                                 </ListItem>
                              </Box>
                              <Box width="300px">
                                 {feature.featureLabels.map((label) =>
                                    selectedFeatures.includes(feature._id) ? (
                                       <TextField
                                          sx={{ m: 1 }}
                                          key={label.label}
                                          placeholder={label.label}
                                          size="small"
                                          fullWidth
                                          value={
                                             labelValues[feature._id]?.[
                                                label.label
                                             ] || ""
                                          }
                                          onChange={(e) =>
                                             handleInputChange(
                                                feature._id,
                                                label.label,
                                                e.target.value
                                             )
                                          }
                                       />
                                    ) : null
                                 )}
                              </Box>
                           </Box>
                        );
                     })}
                  </List>

                  <Box mt={4}>
                     <Button
                        variant="contained"
                        onClick={handleUpdatePlan}
                        sx={{ marginRight: "10px" }}
                     >
                        Save Changes
                     </Button>
                     <Button
                        variant="contained"
                        onClick={() =>
                           navigate("/Admin-Dashboard/Subscription-Plans")
                        }
                     >
                        Cancel
                     </Button>
                  </Box>
               </form>
            </Box>
         </OuterBox>
      </div>
   );
}

export default EditSubscriptionPlan;
