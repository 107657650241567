import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotFoundImage from "../../assets/images/404.png";
import { useTheme } from '@mui/material/styles';
import { getAuth } from "../../middleware/authServices";
import { getRoleDashboard } from "../../middleware/authServices.js";
// import Sidebar from "./SideBarList";
// import DrawerSidebar from "./DrawerSidebar";

function NotFound() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isAuthenticated = getAuth();
  const roleDashboard = getRoleDashboard();

  const handleButton = () =>{
    if (isAuthenticated && roleDashboard === "Admin") {
      navigate('/Admin-Dashboard');
    }
    else if (isAuthenticated && roleDashboard === "Agent") {
      navigate('/agent-dashboard/Dashboard-Agent');
    }
    else if (isAuthenticated && roleDashboard === "Client") {
      navigate('/dashboard');
    }
    else {
      navigate('/');
    }
  }

  return (
    <>
      {/* <DrawerSidebar /> */}
      {/* <Sidebar /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          padding: 2,
          backgroundColor: "background.body",
        }}
      >
        <Box
          component="img"
          src={NotFoundImage}
          alt="404 Not Found"
          sx={{
            width: "50%",
            maxWidth: "297px",
            marginBottom: "20px",
            marginLeft: "65px",
            [theme.breakpoints.down('sm')]: {
              marginLeft: "17px",
            },
          }}
        />
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: "text.grey", fontWeight: "600",marginBottom:"16px" }}
        >
          SORRY, THE PAGE WAS NOT FOUND
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleButton}
          sx={{
            borderRadius: "5px",
            fontWeight: "600",
            marginBottom: "4px",
            padding: "8px 22px",
          }}
        >
          Go to Home
        </Button>
      </Box>
    </>
  );
}

export default NotFound;
