import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function MessageMoreOption({ content, handleSelectedReplyMsg }) {
   const url = process.env.REACT_APP_IMAGE_URL;
   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleDownload = async () => {
      const imageUrl = url + "/" + content?.message?.image?.path;
      // const imageUrl =s
      //    "https://static.remove.bg/sample-gallery/graphics/bird-thumbnail.jpg";

      try {
         const response = await fetch(imageUrl);

         // Ensure the request was successful
         if (!response.ok) throw new Error("Image download failed");

         const blob = await response.blob(); // Convert the response into a blob
         const url = window.URL.createObjectURL(blob); // Create a temporary URL for the blob

         const link = document.createElement("a");
         link.href = url;
         link.setAttribute("download", "downloaded-image.jpg"); // Specify the downloaded file name
         document.body.appendChild(link);
         link.click();

         // Clean up
         link.remove();
         window.URL.revokeObjectURL(url); // Release the URL object to free memory
      } catch (error) {
         console.error("Failed to download the image:", error);
      }
   };

   const handleMenuItemClick = (menuOption, data) => {
      if (menuOption === "reply") {
         handleSelectedReplyMsg(content);
      }
      if (menuOption === "download") {
         handleDownload();
      }
      handleClose(); // Close the menu after selection
   };

   return (
      <Box
         sx={{
            width: "70px",
            height: "20px",
            position: "absolute",
            bgcolor: "",
            display: "flex",
            justifyContent: "end",
            right: 0,
            top: 6,
            zIndex: 9999,
         }}
      >
         <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={(theme) => ({
               backgroundColor: "transparent",
               "&:hover": {
                  backgroundColor: "transparent",
                  color:
                     content.flag === "incoming" ? "primary.iconmain" : "#fff",

                  //   color: theme.palette.primary.iconmain,
               },
            })}
         >
            <KeyboardArrowDownIcon />
         </IconButton>
         <Menu
            id="long-menu"
            MenuListProps={{
               "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
               style: {
                  width: "10ch",
               },
            }}
         >
            <MenuItem
               sx={{ fontSize: "14px" }}
               // onClick={() => handleSelectedReplyMsg(content)}
               onClick={() => handleMenuItemClick("reply", content)}
            >
               Reply
            </MenuItem>
            {content?.message_type === "image" && (
               <MenuItem
                  sx={{ fontSize: "14px" }}
                  // onClick={() => handleDownload()}
                  onClick={() => handleMenuItemClick("download")}
               >
                  Download
               </MenuItem>
            )}
         </Menu>
      </Box>
   );
}

export default MessageMoreOption;
