import React, { useState } from "react";
import {
   Avatar,
   Box,
   IconButton,
   Menu,
   MenuItem,
   Typography,
   styled,
} from "@mui/material";
import moment from "moment";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReplyMsgBox from "../components/ReplyMsgBox";
import MessageMoreOption from "../components/MessageMoreOption";
import { parseText, sendMessageStatus } from "../../../../utils/helperFuncion";

const Message = styled(Box)(({ left, right }) => ({
   display: "flex",
   flexDirection: "column",
   marginBottom: "5px",
   paddingLeft: left || 0,
   paddingRight: right || 0,
}));

function TextMessage({ content, handleSelectedReplyMsg }) {
   //  const [open, setopen] = useState(true);
   const [anchorEl, setAnchorEl] = useState(null);
   const [isHovered, setIsHovered] = useState(false);
   const open = Boolean(anchorEl);
   const time = moment(content.createdAt).format("h:mm a");
   const statusIcon = sendMessageStatus(content?.status);
   const url = process.env.REACT_APP_IMAGE_URL;
   const path = content?.reply_to?.filePath;

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };


   return (
      <Box
         key={content._id}
         sx={{
            height: "auto",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            maxWidth: content.flag === "incoming" ? "450px" : null,
            justifyContent: content.flag === "outgoing" ? "end" : "start",
         }}
        
      >
         <Message
            left={content.flag === "incoming" ? 20 : 0}
            right={content.flag === "outgoing" ? 20 : 0}
         >
            <Box
               p={1}
               sx={{
                  maxWidth: "300px",
                  height: "auto",
                  minWidth: "70px",
                  color: content.flag === "incoming" ? "text.primary" : "#fff",
                  backgroundColor:
                     content.flag === "incoming"
                        ? "background.incomingmsg"
                        : "background.outgoingmsg",
                  borderRadius:
                     content.flag === "incoming"
                        ? "0px 20px 20px 20px"
                        : "20px 0px 20px 20px",
                  position: "relative",
                  paddingRight: "20px",
                  overflow: "hidden",
                  wordWrap: "break-word",
               }}
               onMouseEnter={() => setIsHovered(true)} // Handle hover enter
               onMouseLeave={() => setIsHovered(false)} // Handle hover leave
            >
               { isHovered&&(
                  <MessageMoreOption
                     content={content}
                     handleSelectedReplyMsg={handleSelectedReplyMsg}
                  />
               )}
               {content?.reply_to && <ReplyMsgBox content={content} />}

               <Typography
                  sx={{
                     fontSize: "13px",
                     marginRight: "10px",
                     color:
                        content.flag === "incoming" ? "text.primary" : "#fff",
                  }}
               >
                  {parseText(content.message?.text,content?.flag)}
               </Typography>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "end",
                     alignItems: "center",
                  }}
               >
                  <Typography
                     sx={{
                        fontSize: "11px",
                        fontWeight: "400",
                        color: content.flag === "incoming" ? "grey" : "#fff",
                        textAlign:
                           content.flag === "incoming" ? "start" : "end",
                     }}
                  >
                     {time}
                  </Typography>
                  {content.flag === "outgoing" && statusIcon}
               </Box>
            </Box>
         </Message>
      </Box>
   );
}

export default TextMessage;
