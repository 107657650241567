import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../../middleware/utils';
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAllAgentSubscriptionPlan = createAsyncThunk("subscriptions/get-agent-plans", async (query = '') => {
    try {
        const response = await apiClient.get(`${API_URL}/subscriptions/get-agent-plans`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err

    }
});
export const fetchAllAgentSubscribedPlan = createAsyncThunk("subscriptions/get-agent-subcribed", async () => {
    try {
        const response = await apiClient.get(`${API_URL}/agent/subscribed-plan`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err
    }
});

const initialState = {
    agentSubscription: [],
    subscribedPlan:{},
    loading: false,
    error: null,
};

const manageSubscriptionPlanSlice = createSlice({
    name: "manage_agent_subscription",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

        // Fetch All Agent Details
        .addCase(fetchAllAgentSubscriptionPlan.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchAllAgentSubscriptionPlan.fulfilled, (state, action) => {
            state.loading = false;
            state.agentSubscription = action.payload;
            state.error = null;
        })
        .addCase(fetchAllAgentSubscriptionPlan.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        // Fetch agent subscribed plan
        .addCase(fetchAllAgentSubscribedPlan.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchAllAgentSubscribedPlan.fulfilled, (state, action) => {
            console.log(action.payload.subscription)
            state.subscribedPlan = action.payload.subscription;
            state.error = null;
        })
        .addCase(fetchAllAgentSubscribedPlan.rejected, (state, action) => {
            state.error = action.payload;
        })


    },
});

export default manageSubscriptionPlanSlice.reducer;       