import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../../middleware/utils";

const API_URL = process.env.REACT_APP_API_URL;

export const createFeature = createAsyncThunk(
   "master_feature/createFeature",
   async (featureData, { rejectWithValue }) => {
      try {
         const response = await apiClient.post(
            `${API_URL}/feature/create-feature`,
            featureData
         );
         return response.data;
      } catch (err) {
         console.log(err);
         return rejectWithValue(
            err.response.data.message || "Failed to Update"
         );
      }
   }
);

export const fetchFeature = createAsyncThunk(
   "master_feature/fetchFeature",
   async (featureData, { rejectWithValue }) => {
      try {
         const response = await apiClient.get(`${API_URL}/feature`);
         return response.data;
      } catch (err) {
         console.log(err);
         return rejectWithValue(
            err.response.data.message || "Failed to Update"
         );
      }
   }
);

export const updateFeature = createAsyncThunk(
   "master_feature/updateFeature",
   async ({ featureData, featureId }, { rejectWithValue }) => {
      console.log(featureData, featureId);
      try {
         const response = await apiClient.put(
            `${API_URL}/feature/edit-feature/${featureId}`,
            featureData
         );
         return response.data;
      } catch (err) {
         console.log(err);
         return rejectWithValue(
            err.response.data.message || "Failed to Update"
         );
      }
   }
);

export const deleteFeature = createAsyncThunk(
   "master_feature/deleteFeature",
   async (featureId, { rejectWithValue }) => {
      try {
         const response = await apiClient.delete(
            `${API_URL}/feature/delete-feature/${featureId}`
         );
         return response.data;
      } catch (err) {
         console.log(err);
         return rejectWithValue(
            err.response?.data?.message || "Failed to delete feature"
         );
      }
   }
);

const initialState = {
   features: [],
   loading: "",
   error: "",
};

const manageFeatureSlice = createSlice({
   name: "master_feature",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         // Fetch Admin Roles
         .addCase(createFeature.pending, (state) => {
            state.loading = true;
         })
         .addCase(createFeature.fulfilled, (state, action) => {
            state.loading = false;
            state.features = [...state.features, ...action.payload];
         })
         .addCase(createFeature.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         })
         .addCase(fetchFeature.pending, (state) => {
            state.loading = true;
         })
         .addCase(fetchFeature.fulfilled, (state, action) => {
            state.loading = false;
            console.log(action.payload);
            state.features = action.payload;
         })
         .addCase(fetchFeature.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         })
         .addCase(deleteFeature.pending, (state) => {
            state.loading = true;
         })
         .addCase(deleteFeature.fulfilled, (state, action) => {
            state.loading = false;
            console.log(action.payload);
            const { data } = action.payload;
            state.features = state.features.filter(
               (feature) => feature._id !== data._id
            );
         })
         .addCase(deleteFeature.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});

export default manageFeatureSlice.reducer;
