import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import apiClient from "../../../../middleware/utils";
import { getAllContacts } from "../../../../store/slices/clientSlice/contactSlice";
import BulkSendStepper from "../components/BulkSendStepper";

const steps = ["Select template", "Add value", "Select contact"];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 28,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage:
      //    "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage:
      //    "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: "#0292d0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.background.lightblue,
  zIndex: 1,
  color: "#0293d2",
  width: 60,
  height: 60,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  // backgroundColor: "#EBF4FB",

  ...(ownerState.active && {
    border: `1px solid ${theme.palette.border.blueborder}`,
    //  boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    backgroundColor: theme.palette.background.lightblue,
    color: "#0293d2",
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.background.lightblue,
    color: "#0293d2"

    // backgroundImage:
    //    "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function BulkSendModal({ open, handleClose }) {

   const theme = useTheme();


  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);

  const [templates, setTemplates] = useState([]);

  const getAllTemplates = async () => {
    const response = await apiClient.get(`/template`);

    const { templates } = response.data;
    setTemplates(templates.data);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  useEffect(() => {
    if (open) {
      getAllTemplates();
      dispatch(getAllContacts());
    }
  }, [open, dispatch]);

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {/* {icons[String(props.icon)]} */}
        <Typography sx={{ fontSize: "24px" }}>{props.icon}</Typography>
      </ColorlibStepIconRoot>
    );
  }
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        textAlign="center"
        sx={{
         padding: "49px 30px 8px 30px",
         backgroundColor: theme.palette.background.modal,
       }}
      >
        <Typography sx={{ color: "text.primary", fontSize: "25px" }}>
          Bulk Send
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={(theme) => ({
            opacity: 0.8,
            fontWeight: 400,
            fontSize: "14px",
            color: theme.palette.text.darkgrey,
            "& span": {
              fontWeight: 600,
            },
          })}
        >
          Complete the steps to send bulk messaages
        </Typography>
        <Box mt={2}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": {
                        color: "primary.main",
                        fontSize: "12px",
                      },
                    }}
                    {...labelProps}
                    StepIconComponent={ColorlibStepIcon}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </DialogTitle>
      <DialogContent>
        <BulkSendStepper
          handleClose={handleClose}
          templates={templates}
          // allContacts={contacts}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleBack={handleBack}
        />
        <DialogContentText id="alert-dialog-description"></DialogContentText>
      </DialogContent>
      <DialogActions sx={{ height: 20 }} onClick={handleBack}>
        {/* <Box display="flex">
               <Button variant="outlined" onClick={handleClose}>
                  Back
               </Button>
            </Box> */}
      </DialogActions>
    </Dialog>
  );
}

export default BulkSendModal;
