import axios from 'axios';
import { getUserToken , getTenantID } from "../middleware/authServices";

const apiClient = axios.create();

apiClient.interceptors.request.use(
    config => {
        const token = getUserToken();
        const tenantID = getTenantID();

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        if (tenantID) {
            config.headers['X-Tenant-ID'] = tenantID;
        }

        return config;
    },
    error => Promise.reject(error)
);

export default apiClient;
