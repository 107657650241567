import { useEffect, useState, useRef } from "react";
import { getDocument } from "pdfjs-dist";
import { Box } from "@mui/material";
import "pdfjs-dist/build/pdf.worker.entry";

const PdfThumbnail = ({ fileUrl }) => {
   const [thumbnailSrc, setThumbnailSrc] = useState(null);
   const canvasRef = useRef(null);

   // useEffect(() => {
   //    const renderPdfThumbnail = async () => {
   //       try {
   //          // Pass the CORS credentials option when fetching the document
   //          const pdf = await getDocument({
   //             url: fileUrl,
   //             withCredentials: true, // Ensure credentials are sent
               
   //          }).promise;
   //          const page = await pdf.getPage(1);
   //          const scale = 0.3;
   //          const viewport = page.getViewport({ scale });
   //          const canvas = canvasRef.current;
   //          const context = canvas.getContext("2d");

   //          canvas.height = viewport.height;
   //          canvas.width = viewport.width;

   //          const renderContext = {
   //             canvasContext: context,
   //             viewport: viewport,
   //          };
   //          page.render(renderContext);
   //       } catch (error) {
   //          console.error("Error rendering PDF:", error);
   //       }
   //    };

   //    renderPdfThumbnail();
   // }, [fileUrl]);

   return <canvas ref={canvasRef} style={{ width: "100%", height: "260px" }} />;
};

export default PdfThumbnail;
