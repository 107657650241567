import { useState, useEffect } from "react";
import { checkIsVariableExistInBody, replaceVariables } from "../utils/helperFuncion";


const useTemplateData = (content, templates) => {
   const [footerMessage, setFooterMessage] = useState("");
   const [bodyMessage, setBodyMessage] = useState("");
   const [headerMessage, setHeaderMessage] = useState({});
   const [buttons, setButtons] = useState([]);

   useEffect(() => {
      if (templates?.length > 0) {
         const templateName = content?.message?.template?.name;
         const selectedTemplateContent = templates.find(
            (temp) => temp.name === templateName
         );

         if (selectedTemplateContent) {
            const { components } = selectedTemplateContent;

            components.forEach((component) => {
               switch (component.type) {
                  case "HEADER":
                     const { format } = component;
                     let headerContent = { type: format };
                     if (format === "TEXT") {
                        headerContent.text = component.text;
                     }
                     const extension = content.message?.template?.mediaUrl
                        ?.split(".")
                        .pop()
                        .split(/\#|\?/)[0];
                     headerContent.url = content.message?.template?.mediaUrl;
                     headerContent.extension = extension;
                     setHeaderMessage(headerContent);
                     break;
                  case "BODY":
                     const bodyText = component?.text;
                     const savedContent = content?.message?.template?.content;
                     if (savedContent) {
                        const isVariableExist =
                           checkIsVariableExistInBody(savedContent);
                        if (isVariableExist) {
                           const result = replaceVariables(
                              bodyText,
                              savedContent
                           );
                           setBodyMessage(result);
                        } else {
                           setBodyMessage(bodyText);
                        }
                     }
                     break;
                  case "FOOTER":
                     setFooterMessage(component.text);
                     break;
                  case "BUTTONS":
                     setButtons(component.buttons);
                     break;
                  default:
                     break;
               }
            });
         }
      }
   }, [content, templates]);

   return { footerMessage, bodyMessage, headerMessage, buttons };
};

export default useTemplateData;
