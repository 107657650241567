import {
   Avatar,
   Box,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   Popover,
   ToggleButton,
   Typography,
   styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import toast, { Toaster } from "react-hot-toast";
import { isValidPhoneNumber } from "react-phone-number-input";
import ChatList from "./ChatList";


// import { createChat } from "../../services/chatService";
import { useTheme } from "@mui/material/styles";
import newChatImage from "../../../assets/images/newchat.svg";
import bulkSendImage from "../../../assets/images/bulksend.svg";
import AddContactImage from "../../../assets/images/addcontact.svg";
import { GoPlusCircle } from "react-icons/go";
import ChatContactsSkeleton from "../skeltons/ChatContactsSkeleton";
import ImportContact from "./Modals/ImportContact";
import AddContacts from "./Modals/AddContacts";
import BulkSendTemplate from "./Modals/BulkSendTemplate";
import NewChat from "./Modals/NewChat";
import { getAllMessagesByChatId, saveSelectedChat } from "../../../store/slices/clientSlice/messageSlice";
import { getAllChats, setGroupChat } from "../../../store/slices/clientSlice/chatSlice";
import BusinessProfile from "./components/BusinessProfile";
import BulkSendModal from "./Modals/BulkSendModal";
import Profile from "./components/Profile";

const UserBox = styled(Box)(({ theme }) => ({
   top: 0,
   height: "100%",
   backgroundColor: theme.palette.background.whatsappbody,
   width: "100%",
}));

const BusinessAvatar = styled(Avatar)({
   color: "text.primary",
   backgroundColor: "transparent",
   width: "45px",
   height: "45px",
   cursor: "pointer",
   transition: "color 0.3s ease",
   "& .MuiAvatarGroup-root": {
      marginLeft: "-15px",
   },
   "&:hover": {
      color: "#0292d0",
   },
});

const CustomListItem = styled(ListItem)(({ theme }) => ({
   "& .MuiButtonBase-root": {
      height: "20px",
   },
}));
const CustomListIcon = styled(ListItemIcon)(({ theme }) => ({
   minWidth: "35px",
}));

const CustomListText = styled(ListItemText)(({ theme }) => ({
   "& .MuiTypography-root": {
      fontSize: "14px",
   },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
   color: theme.palette.text.primary,
   border: "none",
   borderRadius: "5px",
   padding: "7px 9px",
   "&.Mui-selected": {
      backgroundColor: theme.palette.primary.iconmain,
      color: "#fff",
      "&:hover": {
         backgroundColor: theme.palette.primary.iconmain,
         color: "#fff",
      },
   },
   "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.iconmain,
   },
   "&:hover": {
      backgroundColor: theme.palette.primary.iconmain,
      color: "#fff",
   },
}));

const HeaderIconBox = styled(Box)({
   display: "flex",
   justifyContent: "end",
   width: "100px",
   alignItems: "center",
   paddingRight: "5px",
});

function UserSection({ profileData }) {
   const theme = useTheme();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const chatListRef = useRef(null);
   const isDarkMode = theme.palette.mode === "dark";
   const [openProfile, setOpenProfile] = useState(false);
   const [openBusinessProfile, setOpenBusinessProfile] = useState(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const [openBulkSendTemplate, setOpenBulkSendTemplate] = useState(false);
   const [selectedComponent, setSelectedComponent] = useState(null);
   const [newContact, setNewContact] = useState("");
   const [openNewChatPop, setOpenNewChatPop] = useState(false);
   const [isFetching, setIsFetching] = useState(false);
   const [searchText, setSearchText] = useState("");
   const searchResult = useSelector((state) => state.chat.searchResult);
   const { newAddedChat, isGroupChat } = useSelector((state) => state.chat);
   const { chats, page, totalChatCount, loading } = useSelector(
      (state) => state.chat
   );

   //Created debounsing for search with useEffect
   useEffect(() => {
      //Api will call only the the search text >2 letter
      // if (searchText.length < 2) return;
      const timerId = setTimeout(async () => {
         const page = 1;
         dispatch(getAllChats({ searchKey: searchText, page }));
      }, 200);

      //Clearing the timer fuction
      return () => clearTimeout(timerId);
      // eslint-disable-next-line
   }, [searchText]);

   const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = chatListRef.current;
      const reduecedHeight = scrollHeight;

      if (scrollTop + clientHeight >= reduecedHeight) {
         const pageNumber = Number(page) + 1;
         // When the user scrolls to the bottom, trigger the API call
         if (chats.length < totalChatCount) {
            setIsFetching(true);
            dispatch(
               getAllChats({ searchKey: searchText, page: pageNumber })
            ).then(() => {
               setIsFetching(false); // Reset fetching state once API call finishes
            });
         }
      }
   };

   useEffect(() => {
      const div = chatListRef.current;
      if (div) {
         div.addEventListener("scroll", handleScroll);
      }

      return () => {
         if (div) {
            div.removeEventListener("scroll", handleScroll);
         }
      };
   }, [chats.length, page, totalChatCount]);

   //Function to close bulk send modal
   const handleCloseBulkSendModal = () => setOpenBulkSendTemplate(false);

   //Function to enter phone number

   //Function to add new contact and chat
   const addNewChat = async () => {
      if (!isValidPhoneNumber(newContact)) {
         toast.error(" Please enter valid mobile number.");
         return;
      }
      const number = newContact.replace("+", "");

      let contact = number;

     
      // const response = await createChat({ phoneNumber: contact });
      // if (response.status !== "success") {
      //    toast.error(response.message);
      //    setNewContact("");

      //    return;
      // }
      // dispatch(saveSelectedChat(response.chat));
      // dispatch(getAllMessagesByChatId({ chatId: response.chat?._id, page: 1 }));


      // handleClose(false);
      // setNewContact("");
   };

   const handleClick = (event) => setAnchorEl(event.currentTarget);

   const [openMenu, setOpenMenu] = useState(false);
   const open = Boolean(anchorEl);
   const id = open ? "simple-popover" : undefined;
   const handleClose = () => {
      setAnchorEl(null);
      setSelectedComponent(null);
      setOpenMenu(false);
   };

   const handleInputChange = (e) => {
      const searchkey = e.target.value;
      setSearchText(searchkey);
   };

   const handleChangeChat = () => {
      dispatch(setGroupChat());
   };

   const renderComponent = () => {
      switch (selectedComponent) {
         case "NewChat":
            return (
               <NewChat
                  onClose={handleClose}
                  open={openNewChatPop}
                  setOpen={setOpenNewChatPop}
                  anchorEl={anchorEl}
                  addNewChat={addNewChat}
                  setNewContact={setNewContact}
                  newContact={newContact}
               />
            );
         case "BulkSendTemplate":
            return <BulkSendTemplate />;
         case "AddContacts":
            return <AddContacts onClose={handleClose} />;
         case "Groups":
            return navigate("/settings/groups");
         case "ImportContacts":
            return <ImportContact onClose={handleClose} />;
         default:
            return null;
      }
   };

   return (
      <>
         <Box
            sx={{
               position: "relative",
               height: "100%",
               overflow: "hidden",
               borderRight: `1px solid ${theme.palette.border.wpdivider}`,
            }}
         >
            <Toaster />

            <UserBox>
               <Box sx={{ height: "96px", padding: "15px" }}>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Box display="flex" alignItems="center" width="100px">
                        <Typography
                           fontSize="24px"
                           fontWeight={600}
                           sx={{ color: "text.primary", paddingLeft: "3px" }}
                        >
                           WhatsApp
                        </Typography>
                     </Box>
                     <HeaderIconBox gap={2}>
                        <Box sx={{ marginTop: "3.2px", marginRight: "-9px" }}>
                           <StyledToggleButton
                              value="check"
                              selected={openMenu}
                              onChange={() => {
                                 setOpenMenu(!openMenu);
                              }}
                              aria-describedby={id}
                              onClick={handleClick}
                           >
                              <GoPlusCircle
                                 style={{
                                    fontSize: "24px",
                                    cursor: "pointer",
                                    transition: "color 0.3s ease",
                                    marginTop: "0px",
                                    color: "inherit",
                                 }}
                              />
                           </StyledToggleButton>
                        </Box>

                        <BusinessAvatar
                           onClick={() => setOpenBusinessProfile(true)}
                        >
                           <BusinessCenterIcon sx={{ color: "text.primary" }} />
                        </BusinessAvatar>
                        {/* {isGroupChat ? (
                           <Groups2Icon
                              sx={{
                                 color: "text.primary",
                                 transition: "color 0.3s ease",
                                 cursor: "pointer",
                                 "&:hover": {
                                    color: "#0292d0",
                                 },
                              }}
                              onClick={handleChangeChat}
                           />
                        ) : (
                           <PersonIcon
                              sx={{
                                 color: "text.primary",
                                 transition: "color 0.3s ease",
                                 cursor: "pointer",
                                 "&:hover": {
                                    color: "#0292d0",
                                 },
                              }}
                              onClick={handleChangeChat}
                           />
                        )} */}
                        {/* <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" fontSize="16px" /> */}
                        {/* <MoreVertIcon
                           aria-describedby={id}
                           onClick={handleClick}
                           sx={{
                              color: "text.primary",
                              fontSize: "30px",
                              transition: "color 0.3s ease",
                              cursor: "pointer",
                              "&:hover": {
                                 color: "#0292d0",
                              },
                           }}
                        /> */}
                        <Popover
                           id={id}
                           open={open}
                           anchorEl={anchorEl}
                           onClose={handleClose}
                           anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                           }}
                           transformOrigin={{
                              vertical: "top",
                              horizontal: !selectedComponent ? "right" : "left",
                           }}
                           sx={{
                              mt: 1.3,
                              ml: 10,
                           }}
                        >
                           <Box
                              display="flex"
                              flexDirection="column"
                              textAlign="start"
                           >
                              {!selectedComponent ? (
                                 <List
                                    sx={{ fontSize: "14px", cursor: "pointer" }}
                                 >
                                    <ListItem
                                       disablePadding
                                       onClick={() =>
                                          setSelectedComponent("NewChat")
                                       }
                                       sx={{
                                          ":hover": {
                                             backgroundColor: "#8080801f",
                                          },
                                       }}
                                    >
                                       <CustomListItem>
                                          <CustomListIcon>
                                             <img
                                                src={newChatImage}
                                                alt="New Chat"
                                                style={{
                                                   width: "20px",
                                                   height: "20px",
                                                   filter: isDarkMode
                                                      ? "invert(1)"
                                                      : "none",
                                                }}
                                             />
                                          </CustomListIcon>
                                          <CustomListText primary="New Chat" />
                                       </CustomListItem>
                                    </ListItem>
                                    {/* <ListItem
                                       sx={{
                                          ":hover": {
                                             backgroundColor: "#8080801f",
                                          },
                                       }}
                                       disablePadding
                                       onClick={() =>
                                          setOpenBulkSendTemplate(true)
                                       }
                                    >
                                       <CustomListItem>
                                          <CustomListIcon>
                                             <img
                                                src={bulkSendImage}
                                                alt="Bulk Chat"
                                                style={{
                                                   width: "20px",
                                                   height: "20px",
                                                   filter: isDarkMode
                                                      ? "invert(1)"
                                                      : "none",
                                                }}
                                             />
                                          </CustomListIcon>
                                          <CustomListText primary="Bulk send template" />
                                       </CustomListItem>
                                    </ListItem> */}
                                    <ListItem
                                       sx={{
                                          ":hover": {
                                             backgroundColor: "#8080801f",
                                          },
                                       }}
                                       disablePadding
                                       onClick={() =>
                                          setSelectedComponent("AddContacts")
                                       }
                                    >
                                       <CustomListItem>
                                          <CustomListIcon>
                                             <img
                                                src={AddContactImage}
                                                alt="Add Contact"
                                                style={{
                                                   width: "20px",
                                                   height: "20px",
                                                   filter: isDarkMode
                                                      ? "invert(1)"
                                                      : "none",
                                                }}
                                             />
                                             {/* <PersonAddAltOutlinedIcon /> */}
                                          </CustomListIcon>
                                          <CustomListText primary="Add Contacts" />
                                       </CustomListItem>
                                    </ListItem>
                                    <ListItem
                                       sx={{
                                          ":hover": {
                                             backgroundColor: "#8080801f",
                                          },
                                       }}
                                       disablePadding
                                       onClick={() =>
                                          setSelectedComponent("ImportContacts")
                                       }
                                    >
                                       <CustomListItem>
                                          <CustomListIcon>
                                             <img
                                                src={AddContactImage}
                                                alt="Import Contact"
                                                style={{
                                                   width: "20px",
                                                   height: "20px",
                                                   filter: isDarkMode
                                                      ? "invert(1)"
                                                      : "none",
                                                }}
                                             />
                                             {/* <PersonAddAltOutlinedIcon /> */}
                                          </CustomListIcon>
                                          <CustomListText primary="Import Contacts" />
                                       </CustomListItem>
                                    </ListItem>
                                    <ListItem
                                       sx={{
                                          ":hover": {
                                             backgroundColor: "#8080801f",
                                          },
                                       }}
                                       disablePadding
                                       onClick={() =>
                                          setSelectedComponent("Groups")
                                       }
                                    >
                                       <CustomListItem>
                                          <CustomListIcon>
                                             <img
                                                src={AddContactImage}
                                                alt="Import Contact"
                                                style={{
                                                   width: "20px",
                                                   height: "20px",
                                                   filter: isDarkMode
                                                      ? "invert(1)"
                                                      : "none",
                                                }}
                                             />
                                             {/* <PersonAddAltOutlinedIcon /> */}
                                          </CustomListIcon>
                                          <CustomListText primary="Groups" />
                                       </CustomListItem>
                                    </ListItem>
                                 </List>
                              ) : (
                                 <Box p={2}>{renderComponent()}</Box>
                              )}
                           </Box>
                        </Popover>

                        {/* <TuneIcon sx={{ color: "#fff", fontSize: "30px" }} /> */}
                        {/* <MoreVertIcon sx={{ color: "#fff", fontSize: "30px" }} /> */}
                     </HeaderIconBox>
                  </Box>

                  <Box
                     width="100%"
                     display="flex"
                     alignItems="center"
                     justifyContent="space-between"
                     mt={1}
                     mr={2}
                     borderRadius="20px"
                  >
                     <TextField
                        sx={{
                           color: "#fff",
                           backgroundColor: "background.whatsappsearch",
                           borderRadius: "20px",
                           "& .MuiOutlinedInput-root": {
                              borderRadius: "20px",
                           },
                        }}
                        placeholder="Search..."
                        //  variant="standard"
                        size="small"
                        fullWidth
                        value={searchText}
                        onChange={handleInputChange}
                        // onKeyPress={handleKeyPress}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    sx={{
                                       "&:hover": {
                                          backgroundColor: "transparent",
                                       },
                                    }}
                                 >
                                    <SearchIcon
                                       sx={{
                                          color: "inherit",
                                          "&:hover": {
                                             color: "#0292d0",
                                          },
                                       }}
                                    />
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                     {/* <Box><img src={SortIcon} alt="sort" /></Box> */}
                  </Box>
               </Box>
               <Box
                  className="chatlist"
                  ref={chatListRef}
                  sx={{
                     backgroundColor: "background.whatsappbody",
                     height: "100%",
                     // padding: "10px 5px 10px 5px",
                     overflowY: "scroll",
                     padding: "15px",
                     paddingTop: "0px",
                  }}
               >
                  {Object.keys(newAddedChat).length > 0 && (
                     <ChatList key={newAddedChat._id} chatData={newAddedChat} newChat={true} />
                  )}
                  {/* {isGroupChat &&
                     groups?.map((group) => (
                        <GroupChatList key={group?._id} group={group} />
                     ))} */}
                  {!isGroupChat && searchResult?.length > 0 ? (
                     searchResult?.map((chatData) => (
                        <ChatList
                           key={chatData?.chat?._id}
                           chatData={chatData}
                           searchText={searchText}
                        />
                     ))
                  ) : loading ? (
                     [1,2,3,4,5,6,7].map((item) => (
                        <ChatContactsSkeleton key={item} />
                     ))
                  ) : (
                     <span style={{ padding: "10px" }}>No contacts found</span>
                  )}
                  {isFetching &&
                     [1,2,3].map((item) => (
                        <ChatContactsSkeleton key={item} />
                     ))}
               </Box>
            </UserBox>
            <Profile active={openProfile} setOpenProfile={setOpenProfile} />
            <BusinessProfile
               profileData={profileData}
               active={openBusinessProfile}
               setOpenProfile={setOpenBusinessProfile}
            />
         </Box>
         {/* <TemplatePreviewModal open={openBulkSendTemplate} templates={templates}/> */}
         <BulkSendModal
            open={openBulkSendTemplate}
            handleClose={handleCloseBulkSendModal}
         />
      </>
   );
}

export default UserSection;
