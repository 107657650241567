import { Box, Button, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
// import { canSendTemplateMessage } from "../../services/templateService";
import toast from "react-hot-toast";
import { useTheme } from "@mui/material/styles";
import apiClient from "../../../middleware/utils";
import TemplateViewModal from "./TemplateViewModal";

function ListTemplate({
  openTemplateList,
  setOpenTemplateList,
  setMessageList,
  selectedChat,
}) {
  const [openTemplate, setOpenTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();

  const handleOpenTemplateModal = async (item) => {
    //Before opening modal check wheather the template is send before with in one minute
    // If send within one minute we cannot send the same tamplate only send after one minute

    //Make api call to check the above
    const data = {
      templateName: item.name,
      chatId: selectedChat?._id,
    };
    // const response = await canSendTemplateMessage(data);
    // if (response && !response.canSend) {
    //   toast.error("This template can be sent only after one minute");
    // } else {
    //   setSelectedTemplate(item);
    //   setOpenTemplate(true);
    // }
  };
  const handleCloseTemplateModal = () => setOpenTemplate(false);
  const getAllTemplates = async () => {
    const response = await apiClient.get("/template");

    const { templates } = response.data;

    // Function to filter template /only list approved templates
    const approvedTemplates = templates?.data?.filter(
      (temp) => temp.status === "APPROVED"
    );
    setTemplates(approvedTemplates);
  };

  useEffect(() => {
    if (openTemplateList) {
      getAllTemplates();
    }
  }, [openTemplateList]);

  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          width: "90%",
          zIndex: "9999",
          marginRight: "20px",
          height: "500px",
          backgroundColor: theme.palette.background.modal,
          bottom: "100px",
          left: "20px",
          borderRadius: "10px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        }}
      >
        <Box
          height="35px"
          sx={{ margin: "27px 25px -12px 25px" }}
          m={2}
          // borderBottom="1px solid #80808026 "
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{ fontWeight: "600", color: "text.primary",fontSize:"20px" }}
          >
            Templates
          </Typography>
          <IconButton
            sx={{ color: "grey", padding: "6px" }}
            onClick={() => setOpenTemplateList(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "420px",
            m: 2,
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent", 
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
          maxHeight="420px"
          m={2}
        >
          {templates?.map((item) => (
            <Box
              padding="16px 20px"
              m={1.5}
              sx={{
                // boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                backgroundColor: theme.palette.background.backgroundgrey,
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => handleOpenTemplateModal(item)}
            >
              <Typography
                key={item.name}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                  // ":hover": {
                  //   fontWeight: "600",
                  // },
                }}
                mb={0.4}
              >
                {item?.name}
              </Typography>
              {item.components.find((temp) => temp.type === "HEADER") && (
                <Typography
                  sx={{ fontSize: "12px", color: "text.primary" }}
                  mb={0.4}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: theme.palette.text.primary,
                    }}
                  >
                    Header :&nbsp;
                  </span>
                  {
                    item?.components.find((temp) => temp.type === "HEADER")
                      ?.text
                  }
                </Typography>
              )}
              {item.components.find((temp) => temp.type === "BODY") && (
                <Typography
                  sx={{ fontSize: "12px", color: "text.primary" }}
                  mb={0.4}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: theme.palette.text.primary,
                    }}
                  >
                    Body :{" "}
                  </span>
                  {item?.components.find((temp) => temp.type === "BODY")?.text}
                </Typography>
              )}
              {item.components.find((temp) => temp.type === "FOOTER") && (
                <Typography
                  sx={{ fontSize: "12px", color: "text.primary" }}
                  mb={0.4}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: theme.palette.text.primary,
                    }}
                  >
                    Footer :
                  </span>
                  {
                    item?.components.find((temp) => temp.type === "FOOTER")
                      ?.text
                  }
                </Typography>
              )}
              {item.components.find((temp) => temp.type === "BUTTONS") && (
                <Typography
                  sx={{ fontSize: "12px", color: "text.primary" }}
                  mb={0.4}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: theme.palette.text.primary,
                    }}
                  >
                    Footer :
                  </span>
                  {
                    item?.components.find((temp) => temp.type === "BUTTONS")
                      ?.text
                  }
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <TemplateViewModal
        setMessageList={setMessageList}
        openTemplate={openTemplate}
        handleClose={handleCloseTemplateModal}
        selectedTemplate={selectedTemplate}
        setOpenTemplateList={setOpenTemplateList}
        selectedChat={selectedChat}
      />
    </Box>
  );
}

export default ListTemplate;
