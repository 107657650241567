import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../../middleware/utils';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchAdminUsers = createAsyncThunk("users/getAllAdminUsers", async (query = '') => {
    try {
        const response = await apiClient.get(`${API_URL}/admin-user/get-admin-users`);
        return response.data;
    } catch (err) {
        console.log(err);
    }
});

export const createAdminUsers = createAsyncThunk("users/createAdminUsers", async (userData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/admin-user/create-admin-user`, userData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});

export const updateAdminUsers = createAsyncThunk("users/updateAdminUsers", async (userData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/admin-user/update-admin-user`, userData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});

export const deleteAdminUsers = createAsyncThunk("users/deleteAdminUsers", async (userData, { rejectWithValue }) => {
    try {
        await apiClient.post(`${API_URL}/admin-user/delete-admin-user`, userData);
        return userData.id;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Delete');
    }
});

export const userChangePassword = createAsyncThunk("users/userChangePassword", async (userData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/admin-user/admin-change-password`, userData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});


const initialState = {
    users: [],
    error: null,
};

const manageUsersSlice = createSlice({
    name: "manage_users",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Admin Users
            .addCase(fetchAdminUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAdminUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload;
                state.error = null;
            })
            .addCase(fetchAdminUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Create Admin USers
            .addCase(createAdminUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(createAdminUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = [...state.users, action.payload];
                state.error = null;
            })
            .addCase(createAdminUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Update Admin USers
            .addCase(updateAdminUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateAdminUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = [...state.users, action.payload];
                state.error = null;
            })
            .addCase(updateAdminUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            //Delete Admin USers
            .addCase(deleteAdminUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteAdminUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = state.users.filter(users => users._id !== action.payload);
                state.error = null;
            })
            .addCase(deleteAdminUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            //Admin Change Password 
            .addCase(userChangePassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(userChangePassword.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload;
                state.error = null;
            })
            .addCase(userChangePassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default manageUsersSlice.reducer;