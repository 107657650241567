import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import { useRef } from "react";

// const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
//    position: "absolute",
//    borderRadius: "10px",
//    "&.MuiSpeedDial-fab": {
//       borderRadius: "10px",
//    },
//    "& .MuiButtonBase-root": {
//       borderRadius: "10px",
//       width: 60,
//       height: 50,
//       backgroundColor: theme.palette.background.chatinput,
//       color: theme.palette.primary.iconmain,
//       boxShadow:
//          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
//       ":hover": {
//          backgroundColor: theme.palette.background.chatinput,
//       },
//    },
//    // "& .MuiSpeedDial-actions": {
//    //    // backgroundColor: "blue",
//    //    "& .MuiButtonBase-root": {
//    //       borderRadius: "50%",
//    //       width: 60,
//    //       height: 60,
//    //       backgroundColor: "grey",
//    //    },
//    // },

//    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
//       bottom: theme.spacing(-9.3),
//       // right: theme.spacing(),
//       left: theme.spacing(2),
//    },
//    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
//       top: theme.spacing(2),
//       left: theme.spacing(2),
//    },
// }));

const StyledSpeedDialAction = styled(SpeedDialAction)(({ color }) => ({
   width: "60px",
   height: "60px",
   backgroundColor: `${color} !important`,
   "&:hover": {
      backgroundColor: `${color} !important`,
   },
}));

export default function CustomSpeedDial({
   handleOpenTemplate,
   // fileuploadRef,
   handleFileChange,
   isChatExpired,
   // handleClickFileUpload,
}) {
   const [hidden, setHidden] = React.useState(false);
   const theme = useTheme();

   const fileuploadRef = useRef(null);
   const imageUploadRef = useRef(null);
   const musicUploadRef = useRef(null);
   const handleClickFileUpload = () => {
      fileuploadRef.current.click();
   };
   const handleClickImageUpload = () => {
      fileuploadRef.current.click();
   };
   const handleClickMusicUpload = () => {
      musicUploadRef.current.click();
   };

   const actions = [
      {
         icon: (
            <FontAwesomeIcon
               style={{
                  cursor: "pointer",
                  color: "#6e20ff",
                  fontSize: "18px",
               }}
               icon="fa-solid fa-layer-group"
            />
         ),
         name: "Templates",
         bgColor:theme.palette.background.template,
         onClick: () => handleOpenTemplate(),
      },
      {
         icon: <ImageIcon sx={{ color:isChatExpired?"grey": "#ff843f", fontSize: "22px" }} />,
         name: "Image",
         bgColor:isChatExpired?"whiteSmoke":  theme.palette.background.image,
         onClick:isChatExpired?()=>console.log(""): () => handleClickFileUpload(),
      },
      {
         // icon: <MusicNoteIcon sx={{ color: "#ff4a88",fontSize:"22px" }} />,
         icon: (
            <FontAwesomeIcon
               style={{
                  cursor: "pointer",
                  color:isChatExpired?"grey": "#ff4a88",
                  fontSize: "20px",
               }}
               icon="fa-solid fa-music"
            />
         ),
         name: "Music",
         bgColor:isChatExpired?"whiteSmoke":  theme.palette.background.music,
         onClick:isChatExpired?()=>console.log(""): () => handleClickMusicUpload(),
      },
      {
         icon: (
            <DescriptionIcon
               fontSize="large"
               sx={{ color:isChatExpired?"grey": "#2a13ff", fontSize: "24px" }}
            />
         ),
         name: "File",
         bgColor:isChatExpired?"whiteSmoke": theme.palette.background.file,
         onClick:isChatExpired?()=>console.log(""): () => handleClickFileUpload(),
      },
   ];
   const handleHiddenChange = (event) => {
      setHidden(event.target.checked);
   };
   // const StyledSpeedDialAction = styled(SpeedDialAction)(
   //    ({ theme, color }) => ({
   //       width: "60px",
   //       height: "60px",

   //       backgroundColor: color,
   //       "&:hover": {
   //          backgroundColor: color,
   //       },
   //       // "& .MuiButtonBase-root": {
   //       //    width: "60px",
   //       //    height: "60px",
   //       //    borderRadius: "50%",
   //       // },
   //    })
   // );
   return (
      <>
         <input
            style={{ display: "none" }}
            type="file"
            accept="image/,video/"
            ref={fileuploadRef}
            onChange={handleFileChange}
         />
         <input
            style={{ display: "none" }}
            type="file"
            accept=".mp3"
            ref={musicUploadRef}
            onChange={handleFileChange}
         />
         <Box
            sx={{
               transform: "translateZ(0px)",
               flexGrow: 1,
               borderRadius: "15px !important",
               width: "60px",
               height: "60px",
            }}
         >
            <Box sx={{ position: "relative" }}>
               <SpeedDial
                  sx={{
                     position: "absolute",
                     borderRadius: "10px Important",

                     "& .MuiButtonBase-root.MuiFab-root": {
                        // Applying specific background only to the main button
                        borderRadius: "10px",
                        width: 60,
                        height: 50,
                        backgroundColor: theme.palette.background.chatinput, // Only for main button
                        color: theme.palette.primary.iconmain,
                        boxShadow:
                           "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                        ":hover": {
                           backgroundColor: theme.palette.background.chatinput,
                        },
                     },
                     "& .MuiSpeedDialIcon-root": {
                        // Styling the plus icon inside the main button
                        backgroundColor: theme.palette.background.chatinput, // Specific to the plus icon
                     },
                     "& .MuiButtonBase-root": {
                        borderRadius: "10%",
                        width: 60,
                        height: 50,
                     },
                     "&.MuiSpeedDial-fab": {
                        borderRadius: "10px important",
                     },
                     "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft":
                        {
                           bottom: theme.spacing(-9.3),
                           // right: theme.spacing(),
                           left: theme.spacing(2),
                        },
                     "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight":
                        {
                           top: theme.spacing(2),
                           left: theme.spacing(2),
                        },

                     "& .MuiSpeedDial-actions": {
                        marginBottom: "1px",
                        paddingBottom: "15px",
                        borderRadius: "10px",

                        "& .MuiButtonBase-root": {
                           borderRadius: "50%",
                           width: 60,
                           height: 60,
                           // backgroundColor: "grey",
                        },
                     },
                     "& .css-u1qjuz-MuiSpeedDial-actions": {
                        backgroundColor:
                           theme.palette.mode === "dark" ? "#353535" : "#fff",
                        transform: "translateZ(0px)",
                     },
                  }}
                  ariaLabel="SpeedDial playground example"
                  // sx={{ position: 'absolute', bottom: -77, right: 16 }}
                  hidden={hidden}
                  icon={<SpeedDialIcon />}
                  direction="up"
               >
                  {actions.map((action) => (
                     <StyledSpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        color={action.bgColor}
                        tooltipTitle={action.name}
                        fontSize={action.fontSize}
                        onClick={() => {
                           action.onClick(); // Call the action's onClick function
                        }}
                     />
                  ))}
               </SpeedDial>
            </Box>
         </Box>
      </>
   );
}
