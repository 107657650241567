import { Avatar, Box, Typography, styled } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import pdfIcon from "../../../../assets/images/pdf.png";
import wordIcon from "../../../../assets/images/word.jpg";
import excelfIcon from "../../../../assets/images/excel-logo.png";
import { useTheme } from "@mui/material/styles";

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import PdfViewer from ".././components/PdfViewer"
import { sendMessageStatus } from "../../../../utils/helperFuncion";
import MessageMoreOption from "../components/MessageMoreOption";
import ReplyMsgBox from "../components/ReplyMsgBox";

const Message = styled(Box)(({ left, right }) => ({
   display: "flex",
   flexDirection: "column",
   marginBottom: "5px",
   paddingLeft: left || 0,
   paddingRight: right || 0,
}));

function DocumentMessage({ content, handleSelectedReplyMsg }) {
   const theme = useTheme();
   const [loaded, setLoaded] = useState(false);
   const defaultLayoutPluginInstance = defaultLayoutPlugin();

   const url = process.env.REACT_APP_IMAGE_URL;
   const time = moment(content.createdAt).format("h:mm a");
   const [isHovered, setIsHovered] = useState(false);

   const statusIcon = sendMessageStatus(content?.status);
   const { message } = content;

   const extension =
      message?.image?.path?.split(".").pop() ||
      message?.document?.path?.split(".").pop();
   const fileName = message?.image?.filename || message?.document?.filename;
   const documentUrl = `${url}/${
      message?.image?.path || message?.document?.path
   }`;
   const caption = message?.document?.caption;
   // Map file extensions to document types
   const documentType =
      {
         xls: "excel",
         xlsx: "excel",
         doc: "word",
         docx: "word",
         pdf: "pdf",
      }[extension] || "unknown";

   console.log(documentType);

   const fileUrl = extension === "pdf" ? documentUrl : "";

   const handleDownload = () => {
      // Create an invisible anchor element
      const link = document.createElement("a");
      link.href = documentUrl;
      link.download = fileName; // Optional: specify a default file name
      link.click(); // Trigger the download
   };

   return (
      <>
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: content.flag === "incoming" ? "start" : "end",
            }}
         >
            <Message
               left={content.flag === "incoming" ? 20 : 0}
               right={content.flag === "outgoing" ? 20 : 0}
            >
               <Box
                  mt={1}
                  sx={{
                     padding: "4px 4px 4px 4px",
                     width: "250px",
                     position: "relative",
                     color:
                        content.flag === "incoming" ? "text.primary" : "#fff",
                     backgroundColor:
                        content.flag === "incoming"
                           ? "background.incomingmsg"
                           : "background.outgoingmsg",
                     borderRadius:
                        content.flag === "incoming"
                           ? "0px 20px 20px 20px"
                           : "20px 0px 20px 20px",
                  }}
                  onMouseEnter={() => setIsHovered(true)} // Handle hover enter
                  onMouseLeave={() => setIsHovered(false)}
               >
                  {isHovered && (
                     <MessageMoreOption
                        content={content}
                        handleSelectedReplyMsg={handleSelectedReplyMsg}
                     />
                  )}
                  {content?.reply_to && <ReplyMsgBox content={content} />}

                  <Box
                     sx={{
                        margin: "5px",
                        padding: "5px",
                        borderRadius: "10px",
                        backgroundColor:
                           content.flag === "incoming"
                              ? "background.incomingmsg"
                              : "#ffffff12",
                        display: "flex",
                     }}
                  >
                     {/* {extension === "pdf" && loaded ? (
                        <PdfViewer fileUrl={fileUrl} />
                     ) : (
                        <IconButton
                           sx={{
                              color: "#f44336", // Red color like PDF
                              fontSize: "3rem",
                           }}
                           onClick={() => setLoaded(true)} // Click to load the PDF first page
                        >
                           <PictureAsPdfIcon sx={{ fontSize: "40px" }} />
                        </IconButton>
                     )} */}
                     {documentType === "pdf" ? (
                        <Box bgcolor="#0292d0" width="100%" position="relative">
                           <PdfViewer fileUrl={fileUrl} />
                           <Box
                              width="100%"
                              position="absolute"
                              bottom={0}
                              height={70}
                              bgcolor="white"
                              display="flex"
                              alignItems="center"
                           >
                              <Box
                                 m={0.5}
                                 width="200px"
                                 display="flex"
                                 alignItems="center"
                                 gap={1}
                              >
                                 <img
                                    src={pdfIcon}
                                    alt="pdf"
                                    height={30}
                                    width={25}
                                 />
                                 <a
                                    href={documentUrl}
                                    style={{ textDecoration: "none" }}
                                 >
                                    <Typography fontSize="12px" color="black">
                                       {fileName}
                                    </Typography>
                                 </a>
                                 <ArrowCircleDownIcon
                                    sx={{ cursor: "pointer", color: "black" }}
                                    onClick={handleDownload}
                                 />
                              </Box>
                           </Box>
                        </Box>
                     ) : (
                        <a
                           href={documentUrl}
                           target="_blank"
                           rel="noopener noreferrer"
                           className="links-hover"
                        >
                           <div
                              style={{
                                 height: "40px",
                                 width: "30px",
                                 display: "flex",
                              }}
                           >
                              {documentType === "excel" && (
                                 <img
                                    src={excelfIcon}
                                    alt="document"
                                    width="100%"
                                    height="30px"
                                 />
                              )}
                              {(documentType === "word" ||
                                 extension === "document") && (
                                 <img
                                    src={wordIcon}
                                    alt="document"
                                    width="100%"
                                    height="30px"
                                 />
                              )}
                           </div>
                           <div>
                              <p
                                 style={{
                                    color:
                                       content.flag === "incoming"
                                          ? theme.palette.text.primary
                                          : "#fff",
                                    fontSize: "11px",
                                 }}
                              >
                                 {fileName}
                              </p>
                           </div>
                        </a>
                     )}
                  </Box>
                  {caption && (
                     <Typography sx={{ fontSize: "13px", padding: "10px" }}>
                        {caption}
                     </Typography>
                  )}
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                     }}
                  >
                     <Typography
                        sx={{
                           fontSize: "11px",
                           fontWeight: "400",
                           color: content.flag === "incoming" ? "grey" : "#fff",
                           textAlign:
                              content.flag === "incoming" ? "start" : "end",
                        }}
                     >
                        {time}
                     </Typography>
                     {content.flag === "outgoing" && statusIcon}
                  </Box>
               </Box>
            </Message>
         </Box>
      </>
   );
}

export default DocumentMessage;
