import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../../middleware/utils";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAllClientSubscriptionPlan = createAsyncThunk(
   "subscriptions/get-client-plans",
   async (query = "") => {
      try {
         const response = await apiClient.get(
            `${API_URL}/subscriptions/get-client-plans`
         );
         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);

export const createClientSubscriptionPlan = createAsyncThunk(
   "subscriptions/create-client-plans",
   async (plan, { rejectWithValue }) => {
      try {
         const response = await apiClient.post(
            `${API_URL}/subscriptions/create-subscription`,
            plan
         );
         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);
export const updateClientSubscriptionPlan = createAsyncThunk(
   "subscriptions/edit-client-plans",
   async ({ planData, planId }, { rejectWithValue }) => {
      try {
         const response = await apiClient.post(
            `${API_URL}/subscriptions/update-subscription`,
            planData
         );
         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);

export const deleteClientSubscriptionPlan = createAsyncThunk(
   "subscriptions/delete-client-plans",
   async (planId, { rejectWithValue }) => {
      try {
         const response = await apiClient.post(
            `${API_URL}/subscriptions/delete-subscription`,
            { id: planId }
         );
         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);

const initialState = {
   clientSubscription: [],
   loading: false,
   error: null,
   success: false,
};

const manageSubscriptionClientPlanSlice = createSlice({
   name: "manage_client_subscription",
   initialState,
   reducers: {
      resetSuccess(state) {
         state.success = false;
      },
   },
   extraReducers: (builder) => {
      builder

         // Fetch All Agent Details
         .addCase(fetchAllClientSubscriptionPlan.pending, (state) => {
            state.loading = true;
         })
         .addCase(fetchAllClientSubscriptionPlan.fulfilled, (state, action) => {
            state.loading = false;
            state.clientSubscription = action.payload;
            state.error = null;
         })
         .addCase(fetchAllClientSubscriptionPlan.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         })
         // Create Plan Action Cases
         .addCase(createClientSubscriptionPlan.pending, (state) => {
            state.loading = true;
            state.success = false;
         })
         .addCase(createClientSubscriptionPlan.fulfilled, (state) => {
            state.loading = false;
            state.success = true; // Set success to true on fulfilled
            state.error = null;
         })
         .addCase(createClientSubscriptionPlan.rejected, (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
         })
         // Create Plan Action Cases
         .addCase(deleteClientSubscriptionPlan.pending, (state) => {
            state.loading = true;
         })
         .addCase(deleteClientSubscriptionPlan.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
         })
         .addCase(deleteClientSubscriptionPlan.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});
export const { resetSuccess } = manageSubscriptionClientPlanSlice.actions;

export default manageSubscriptionClientPlanSlice.reducer;
