import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../../middleware/utils";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchTransactions = createAsyncThunk(
   "transaction/fetchTransactions",
   async () => {
      try {
         const response = await apiClient.get(`${API_URL}/agent/transactions`);
         return response.data;
      } catch (err) {
         console.log(err);
      }
   }
);

export const fetchAllAgentTransactions = createAsyncThunk(
    "transaction/fetchAllAgentTransactions",
    async () => {
       try {
          const response = await apiClient.get(`${API_URL}/agent/all-agent/transactions`);
          return response.data;
       } catch (err) {
          console.log(err);
       }
    }
 );

 export const fetchAllClientTransactions = createAsyncThunk(
    "transaction/fetchAllClientTransactions",
    async () => {
       try {
          const response = await apiClient.get(`${API_URL}/agent/all-client/transactions`);
          return response.data;
       } catch (err) {
          console.log(err);
       }
    }
 );

const initialState = {
   transactions: [],
   agentTransactions: [],
   clientTransactions: [],
   error: null,
};

const transactionSlice = createSlice({
   name: "transaction",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         // Fetch Admin Roles
         .addCase(fetchTransactions.pending, (state) => {
            state.loading = true;
         })
         .addCase(fetchTransactions.fulfilled, (state, action) => {
            state.loading = false;
            state.transactions = action.payload;
            state.error = null;
         })
         .addCase(fetchTransactions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         })
         // Fetch all agent transactions
         .addCase(fetchAllAgentTransactions.pending, (state) => {
            state.loading = true;
         })
         .addCase(fetchAllAgentTransactions.fulfilled, (state, action) => {
            state.loading = false;
            state.agentTransactions = action.payload;
            state.error = null;
         })
         .addCase(fetchAllAgentTransactions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         })
          // Fetch all client transactions
          .addCase(fetchAllClientTransactions.pending, (state) => {
            state.loading = true;
         })
         .addCase(fetchAllClientTransactions.fulfilled, (state, action) => {
            state.loading = false;
            state.clientTransactions = action.payload;
            state.error = null;
         })
         .addCase(fetchAllClientTransactions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});

export default transactionSlice.reducer;
