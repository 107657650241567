import {
   Alert,
   Box,
   Button,
   DialogActions,
   FormControl,
   Input,
   InputAdornment,
   TextField,
   Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useTheme } from "@mui/material/styles";

const backBtn = (theme) => ({
   width: "auto",
   backgroundColor: theme.palette.background.bgbutton,
   textTransform: "capitalize",
   border: "0.5px solid #0293d2",
   color: "#0293d2",
   paddingLeft: "22px",
   paddingRight: "22px",
   borderRadius: "5px",
   "&:hover": {
      backgroundColor: "#0293d2",
      color: "#fff",
      border: "0.5px solid #0293d2",
   },
});

function BulkTemplateViewModal({
   selectedTemplate,
   setNewTemplateData,
   handleNext,
   handleBack,
}) {
   const components = selectedTemplate?.components || [];
   const theme = useTheme();

   const [file, setFile] = useState("");
   const [bodyContent, setBodyContent] = useState("");
   // State for handling if the header opion is link
   const [linkOption, setLinkOpen] = useState(false);
   // const [mediaLink, setMediaLink] = useState("");
   const [mediaUrl, setMediaUrl] = useState("");
   const [previewImage, setPreviewImage] = useState("");
   const [variable, setVariable] = useState([]);
   const [validationError, setValidationError] = useState("");
   const [button, setButton] = useState([]);
   const [headerType, setHeaderType] = useState("");
   const [headerText, setHeaderText] = useState("");
   const [urlButton, setUrlButton] = useState("");

   useEffect(() => {
      if (!selectedTemplate) return;


      const components = selectedTemplate.components || [];
      const bodyComponent = components.find((item) => item.type === "BODY");
      const headerContent = components.find((item) => item.type === "HEADER");

      if (headerContent) {
         setHeaderType(headerContent.format);
         if (headerContent.format === "TEXT") {
            setHeaderText(headerContent.text);
         }
      }
      if (bodyComponent) {
         const bodyText = bodyComponent.text || "";
         const regex = /\{\{\d+\}\}/g;

         // Use match() to find all occurrences of the pattern in the text
         const matches = bodyText.match(regex) || [];
         if (matches.length > 0) setBodyContent(true);

         // Map matches to variable objects
         const allVariables = matches.map((item) => ({
            id: item,
            type: "text",
            text: "",
         }));

         // Update state with the extracted variables
         setVariable(allVariables);
      }
      const buttonContent = components.find((item) => item.type === "BUTTONS");

      if (buttonContent) {
         let newButtons = [];
         buttonContent?.buttons?.map((btn, index) => {
            newButtons.push({ ...btn, index });
         });

         setButton(newButtons);
      }
   }, [components]);

   // Function to send template message
   const handleSendMessage = async () => {
      if (variable) {
         const variableWithoutText = variable.filter(
            (item) => item.text === ""
         );

         if (variableWithoutText.length > 0)
            return setValidationError("All fields required");

         setValidationError("");
      }
      let formData = new FormData();

      if (file) {
         formData.append("image", file);
         const head = {
            type: headerType,
         };
         formData.append("header", JSON.stringify(head));
      }

      if (bodyContent) {
         const bodyArray = [];

         // Add each object from 'variable' array to 'bodyArray'
         variable.forEach((obj) => {
            bodyArray.push(obj);
         });

         // Append 'bodyArray' as a single entry named 'body'
         formData.append("body", JSON.stringify(bodyArray));
      }

      if (urlButton) {
         const dynamicUrlBtn = button?.find((btn) => btn.type === "URL");
         let buttonComponent = {
            type: "button",
            sub_type: "url",
            index: dynamicUrlBtn?.index,
            parameters: [
               {
                  type: "payload",
                  payload: urlButton,
               },
            ],
         };
         formData.append("button", JSON.stringify(buttonComponent));
      }
      // const to = 919446767626;
      const to = 916238802949;
      const template = JSON.stringify({
         // templateName: selectedTemplate?.name,/
         templateName: selectedTemplate?.name,
         languageCode: selectedTemplate?.language,
      });

      // formData.append("template", true);
      formData.append("template", template);
      formData.append("to", to);
      formData.append("type", "template");

      setNewTemplateData(formData);
      handleNext();
   };

   const fileUploadRef = useRef(null);
   const handleUploadFile = () => {
      fileUploadRef.current.click();
   };

   const handleChange = (e, itemId) => {
      const newText = e.target.value;

      const result = variable.map((vari) => {
         if (vari.id === itemId) {
            vari.text = newText;
         }
         return vari;
      });
      setVariable(result);
   };

   const handleFileinputChange = (e) => {
      let file = e.target.files[0];
      if (file) {
         setFile(file);
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onloadend = () => {
            setPreviewImage(reader.result);
         };
      }
   };

   const renderHeader = () => {
      if (!headerType) return null;

      if (headerType === "TEXT") {
         return (
            <Box>
               <Typography
                  variant="body1"
                  sx={{ color: "text.primary", fontSize: "16px" }}
               >
                  Header
               </Typography>
               <Box
                  mt={1}
                  bgcolor={theme.palette.background.backgroundgrey}
                  p={3}
                  borderRadius="5px"
               >
                  <Typography variant="caption" color="text.primary">
                     {headerText}
                  </Typography>
               </Box>
            </Box>
         );
      }

      return (
         <Box mt={1}>
            <Typography
               variant="body1"
               textTransform="capitalize"
               color="text.primary"
            >
               Header : {headerType}
            </Typography>
            <Box
               mt={1}
               p={3}
               bgcolor={theme.palette.background.backgroundgrey}
               height="150px"
               borderRadius="5px"
            >
               {!linkOption ? (
                  <Box
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                  >
                     <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                     >
                        {previewImage ? (
                           <Box sx={{ width: "100px", display: "flex" }}>
                              <img
                                 src={previewImage}
                                 alt="uploaded"
                                 style={{ width: "100%" }}
                              />
                              <HighlightOffRoundedIcon
                                 sx={{ cursor: "pointer" }}
                                 onClick={() => setPreviewImage(null)}
                              />
                           </Box>
                        ) : (
                           <>
                              <CloudUploadIcon
                                 sx={{
                                    fontSize: "50px",
                                    cursor: "pointer",
                                    color: "text.primary",
                                 }}
                                 onClick={handleUploadFile}
                              />
                              <Typography
                                 variant="caption"
                                 color="text.primary"
                              >
                                 Upload a file
                              </Typography>
                           </>
                        )}
                        <input
                           ref={fileUploadRef}
                           type="file"
                           accept={
                              headerType === "IMAGE"
                                 ? "image/*"
                                 : headerType === "VIDEO"
                                 ? "video/*"
                                 : ""
                           }
                           style={{ display: "none" }}
                           onChange={handleFileinputChange}
                        />
                     </Box>
                  </Box>
               ) : (
                  <TextField
                     sx={{ marginTop: "20px", color: "text.primary" }}
                     label="URL"
                     value={mediaUrl}
                     fullWidth
                     onChange={(e) => setMediaUrl(e.target.value)}
                  />
               )}
            </Box>
         </Box>
      );
   };

   const renderBody = () => {
      const bodyComponent = components.find((item) => item.type === "BODY");
      if (!bodyComponent) return null;

      return (
         <Box mt={1}>
            <Typography
               variant="body1"
               sx={{ color: "text.primary", fontSize: "16px" }}
            >
               Body
            </Typography>
            <Box
               mt={1}
               bgcolor={theme.palette.background.backgroundgrey}
               p={3}
               borderRadius="5px"
            >
               <Typography
                  sx={{ marginLeft: "5px", color: "text.primary" }}
                  variant="caption"
               >
                  {bodyComponent.text}
               </Typography>

               <Box>
                  {variable?.map((item) => (
                     <FormControl
                        fullWidth
                        sx={{ m: 1, width: "98%" }}
                        variant="standard"
                        key={item?.id}
                     >
                        <Input
                           id="standard-adornment-amount"
                           onChange={(e) => handleChange(e, item?.id)}
                           sx={{ fontSize: "12px", color: "text.primary" }}
                           startAdornment={
                              <InputAdornment position="start">
                                 {item?.id}
                              </InputAdornment>
                           }
                        />
                     </FormControl>
                  ))}
               </Box>
            </Box>
         </Box>
      );
   };

   const renderFooter = () => {
      const footerComponent = components.find((item) => item.type === "FOOTER");
      if (!footerComponent) return null;

      return (
         <Box mt={1} minHeight={40}>
            <Typography
               variant="body1"
               sx={{ color: "text.primary", fontSize: "16px" }}
            >
               Footer
            </Typography>
            <Box
               mt={1}
               bgcolor={theme.palette.background.backgroundgrey}
               p={3}
               borderRadius="5px"
            >
               <Typography variant="caption" color="text.primary">
                  {footerComponent.text}
               </Typography>
            </Box>
         </Box>
      );
   };

   const renderButtons = () => {
      const buttonComponent = components?.find(
         (item) => item.type === "BUTTONS"
      );
      if (!buttonComponent) return null;

      return (
         <Box mt={1} borderRadius="5px">
            <Typography
               variant="body2"
               sx={{ color: "text.primary", fontSize: "16px" }}
            >
               Button
            </Typography>
            <Box
               mt={1}
               display="flex"
               flexDirection="column"
               bgcolor={theme.palette.background.backgroundgrey}
               p={3}
               borderRadius="5px"
            >
               {button?.map((btn) => (
                  <Box
                     key={btn?.text}
                     mt={1}
                     borderBottom="1px solid #fff"
                     mb={1}
                  >
                     <Typography
                        fontSize="12px"
                        color="text.primary"
                        fontWeight={600}
                        mb={0.3}
                     >
                        Type of Action :
                        <span style={{ fontWeight: "400" }}>{btn?.type}</span>
                     </Typography>
                     <Typography
                        fontSize="12px"
                        color="text.primary"
                        fontWeight={600}
                        mb={0.3}
                     >
                        Button Text
                     </Typography>
                     <Button
                        mb={0.5}
                        sx={{
                           color: "text.primary",
                           textTransform: "capitalize",
                           marginTop: "5px",
                           fontSize: "12px",
                           paddingTop: "3px",
                           paddingBottom: "3px",
                           backgroundColor: theme.palette.background.bgbutton,
                           border: "0.5px solid #0293d2",
                           borderRadius: "5px",
                           "&:hover": {
                              color: "#ffffff",
                           },
                        }}
                     >
                        {btn?.text}
                     </Button>
                     <Typography
                        fontSize="12px"
                        marginTop="5px"
                        color="text.primary"
                        mb={0.9}
                     >
                        {btn?.url || btn?.phone_number}
                     </Typography>
                     {btn?.example && (
                        <TextField
                           fullWidth
                           label="{{1}}"
                           value={urlButton}
                           onChange={(e) => setUrlButton(e.target.value)}
                           size="small"
                        />
                     )}
                  </Box>
               ))}
            </Box>
         </Box>
      );
   };

   return (
      <>
         <Box>
            {validationError && (
               <Alert severity="error">{validationError}</Alert>
            )}
            {/* <Typography fontSize="14px">{selectedTemplate?.name}</Typography> */}
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
            {renderButtons()}
         </Box>

         <Box mt={2} display="flex" justifyContent="space-between">
            <Button onClick={handleBack} variant="outlined" sx={backBtn(theme)}>
               Back
            </Button>

            <Button
               onClick={handleSendMessage}
               variant="contained"
               color="primary"
               sx={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
               }}
            >
               Save & Next
            </Button>
         </Box>
      </>
   );
}

export default BulkTemplateViewModal;
