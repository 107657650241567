import { Skeleton, Box, Divider } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

function ChatHeaderSkeleton({ sx }) {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          ...sx,
        }}
      >
        {/* Circular Skeleton */}
        <Skeleton
          variant="circular"
          width={45}
          height={45}
          sx={{
            bgcolor: theme.palette.background.skeleton,
          }}
        />

        {/* Rectangular Skeletons for lines */}
        <Box sx={{ ml: 2 }}>
          <Skeleton
            variant="rectangular"
            width={150}
            height={10}
            sx={{
              bgcolor: theme.palette.background.skeleton,
              mb: 1,
              borderRadius: "4px",
            }}
          />
          <Skeleton
            variant="rectangular"
            width={100}
            height={10}
            sx={{
              bgcolor: theme.palette.background.skeleton,
              borderRadius: "4px",
            }}
          />
        </Box>
      </Box>

      {/* Divider below the Box */}
      <Divider
        sx={{
          bgcolor: theme.palette.border.skeletondivider,
        }}
      />
    </>
  );
}

export default ChatHeaderSkeleton;


// import { Skeleton } from '@mui/material'
// import React from 'react'

// function ChatHeaderSkeleton() {
//   return (
//     <Skeleton variant="rounded"  height={90} sx={{borderRadius: "15px 15px 0px 0px",bgcolor:'white'}}/>
//   )
// }

// export default ChatHeaderSkeleton
