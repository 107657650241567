import React, { useState } from "react";
import { OuterBox } from "../ManageAgents/UserStyles";
import {
   Alert,
   Box,
   Button,
   Checkbox,
   Grid,
   IconButton,
   Table,
   TextField,
   Typography,
   TableHead,
   TableRow,
   TableBody,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import Sidebar from "../../common/NavbarContainer";
import { updateFeature } from "../../../store/slices/adminSlices/manageMasterFeatureSlice";
import { useDispatch } from "react-redux";

function EditFeature({ onSave }) {
   const dispatch = useDispatch();
   const location = useLocation();
   const feature = location.state?.feature; // Access passed data here

   const [featureName, setFeatureName] = useState(feature.featureName);
   const [featureLabels, setFeatureLabels] = useState(
      feature.featureLabels.map((label, index) => ({
         ...label,
         id: index,
         error: false,
      }))
   );

   // Update the feature name
   const handleFeatureNameChange = (e) => setFeatureName(e.target.value);

   // Update label value and validate if empty
   const handleLabelChange = (id, value) => {
      setFeatureLabels((prevLabels) =>
         prevLabels.map((label) =>
            label.id === id
               ? { ...label, label: value, error: value.trim() === "" }
               : label
         )
      );
   };

   // Add a new empty label
   const handleAddLabel = () => {
      setFeatureLabels((prevLabels) => [
         ...prevLabels,
         { label: "", id: Date.now(), error: true },
      ]);
   };

   // Remove a label by its id
   const handleRemoveLabel = (id) => {
      setFeatureLabels((prevLabels) =>
         prevLabels.filter((label) => label.id !== id)
      );
   };

   // Gather data for submission and validate
   const handleSubmit = () => {
      if (!featureName.trim()) {
         alert("Feature name cannot be empty");
         return;
      }

      const hasEmptyLabels = featureLabels.some(
         (label) => label.label.trim() === ""
      );
      if (hasEmptyLabels) {
         alert("All labels must have a name.");
         return;
      }

      const updatedFeature = {
         featureName,
         featureLabels: featureLabels.map(({ label }) => ({ label })),
      };
      console.log({updatedFeature})
      dispatch(updateFeature({featureData:updatedFeature, featureId:feature._id}));
   };

   return (
      <div style={{ display: "flex" }}>
         <Sidebar />
         <OuterBox>
            <Box mt={3.5} mb={2}>
               <Typography fontSize="20px" fontWeight="600" mb={2}>
                  Edit Feature
               </Typography>

               {/* Feature Name Input */}
               <Grid container spacing={2} mt={2}>
                  <Grid item xs={8} md={6}>
                     <TextField
                        label="Feature Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={featureName}
                        onChange={handleFeatureNameChange}
                     />
                  </Grid>
                  <Grid item xs={4} md={6} display="flex" alignItems="center">
                     <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddLabel}
                     >
                        Add Label
                     </Button>
                  </Grid>
               </Grid>

               {/* Display Labels */}
               {featureLabels.map((label) => (
                  <Grid container key={label.id} spacing={2} mt={1}>
                     <Grid item xs={8} md={6}>
                        <TextField
                           label="Label Name"
                           variant="outlined"
                           size="small"
                           fullWidth
                           value={label.label}
                           error={label.error}
                           helperText={
                              label.error ? "Label name cannot be empty" : ""
                           }
                           onChange={(e) =>
                              handleLabelChange(label.id, e.target.value)
                           }
                        />
                     </Grid>
                     <Grid
                        item
                        xs={4}
                        md={6}
                        display="flex"
                        alignItems="center"
                     >
                        <IconButton onClick={() => handleRemoveLabel(label.id)}>
                           <DeleteIcon />
                        </IconButton>
                     </Grid>
                  </Grid>
               ))}

               {/* Submit Button */}
               <Button
                  sx={{ marginTop: "20px" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
               >
                  Save Changes
               </Button>
            </Box>
         </OuterBox>
      </div>
   );
}

export default EditFeature;
