import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../../middleware/utils';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchAgentUsers = createAsyncThunk("users/getAllAgentUsers", async (query = '') => {
    try {
        const response = await apiClient.get(`${API_URL}/agent-user/get-agent-users`);
        return response.data;
    } catch (err) {
        console.log(err);
    }
});

export const createAgentUsers = createAsyncThunk("users/createAgentUsers", async (userData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/agent-user/create-agent-user`, userData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});

export const updateAgentUsers = createAsyncThunk("users/updateAgentUsers", async (userData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/agent-user/update-agent-user`, userData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});

export const deleteAgentUsers = createAsyncThunk("users/deleteAgentUsers", async (userData, { rejectWithValue }) => {
    try {
        await apiClient.post(`${API_URL}/agent-user/delete-agent-user`, userData);
        return userData.id;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Delete');
    }
});

export const userAgentChangePassword = createAsyncThunk("users/userChangePassword", async (userData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/agent-user/agent-change-password`, userData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});


const initialState = {
    agentUsers: [],
    error: null,
};

const manageUsersSlice = createSlice({
    name: "manage_users",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Agent Users
            .addCase(fetchAgentUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAgentUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.agentUsers = action.payload;
                state.error = null;
            })
            .addCase(fetchAgentUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Create Admin USers
            .addCase(createAgentUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(createAgentUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.agentUsers = [...state.agentUsers, action.payload];
                state.error = null;
            })
            .addCase(createAgentUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Update Admin USers
            .addCase(updateAgentUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateAgentUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.agentUsers = [...state.agentUsers, action.payload];
                state.error = null;
            })
            .addCase(updateAgentUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            //Delete Admin USers
            .addCase(deleteAgentUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteAgentUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.agentUsers = state.agentUsers.filter(agentUsers => agentUsers._id !== action.payload);
                state.error = null;
            })
            .addCase(deleteAgentUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            //Admin Change Password 
            .addCase(userAgentChangePassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(userAgentChangePassword.fulfilled, (state, action) => {
                state.loading = false;
                state.agentUsers = action.payload;
                state.error = null;
            })
            .addCase(userAgentChangePassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default manageUsersSlice.reducer;