import { useTheme } from "@emotion/react";
import { Box, Skeleton, styled } from "@mui/material";
import React from "react";

const CustomBox = styled(Box)(({ dynamicColor }) => ({
   height: "66px",
   marigin: "15px",
   // borderTop: "1px solid #80808047",
   display: "flex",
   cursor: "pointer",
   // borderLeft: `5px solid ${dynamicColor}`,
}));

function ChatContactsSkeleton() {
    const theme=useTheme()
   return (
      <Skeleton
         variant="rectangular"
         width="100%"
         height={66}
         sx={{
            bgcolor: theme.palette.background.skeleton,
            mb: 1,
            borderRadius: "4px",
         }}
      />
   );
}

export default ChatContactsSkeleton;
