import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../../middleware/utils';
const API_URL = process.env.REACT_APP_API_URL;

export const fetchTenant = createAsyncThunk("tenant/getAllTenant", async (query = '') => {
    try {
        const response = await apiClient.get(`${API_URL}/tenant?q=${query}`);
        return response.data;
    } catch (err) {
        console.log(err);
    }
});

export const updateTenant = createAsyncThunk("agent/updatetenant", async (tenantData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/tenant/update-tenant`, tenantData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});

export const fetchAllAgentClient = createAsyncThunk("agent/get-all-agent-client", async (agentData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/tenant/get-all-agent-client`, agentData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Fetch');
    }
});

export const createAgentClient = createAsyncThunk("agent/add-agent-client", async (agentData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/tenant/add-agent-client`, agentData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Create');
    }
});

export const updateAgentClient = createAsyncThunk("agent/update-agent-client", async (agentData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/tenant/update-agent-client`, agentData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});

export const deleteAgentClient = createAsyncThunk("agent/delete-agent-client", async (agentData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/tenant/delete-agent-client`, agentData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Delete');
    }
});

const initialState = {
    tenant: [],
    agentClient: [],
    error: null,
};

const manageTenantSlice = createSlice({
    name: "manage_tenant",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Tenant
            .addCase(fetchTenant.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTenant.fulfilled, (state, action) => {
                state.loading = false;
                state.tenant = action.payload;
                state.error = null;
            })
            .addCase(fetchTenant.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Update Tenant
            .addCase(updateTenant.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateTenant.fulfilled, (state, action) => {
                state.loading = false;
                state.tenant = [...state.tenant, action.payload];
                state.error = null;
            })
            .addCase(updateTenant.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Fetch Agent Client
            .addCase(fetchAllAgentClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllAgentClient.fulfilled, (state, action) => {
                state.loading = false;
                state.agentClient = action.payload;
                state.error = null;
            })
            .addCase(fetchAllAgentClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            // Create Agent Client
            .addCase(createAgentClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(createAgentClient.fulfilled, (state, action) => {
                state.loading = false;
                state.agentClient = [...state.agentClient, action.payload];
                state.error = null;
            })
            .addCase(createAgentClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Update Agent Client
            .addCase(updateAgentClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateAgentClient.fulfilled, (state, action) => {
                state.loading = false;
                state.agentClient = [...state.agentClient, action.payload];
                state.error = null;
            })
            .addCase(updateAgentClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Delete Agent Client
            .addCase(deleteAgentClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteAgentClient.fulfilled, (state, action) => {
                state.loading = false;
                state.agentClient = state.agentClient.filter(agent => agent._id !== action.payload);
                state.error = null;
            })
            .addCase(deleteAgentClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default manageTenantSlice.reducer;