import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../../middleware/utils';
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAgentRoles = createAsyncThunk("role/getAllAgentRoles", async (query = '') => {
    try {
        const response = await apiClient.get(`${API_URL}/role/get-agent-roles`);
        return response.data;
    } catch (err) {
        console.log(err);
    }
});

export const createAgentRole = createAsyncThunk("role/create-agent-role", async (roleData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/role/create-agent-role`, roleData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});

export const updateAgentRole = createAsyncThunk("role/update-agent-role", async (roleData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${API_URL}/role/update-agent-role`, roleData);
        return response.data;
    } catch (err) {
        console.log(err);
        return rejectWithValue(err.response.data.message || 'Failed to Update');
    }
});

const initialState = {
    agentRole: [],
    error: null,
};

const manageRoleSlice = createSlice({
    name: "manage_roles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Admin Roles
            .addCase(fetchAgentRoles.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAgentRoles.fulfilled, (state, action) => {
                state.loading = false;
                state.agentRole = action.payload;
                state.error = null;
            })
            .addCase(fetchAgentRoles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Create Agent
            .addCase(createAgentRole.pending, (state) => {
                state.loading = true;
            })
            .addCase(createAgentRole.fulfilled, (state, action) => {
                state.loading = false;
                state.agentRole = [...state.agentRole, action.payload];
                state.error = null;
            })
            .addCase(createAgentRole.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Update Agent
            .addCase(updateAgentRole.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateAgentRole.fulfilled, (state, action) => {
                state.loading = false;
                state.agentRole = [...state.agentRole, action.payload];
                state.error = null;
            })
            .addCase(updateAgentRole.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default manageRoleSlice.reducer;