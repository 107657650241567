import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";


import toast, { Toaster } from "react-hot-toast";
import { getAllChats, removeNewAddedChat } from "../../../store/slices/clientSlice/chatSlice";
import apiClient from "../../../middleware/utils";
import { validateTemplateFile } from "../../../utils/helperFuncion";


const savebtn = (theme) => ({
  backgroundColor: theme.palette.primary.iconmain,
  textTransform: "capitalize",
  border: "0.5px solid #0293d2",
  fontWeight: "500",
  borderRadius: "5px",
  color: theme.palette.text.whitetext,
  paddingLeft: "20px",
  paddingRight: "20px",
  boxShadow: "none !important",
  "&:hover": {
    backgroundColor: "background.iconhover",
    color: "#fff",
    border: `0.5px solid ${theme.palette.background.iconhover}`,
  },
  "@media (max-width: 600px)": {
    marginRight: 0,
    fontSize: "0.925rem",
  },
});

function TemplateViewModal({
  openTemplate,
  handleClose,
  selectedTemplate,
  setOpenTemplateList,
  setMessageList,
  selectedChat,
}) {
  const theme = useTheme();

  const components = selectedTemplate?.components || [];

  const { chats } = useSelector((state) => state.chat);

  const fileRef = useRef(null);
  const [file, setFile] = useState("");
  const [bodyContent, setBodyContent] = useState("");
  // State for handling if the header opion is link
  const [linkOption, setLinkOpen] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [variable, setVariable] = useState([]);
  const [button, setButton] = useState([]);
  const [headerType, setHeaderType] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [urlButton, setUrlButton] = useState("");

  const dispatch = useDispatch();

  const handleCloseTempmodal = () => {
    setVariable([]);
    setButton([]);
    handleClose();
    setHeaderType("");
    setPreviewImage("");
  };

  const handleButtonChange = (e, text) => {
    const value = e.target.value;

    const newButtons = button.map((btn) => {
      if (btn.text === text) {
        btn.value = value;
      }
      return btn;
    });
    setButton(newButtons);
  };

  useEffect(() => {
    if (!selectedTemplate) return;

    const components = selectedTemplate.components || [];
    const headerContent = components.find((item) => item.type === "HEADER");

    if (headerContent) {
      setHeaderType(headerContent.format);
    }

    const bodyComponent = components.find((item) => item.type === "BODY");

    if (bodyComponent) {
      const bodyText = bodyComponent.text || "";
      const regex = /\{\{\d+\}\}/g;
    
      // Use match() to find all occurrences of the pattern in the text
      const matches = bodyText.match(regex) || [];
    
      // Remove duplicates by converting to a Set and back to an array
      const uniqueMatches = [...new Set(matches)];
    
      if (uniqueMatches.length > 0) setBodyContent(true);
    
      // Map uniqueMatches to variable objects
      const allVariables = uniqueMatches.map((item) => ({
        id: item,
        type: "text",
        text: "",
      }));
    
      // Update state with the extracted variables
      setVariable(allVariables);
    }

    // Check for button component is available
    const buttonContent = components.find((item) => item.type === "BUTTONS");

    if (buttonContent) {
      let newButtons = [];
      buttonContent?.buttons?.map((btn, index) => {
        newButtons.push({ ...btn, index });
      });

      setButton(newButtons);
    }
  }, [components, selectedTemplate]);

  // Function to send template message
  //   const handleSendMessage = async () => {
  //     let formData = new FormData();

  //     if (file) {
  //       formData.append("image", file);
  //     }
  //     if (mediaUrl) {
  //       formData.append("url", mediaUrl);
  //     }

  //     if (bodyContent) {
  //       const bodyArray = [];

  //       // Add each object from 'variable' array to 'bodyArray'
  //       variable.forEach((obj) => {
  //         bodyArray.push(obj);
  //       });

  //       // Append 'bodyArray' as a single entry named 'body'
  //       formData.append("body", JSON.stringify(bodyArray));
  //     }

  //     if (footerContent) {
  //       const footer = {
  //         type: "text",
  //         text: "a sample body text",
  //       };
  //       formData.append("footer", footer);
  //     }
  //     //Disabling button

  //     // if (button.length > 0) {
  //     //   formData.append("button", JSON.stringify(button));
  //     // }
  //     // const to = 919446767626;
  //     const to = selectedChat.phone_number;
  //     const template = JSON.stringify({
  //       // templateName: selectedTemplate?.name,/
  //       templateName: selectedTemplate?.name,
  //       languageCode: selectedTemplate?.language,
  //     });
  //     if (headerType) {
  //       const headerObject = JSON.stringify({
  //         type: headerType,
  //         text: headerText,
  //       });
  //       formData.append("header", headerObject);
  //     }

  //     // formData.append("template", true);
  //     formData.append("template", template);
  //     formData.append("to", to);
  //     formData.append("type", "template");
  //     formData.append("chatId", selectedChat._id);

  //     try {
  //       // Sending message
  //       const response = await axios.post(`${process.env.REACT_APP_API}/messages/send`, formData);
  //       if (response && response?.data?.status === "success") {
  //         // Get the chat id from response
  //         const responseChatId = response.data.result?.chat_id;
  //         const newMessage = response.data.result;
  //         const updatedChat = response.data.newChatData;

  //         await dispatch(saveSelectedChat(updatedChat));
  //         await dispatch(updateSentMessage(newMessage));

  //         // scrollToBottom();
  //         let newSelectedChat = {};

  //         if (selectedChat._id === "new") {
  //           dispatch(setNewChatEmpty());
  //           await dispatch(getAllChats());

  //           setTimeout(() => {
  //             chats?.forEach((chat) => {
  //               if (chat.chat?._id === responseChatId) {
  //                 newSelectedChat = chat.chat;
  //               }
  //             });
  //           }, 3000);

  //           dispatch(saveSelectedChat(newSelectedChat));
  //           dispatch(getAllMessagesByChatId({ chatId: responseChatId }));

  //           handleClose();
  //           setOpenTemplateList(false);
  //           return;
  //         }
  //         setMessageList((prev) => [...prev, response?.data?.result]);

  //         // dispatch(getAllMessagesByChatId({ chatId: selectedChat._id }));
  //         dispatch(removeNewAddedChat());
  //         handleClose();
  //         setFile(null);
  //         setButton([]);
  //         setOpenTemplateList(false);
  //         setHeaderType({});
  //       }
  //       const bodyComponent = components.find((item) => item.type === "BODY");

  //       if (bodyComponent) {
  //          const bodyText = bodyComponent.text || "";
  //          const regex = /\{\{\d+\}\}/g;

  //          // Use match() to find all occurrences of the pattern in the text
  //          const matches = bodyText.match(regex) || [];
  //          if (matches.length > 0) setBodyContent(true);

  //          // Map matches to variable objects
  //          const allVariables = matches.map((item) => ({
  //             id: item,
  //             type: "text",
  //             text: "",
  //          }));

  //          // Update state with the extracted variables
  //          setVariable(allVariables);
  //       }

  //       // Check for button component is available
  //       const buttonContent = components.find((item) => item.type === "BUTTONS");

  //       if (buttonContent) {
  //          let buttons = [];
  //          buttonContent?.buttons?.map((btn, index) => {
  //             buttons.push(btn);
  //          });
  //          // let btnWithoutPhn = [];

  //          // buttonContent?.buttons?.map((btn, index) => {
  //          //   if (btn.type !== "PHONE_NUMBER") {
  //          //     btnWithoutPhn.push({ ...btn, index });
  //          //   }
  //          // });

  //          setButton(buttons);
  //       }
  //     }
  //    }, [components, selectedTemplate]);

  // Function to send template message
  const handleSendMessage = async () => {
    let formData = new FormData();

    if (file) {
      formData.append("file", file);
      const head = {
        type: headerType,
      };
      formData.append("header", JSON.stringify(head));
    }
    if (mediaUrl) {
      formData.append("url", mediaUrl);
    }

    if (bodyContent) {
      const bodyArray = [];

      // Add each object from 'variable' array to 'bodyArray'
      variable.forEach((obj) => {
        bodyArray.push(obj);
      });

      // Append 'bodyArray' as a single entry named 'body'
      formData.append("body", JSON.stringify(bodyArray));
    }

    // if (footerContent) {
    //    const footer = {
    //       type: "text",
    //       text: "a sample body text",
    //    };
    //    formData.append("footer", footer);
    // }
    //Disabling button

    if (urlButton) {
      const dynamicUrlBtn = button?.find((btn) => btn.type === "URL");
      let buttonComponent = {
        type: "button",
        sub_type: "url",
        index: dynamicUrlBtn?.index,
        parameters: [
          {
            type: "payload",
            payload: urlButton,
          },
        ],
      };
      formData.append("button", JSON.stringify(buttonComponent));
    }
    // const to = 919446767626;
    const to = selectedChat.phone_number;
    const template = JSON.stringify({
      // templateName: selectedTemplate?.name,/
      templateName: selectedTemplate?.name,
      languageCode: selectedTemplate?.language,
    });
    // if (headerType&&headerType) {
    //    const headerObject = JSON.stringify({
    //       type: headerType,
    //       text: headerText,
    //    });
    //    formData.append("header", headerObject);
    // }

    // formData.append("template", true);
    formData.append("template", template);
    formData.append("to", to);
    formData.append("type", "template");
    formData.append("chatId", selectedChat._id);

    try {
      // Sending message
      const response = await apiClient.post(`/messages/send`, formData);
      if (response && response?.data?.status === "success") {
        // Get the chat id from response
        const responseChatId = response.data.result?.chat_id;
        const newMessage = response.data.result;
        // await dispatch(updateSentMessage(newMessage));

        // scrollToBottom();
        let newSelectedChat = {};

        // if (selectedChat._id === "new") {
        //   dispatch(setNewChatEmpty());
        //   await dispatch(getAllChats());

        //   setTimeout(() => {
        //     chats?.forEach((chat) => {
        //       if (chat.chat?._id === responseChatId) {
        //         newSelectedChat = chat.chat;
        //       }
        //     });
        //   }, 3000);

        //   dispatch(saveSelectedChat(newSelectedChat));
        //   // dispatch(getAllMessagesByChatId({ chatId: responseChatId }));
        //   // This api for set the chat to top
        //   // await dispatch(getAllChats());

          handleClose();
          setOpenTemplateList(false);
          return;
        }
        dispatch(getAllChats({page:1}));
        // setMessageList((prev) => [...prev, response?.data?.result]);

        // dispatch(getAllMessagesByChatId({ chatId: selectedChat._id }));
        dispatch(removeNewAddedChat());
        handleClose();
        setFile(null);
        setButton([]);
        setOpenTemplateList(false);
        setHeaderType({});
      
    } catch (err) {
      console.log(err);
    }
  };

  const fileUploadRef = useRef(null);
  const handleUploadFile = () => {
    fileUploadRef.current.click();
  };

  const handleChange = (e, itemId) => {
    const newText = e.target.value;

    const result = variable.map((vari) => {
      if (vari.id === itemId) {
        vari.text = newText;
      }
      return vari;
    });
    setVariable(result);
  };

  const handleFileinputChange = (e) => {
    let file = e.target.files[0];
    const isvalidate = validateTemplateFile(file, headerType);
    if (isvalidate?.error) {
      toast.error(isvalidate?.error);
      return;
    }
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
    }
  };
  return (
    <Dialog
      onClose={handleCloseTempmodal}
      open={openTemplate}
      sx={{
        zIndex: "99999",
        "& .MuiPaper-root": {
          backgroundColor: "background.modal",
          backgroundImage: "none",
        },
      }}
    >
      <Toaster />
      <DialogContent
        sx={{
          padding: "33px 33px 9px 34px",
          backgroundColor: theme.palette.background.model,
        }}
      >
        <Box
          sx={{
            width: "500px",
            backgroundColor: theme.palette.background.model,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography
              sx={{
                fontWeight: "600",
                color: "text.primary",
                fontSize: "16px",
              }}
            >
              Send a template message
            </Typography>
            <IconButton
              onClick={() => handleCloseTempmodal()}
              sx={{ color: "grey", padding: "6px" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography fontSize="14px">{selectedTemplate?.name}</Typography>
          {/**Header */}
          {headerType && headerType !== "TEXT" && (
            <Box
              bgcolor={theme.palette.background.backgroundgrey}
              p={1}
              height="150px"
              borderRadius="5px"
            >
              <Typography fontSize="12px">Header: {headerType}</Typography>
              {!linkOption ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {previewImage ? (
                      <Box sx={{ width: "100px", display: "flex" }}>
                        <img
                          src={previewImage}
                          alt="uploaded"
                          style={{ width: "100%" }}
                        />
                        <HighlightOffRoundedIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setPreviewImage(null)}
                        />
                      </Box>
                    ) : (
                      <>
                        <CloudUploadIcon
                          sx={{
                            fontSize: "50px",
                            cursor: "pointer",
                          }}
                          onClick={handleUploadFile}
                        />
                        <Typography>Upload a file</Typography>
                      </>
                    )}
                    <input
                      ref={fileUploadRef}
                      type="file"
                      accept={headerType === "IMAGE" ? "image/*" : ""}
                      value=""
                      style={{ display: "none" }}
                      onChange={handleFileinputChange}
                    />
                  </Box>
                  {/* {!previewImage && (
                              <Box
                                 width="100%"
                                 display="flex"
                                 flexDirection="column"
                                 alignItems="center"
                              >
                                 <InsertLinkIcon
                                    onClick={() => setLinkOpen(true)}
                                    sx={{ fontSize: "50px", cursor: "pointer" }}
                                 />
                                 <Typography>Enter a link to file</Typography>
                              </Box>
                           )} */}
                </Box>
              ) : (
                <TextField
                  sx={{ marginTop: "20px" }}
                  id=""
                  label="URL"
                  value={mediaUrl}
                  fullWidth
                  onChange={(e) => setMediaUrl(e.target.value)}
                />
              )}
            </Box>
          )}
          {headerType && headerType === "TEXT" && (
            <Box
              mt={1}
              bgcolor={theme.palette.background.backgroundgrey}
              p={1}
              // height="150px"
              borderRadius="5px"
            >
              <Typography fontSize="12px">Header</Typography>
              <Typography>{headerText}</Typography>
            </Box>
          )}

          {/**Body */}
          {components?.find((item) => item.type === "BODY") && (
            <Box
              mt={1}
              bgcolor={theme.palette.background.backgroundgrey}
              p={1}
              // height="150px"
              borderRadius="5px"
            >
              <Typography fontSize="12px">Body</Typography>
              <Typography>
                {components?.find((item) => item.type === "BODY")?.text}
              </Typography>
              {variable?.map((item) => (
                <FormControl
                  fullWidth
                  sx={{ m: 1 }}
                  variant="standard"
                  key={item?.id}
                >
                  <Input
                    id="standard-adornment-amount"
                    onChange={(e) => handleChange(e, item?.id)}
                    startAdornment={
                      <InputAdornment position="start">
                        {item?.id}
                      </InputAdornment>
                    }
                  />
                </FormControl>
              ))}
            </Box>
          )}

          {/**Footer */}
          {components?.find((item) => item.type === "FOOTER") && (
            <Box
              mt={1}
              bgcolor={theme.palette.background.backgroundgrey}
              p={1}
              borderRadius="5px"
            >
              <Typography fontSize="12px" color="initial">
                footer
              </Typography>
              <Typography fontSize="15px">
                {components?.find((item) => item.type === "FOOTER")?.text}
              </Typography>
            </Box>
          )}
        </Box>
        {/**Buttons */}
        {components?.find((item) => item.type === "BUTTONS") && (
          <Box
            mt={1}
            bgcolor={theme.palette.background.backgroundgrey}
            p={1}
            // height="150px"
            borderRadius="5px"
          >
            <Typography fontSize="12px">Button </Typography>
            <Box display="flex" flexDirection="column">
              {button?.map((btn) => (
                <>
                  <Typography fontSize="12px" fontWeight={300}>
                    Type: {btn?.type}
                  </Typography>
                  <Box mt={0.5}>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "text.primary",
                        textTransform: "capitalize",
                        marginTop: "5px",
                        fontSize: "12px",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        backgroundColor: theme.palette.background.bgbutton,
                        border: "0.5px solid #0293d2",
                        borderRadius: "5px",
                        "&:hover": {
                          color: "#ffffff",
                        },
                      }}
                    >
                      {btn?.text}
                    </Button>
                    <Typography fontSize="12px" mt={0.7} mb={0.9}>
                      {btn?.url || btn?.phone_number}
                    </Typography>
                    {btn.example && (
                      <>
                        <Box mb={0.7}>
                          <TextField
                            fullWidth
                            label="{{1}}"
                            value={urlButton}
                            onChange={(e) => setUrlButton(e.target.value)}
                            size="small"
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              ))}
              {/* {button?.map((btn) => (
                <TextField
                  sx={{ mt: 1 }}
                  key={btn?.text}
                  id=""
                  label={btn?.type}
                  value={btn.value}
                  onChange={(e) => handleButtonChange(e, btn.text)}
                />
              ))} */}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "8px 29px 15px 26px" }}>
        <Button onClick={handleSendMessage} variant="contained" sx={savebtn}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplateViewModal;
