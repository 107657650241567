import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./slices/authSlice";
import manageAgentReducer from "./slices/adminSlices/manageAgentSlice";
import manageTenantReducer from "./slices/adminSlices/manageTenantSlice";
import manageRoleAdminReducer from "./slices/adminSlices/manageAdminRoleSlice";
import manageRoleAgentReducer from "./slices/adminSlices/manageAgentRoleSlice";
import manageAdminUserReducer from "./slices/adminSlices/manageAdminUserSlice";
import manageAgentUserReducer from "./slices/agentSlice/manageAgentUserSlice";
import manageAgentSubscription from "./slices/adminSlices/manageSubscriptionPlans";
import manageSubscriptionPlans from "./slices/adminSlices/manageSubscriptionPlans";
import manageClientSubscriptionPlans from "./slices/adminSlices/manageSubscriptionClinetPlans";
import manageMasterFeatureReducer from "./slices/adminSlices/manageMasterFeatureSlice";
import transactionReducer from "./slices/commenSlice.js/transactionSlice";
import ticketsReducer from "./slices/agentSlice/manageTicketSlice";

//Client reducer
import messgeReducer from "./slices/clientSlice/messageSlice"
import userReducer from "./slices/clientSlice/userSlice"
import sideBarReducer from "./slices/clientSlice/sideBarSlice"
import chatReducer from "./slices/clientSlice/chatSlice"
import notificationReducer from "./slices/clientSlice/notificationSlice"
import bulkSendReportReducer from "./slices/clientSlice/bulkSendReportSlice"
import contactReducer from "./slices/clientSlice/bulkSendReportSlice"
import templateReducer from "./slices/clientSlice/bulkSendReportSlice"
import insightsReducer from "./slices/clientSlice/bulkSendReportSlice"
import labelReducer from "./slices/clientSlice/bulkSendReportSlice"
import groupReducer from "./slices/clientSlice/bulkSendReportSlice"
import scheduledReducer from "./slices/clientSlice/scheduledMessageSlice"

const appStore = configureStore({
   reducer: {
      auth: authReducer,
      agent: manageAgentReducer,
      tenant: manageTenantReducer,
      role: manageRoleAdminReducer,
      agentRole: manageRoleAgentReducer,
      users: manageAdminUserReducer,
      agentUsers: manageAgentUserReducer,
      agentClientSubscription: manageAgentSubscription,
      subscriptionPlan: manageSubscriptionPlans,
      clientSubscriptionPlan: manageClientSubscriptionPlans,
      feature:manageMasterFeatureReducer,
      transaction:transactionReducer,
      ticket:ticketsReducer,
      //client
      // auth: authReducer,
      user: userReducer,
      sidebar: sideBarReducer,
      chat: chatReducer,
      messages: messgeReducer,
      notification: notificationReducer,
      bulkSend: bulkSendReportReducer,
      contacts: contactReducer,
      template: templateReducer,
      insights: insightsReducer,
      labels: labelReducer,
      group: groupReducer,
      scheduledMessage: scheduledReducer,

   },
});

export default appStore;


