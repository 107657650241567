import {
   Box,
   CircularProgress,
   Tooltip,
   Typography,
   styled,
} from "@mui/material";
import React, { forwardRef } from "react";
import ListTemplate from "../ListTemplate";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CustomSpeedDial from "../../common/CustomSpeedDial";
import EmptyImage from "../../../../assets/images/nopreview.jpeg";

// import { AudioRecorder } from "react-audio-voice-recorder";

const TypeBox = styled(Box)(({ theme }) => ({
   height: "150px",
   // width: "100%",
   backgroundColor: theme.palette.background.chatbg,
   // border: "1px solid grey",
   display: "flex",
   alignItems: "center",
}));

const ShowSelectedMessage = styled(Box)(({ theme }) => ({
   backgroundColor: theme.palette.background.replybox,
   height: "200px",
   width: "100%",
   display: "flex",
}));

const ReplyMsgContent = styled(Box)({
   width: "100%",
   backgroundColor: "#8080801a",
   margin: "5px 10px",
   borderRadius: "10px",
   display: "flex",
   justifyContent: "space-between",
   padding: "10px",
});

const ThemedInput = styled("input")(({ theme }) => ({
   width: "-webkit-fill-available",
   height: "50px",
   outline: "none",
   border: "none",
   paddingLeft: "25px",
   backgroundColor: theme.palette.background.chatinput,
   borderRadius: "10px",
   fontSize: "14px",
   color: theme.palette.text.primary,
   boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
   // boxShadow: "0px 4px 10.2px rgba(119, 119, 119, 0.1)",
}));

const ChatInput = forwardRef(({
   openTemplate,
   setOpenTemplate,
   selectedChat,
   setMessageList,
   isChatExpired,
   handleClickFileUpload,
   fileuploadRef,
   handleFileChange,
   textMessage,
   handleChange,
   started,
   setStarted,
   handleSendMessage,
   sendMessageLoading,
   handleKeyDown,
   selectedMsgToReply,
   setSelectedMessageToReply,
}, inputRef) => {
   const url = process.env.REACT_APP_IMAGE_URL;
   const { message } = selectedMsgToReply;
   const content =
      message?.text ||
      message?.image?.caption ||
      message?.video?.caption ||
      message?.document?.caption;

   const handleOpenTemplate = () => {
      setOpenTemplate(!openTemplate);
   };

   return (
      <>
         {selectedChat?.isBlocked ? (
            <TypeBox>
               <Box
                  p={1}
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     backgroundColor: "background.default",
                     height: "25px",
                     marginTop: "10px",
                     width: "95%",
                  }}
               >
                  <Typography fontSize="14px" color="grey">
                     The contact has been blocked. Please unblock to send a
                     message.
                  </Typography>
               </Box>
            </TypeBox>
         ) : (
            <div style={{ display: "contents" }}>
               {Object.keys(selectedMsgToReply).length > 0 && (
                  <ShowSelectedMessage>
                     <ReplyMsgContent>
                        <Box height="100%" maxHeight="100px" overflow="hidden">
                           <Typography
                              fontSize="13px"
                              fontWeight={600}
                              color="text.replytext"
                           >
                              {selectedMsgToReply?.display_phone_number}
                           </Typography>
                           {/* {selectedMsgToReply?.message_type === "text" && ( */}
                           <Typography
                              fontSize="13px"
                              color="grey"
                              sx={{
                                 textOverflow: "ellipsis",
                                 // maxWidth: "200px",
                                 maxHeight: "10px",
                              }}
                           >
                              {content}
                           </Typography>
                           {/* )} */}
                        </Box>
                        {selectedMsgToReply?.message_type === "image" && (
                           <Box width={60} height={60}>
                              <img
                                 src={
                                    EmptyImage ||
                                    url +
                                       "/" +
                                       selectedMsgToReply?.message?.image?.path
                                 }
                                 width="100%"
                                 alt="selected"
                              />
                           </Box>
                        )}
                     </ReplyMsgContent>
                     <Box
                        width={60}
                        sx={{
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <CloseIcon
                           sx={(theme) => ({
                              color: "grey",
                              cursor: "pointer",
                              "&:hover": {
                                 color: `${theme.palette.primary.iconmain} !important`,
                              },
                           })}
                           onClick={() => setSelectedMessageToReply({})}
                        />
                     </Box>
                  </ShowSelectedMessage>
               )}

               {openTemplate && (
                  <ListTemplate
                     openTemplateList={openTemplate}
                     setOpenTemplateList={setOpenTemplate}
                     setMessageList={setMessageList}
                     selectedChat={selectedChat}
                  />
               )}

               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     margin: "0px",
                     bgcolor: "background.chatbg",
                     width: "100%",
                  }}
               >
                  <Box
                     sx={{
                        width: "110px",
                        height: 100,
                        display: "flex",
                        justifyContent: "center",
                     }}
                  >
                     {/* {!isChatExpired && ( */}
                     <CustomSpeedDial
                        handleOpenTemplate={handleOpenTemplate}
                        fileuploadRef={fileuploadRef}
                        handleFileChange={handleFileChange}
                        handleClickFileUpload={handleClickFileUpload}
                        isChatExpired={isChatExpired}
                     />
                     {/* )} */}
                     {/* <Box height={100} sx={{position:"relative"}}>
                           <AddCircleOutlineIcon
                              sx={{ color: "#999", cursor: "pointer" }}
                              onClick={handleClickFileUpload}
                           />
                           <CustomSpeedDial />
                           <input
                              style={{ display: "none" }}
                              type="file"
                              // accept="image/,video/"
                              ref={fileuploadRef}
                              onChange={handleFileChange}
                           />
                        </Box> */}
                     {/* )} */}
                     {/* <Tooltip title="Templates">
                        <FontAwesomeIcon
                           onClick={() => setOpenTemplate(!openTemplate)}
                           style={{
                              color: "#999",
                              cursor: "pointer",
                              "&:hover": { color: "black" },
                           }}
                           icon="fa-solid fa-layer-group"
                        />
                     </Tooltip> */}
                     {/* {selectedChat?._id !== "new" && <FontAwesomeIcon style={{ color: "#999" }} icon="fa-solid fa-share-from-square" />} */}
                  </Box>

                  {!started && (
                     <Box ml={1.5} width="100%">
                        <ThemedInput
                           ref={inputRef}
                           placeholder={
                              selectedChat?._id === "new" || isChatExpired
                                 ? "You can only send templates to this number"
                                 : "Write message"
                           }
                           disabled={
                              selectedChat?._id === "new" || isChatExpired
                           }
                           value={textMessage}
                           onChange={handleChange}
                           onKeyDown={handleKeyDown}
                        />
                        {/* <input
                           placeholder={
                              selectedChat?._id === "new" || isChatExpired
                                 ? "You can only send templates to this number"
                                 : "Write message"
                           }
                           disabled={
                              selectedChat?._id === "new" || isChatExpired
                           }
                           value={textMessage}
                           onChange={handleChange}
                           onKeyDown={handleKeyDown}
                           style={{
                              width: "-webkit-fill-available",
                              height: "50px",
                              outline: "none",
                              border: "none",
                              paddingLeft: "25px",
                              backgroundColor: "background.chatinput",
                              borderRadius: "10px",
                              fontSize: "14px",
                              boxShadow:
                                 "0px 4px 10.2px rgba(119, 119, 119, 0.1)",
                           }}
                        /> */}
                     </Box>
                  )}
                  <Box width="110px" display="flex" justifyContent="center">
                     <Box
                        sx={{
                           backgroundColor: "button.sendbtn",
                           width: "60px",
                           height: "50px",
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                           cursor: "pointer",
                           borderRadius: "10px",
                           // boxShadow: "0px 4px 10.2px rgba(119, 119, 119, 0.1)",
                           ":hover": {
                              // width: "62px",
                              // height: "52px",
                              backgroundColor: "background.iconhover",
                           },
                        }}
                     >
                        {sendMessageLoading ? (
                           <CircularProgress
                              sx={{
                                 width: "20px",
                                 height: "20px",
                                 color: "#000000",
                              }}
                           />
                        ) : (
                           <SendOutlinedIcon
                              sx={{ color: "#fff" }}
                              onClick={() => handleSendMessage()}
                           />
                        )}
                        {/* <SendIcon onClick={() => handleSendMessage()} /> */}
                        {/* <SendOutlinedIcon
                           sx={{ color: "#fff",  }}
                           onClick={() => handleSendMessage()}
                        /> */}
                     </Box>
                  </Box>
                  {/* {!textMessage && !isChatExpired ? (
                     <Box
                        sx={{
                           backgroundColor: "#80808024",
                           height: "44px",
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                           cursor: "pointer",
                        }}
                     >
                     </Box>
                  ) : (
                     <Box
                        sx={{
                           backgroundColor: "#80808024",
                           width: "50px",
                           height: "44px",
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                           cursor: "pointer",
                        }}
                     >
                        {textMessage && sendMessageLoading ? (
                           <CircularProgress
                              sx={{
                                 width: "20px",
                                 height: "20px",
                                 color: "#000000",
                              }}
                           />
                        ) : (
                           textMessage && (
                              <SendIcon onClick={() => handleSendMessage()} />
                           )
                        )}
                     </Box>
                  )} */}
               </Box>
            </div>
         )}
      </>
   );
});

export default ChatInput;
