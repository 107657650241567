import React from 'react'
import { Avatar, Box, Typography, styled } from "@mui/material";
import moment from "moment";
import { sendMessageStatus } from '../../../../utils/helperFuncion';

const Message = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginBottom: "5px",
  paddingLeft:20
});

function ButtonMessage({ content }) {
    const time = moment(content.createdAt).format("h:mm a");
    const statusIcon = sendMessageStatus(content?.status);
  return (
    <Box
    key={content._id}
    sx={{
      height: "auto",
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      maxWidth: content.flag === "incoming" ? "450px" : null,
      justifyContent: content.flag === "outgoing" ? "end" : "start",
    }}
  >
    
    <Message>
      <Box
        p={1}
        sx={{
          width: "auto",
          height: "auto",
          backgroundColor: content.flag === "incoming" ? "background.incomingmsg" : "background.outgoingmsg",
          borderRadius: content.flag === "incoming" ? "0px 20px 20px 20px" : "20px 0px 20px 20px",
        }}
      >
        
        <Typography sx={{ fontSize: "13px",color:"text.primary" }}>{content?.message?.button?.text}</Typography>
        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "11px",
              fontWeight: "400",
              color: "grey",
              textAlign: content.flag === "incoming" ? "start" : "end",
            }}
          >
            {time}
          </Typography>
          {content.flag === "outgoing" && statusIcon}
        </Box>
      </Box>
    </Message>
    
  </Box>
  )
}

export default ButtonMessage