import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  // Popover,
  Typography,
  IconButton,
} from "@mui/material";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalBox: {
    width: "415px",
    height: "200px",
    backgroundColor: (theme) => theme.palette.background.modal,
    borderRadius: "8px",
    padding: "16px",
    position: "relative",
    textAlign: "center",
    "@media (max-width: 600px)": {
      width: "314px",
    },
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    color: (theme) => theme.palette.grey[500],
  },
};

function NewChat({
  open,
  onClose,
  setOpen,
  addNewChat,
  newContact,
  setNewContact,
}) {
  // const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneChange = (num) => setNewContact(num);
  const handleClose = () => setOpen(false);
  const theme = useTheme();

  const inputStyle = {
    width: "270px",
    height: "27px",
    border: "none",
    borderRadius: "5px",
  };

  return (
    <Box >
      <Box sx={styles.modalBox}>
        <IconButton sx={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Box height="44%" textAlign="center" sx={{ marginTop: "25px" }}>
          <Typography sx={{ color: "text.primary", fontSize: "25px" }}>
            Add New Chat
          </Typography>
          <Typography
            variant="body2"
            sx={(theme) => ({
              opacity: 0.8,
              fontWeight: 400,
              fontSize: "14px",
              color: theme.palette.text.darkgrey,
              "& span": {
                fontWeight: 600,
              },
            })}
          >
            Enter number and click on the<span> add</span> to start new chat
          </Typography>
        </Box>
        {/* <Box height="50%"> */}
        <Box
          height={60}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <PhoneInput
            defaultCountry="IN"
            value={newContact}
            onChange={setNewContact}
            placeholder="Phone number"
            className="phone-inputchat"
            style={inputStyle}
          />

          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "capitalize",borderRadius:"5px"
            }}
            onClick={() => addNewChat()}
          >
            Add
          </Button>
        </Box>
        {/* </Box> */}
      </Box>
    </Box>
  );
}

export default NewChat;
