import {
  Box,
  Typography,
  Button,
  Avatar,
  TextField,
  InputAdornment,
  styled,
} from "@mui/material";
import React, { useRef } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTheme } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
  focused: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "green", // Customize the focus color here
    },
  },
  // backgroundColor: "#fff",
  "& .MuiInputBase-root": {
    // backgroundColor: "#fff",
    ":hover": { backgroundColor: "button.texthover" },
  },
});

function BusinessProfile({ profileData, active, setOpenProfile }) {
  const theme = useTheme();

  // Function to focus the text field
  const profile = {
    position: "absolute",
    borderRadius: "15px",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "background.body",
    transition: "transform 0.5s ease-out",
    transform: "translateX(-100%)",
  };
  const activeProfile = {
    transform: "translateX(0%)" /* slide it into view */,
  };
  return (
    <Box sx={{ ...(active ? { ...profile, ...activeProfile } : profile) }}>
      <Box p={2} display="flex" justifyContent="space-between">
        <Typography sx={{ color: theme.palette.text.primary }}>
          Business Profile
        </Typography>

        <CancelIcon onClick={() => setOpenProfile(false)} sx={{ color: 'text.primary',cursor:"pointer" }}/>
      </Box>
      <Box pl={2} pr={2} pb={2} display="flex" justifyContent="center">
        <Avatar
          sx={{ height: "115px", width: "115px" }}
          src={profileData?.profile_picture_url}
        />
      </Box>
      <Box pl={2} pr={2}>
        <CustomTextField
          label="About"
          id="filled-start-adornment"
          value={profileData?.about}
          focused
          fullWidth
          sx={{
            // backgroundColor: "#fff",
            "& .MuiInputBase-root": {
              // backgroundColor: "#fff",
              ":hover": { backgroundColor: "button.texthover" },
            },
          }}
          variant="filled"
        />
        <CustomTextField
          label="Address"
          id="filled-start-adornment"
          fullWidth
          sx={{
            // backgroundColor: "#fff",
            "& .MuiInputBase-root": {
              // backgroundColor: "#fff",
              ":hover": { backgroundColor: "button.texthover" },
            },
          }}
          variant="filled"
        />
        <CustomTextField
          label="Description"
          id="filled-start-adornment"
          fullWidth
          sx={{
            // backgroundColor: "#fff",
            "& .MuiInputBase-root": {
              // backgroundColor: "#fff",
              ":hover": { backgroundColor: "button.texthover" },
            },
          }}
          variant="filled"
        />
        <CustomTextField
          focused
          label="Email"
          id="standard-start-adornment"
          fullWidth
          value={profileData?.email}
          variant="filled"
        />
      </Box>
    </Box>
  );
}

export default BusinessProfile;
