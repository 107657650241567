import {
    Box,
    Collapse,
    List,
    // ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    // Typography,
  } from "@mui/material";
  import React, { useState ,useEffect } from "react";
  import { useLocation, useNavigate } from "react-router-dom";
  import { useTheme } from "@emotion/react";
  import LogoutIcon from "@mui/icons-material/Logout";
  import LogOutModal from "../../common/LogOutModal";
    import { getPrivileges } from "../../../middleware/authServices";
  import RolesIcon from "../../../assets/icons/svg/SidebarRoles";
  import InsightIcon from "../../../assets/icons/svg/SidebarInsight";
  import UserIcon from "../../../assets/icons/svg/UserIcon";
  import SubscriptionIcon from "../../../assets/icons/svg/SidebarSubscription";
  // import UserTie from "../../assets/icons/svg/SidebarUserTie";
  import SidebarCase from "../../../assets/icons/svg/SidebarCase";
  import SidebarLock from "../../../assets/icons/svg/SidebarLock";
  import HomeSidebar from "../../../assets/icons/svg/HomeSidebar";
  import FeaturesIcon from "../../../assets/icons/svg/SidebarFeatures";
  import AgentSub from "../../../assets/icons/svg/SidebarAgentSub";
  import TicketsIcon from "../../../assets/icons/svg/SidebarTickets";
  
  // import WhatsAppIcon from "@mui/icons-material/WhatsApp";
  //icons
  // import BookIcon from "../../assets/icons/png/book.png";
  // import ChatbotIcon from "../../assets/icons/png/chatbot.png";
  // import ConnectionIcon from "../../assets/icons/png/connection.png";
  // import GrowthIcon from "../../assets/icons/png/growth.png";
  // import HomeIcon from "../../assets/icons/png/home.png";
  // import LogoutIcon from "../../assets/icons/png/logout.png";
  // import MessageIcon from "../../assets/icons/png/message.png";
  // import SettingsIcon from "../../assets/icons/svg/SidebarSettings";
  // import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
  // import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
  // import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
  // import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
  // import TelegramIcon from "@mui/icons-material/Telegram";
  // import LabelIcon from "@mui/icons-material/Label";
  // import FolderOpenIcon from "@mui/icons-material/FolderOpen";
  // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  // import PersonAddIcon from "@mui/icons-material/PersonAdd";
  // import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
  // import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
  // import PersonIcon from "@mui/icons-material/Person";
  // import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
  // import DehazeIcon from "@mui/icons-material/Dehaze";
  // import SearchIcon from "@mui/icons-material/Search";
  // import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
  // import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
  // import AssessmentIcon from "@mui/icons-material/Assessment";
  // import ArticleIcon from "@mui/icons-material/Article";
  // import Brightness7Icon from "@mui/icons-material/Brightness7";
  // import Brightness4Icon from "@mui/icons-material/Brightness4";
  // import HomeIcon from "../../assets/icons/svg/SidebarHome";
  // import InboxIcon from "../../assets/icons/svg/SidebarInbox";
  // import ScheduledMessageIcon from "../../assets/icons/svg/SidebarScheduled";
  // import { ThemeContext } from "../../theme";
  // import AddUser from "../../assets/icons/png/adduser.png";
  // import TemplateIcon from "../../assets/icons/svg/SidebarTemplates";
  // import ReportIcon from "../../assets/icons/svg/SidebarReport";
  // import BulkSendIcon from "../../assets/icons/svg/SidebarBulkSend";
  // import ChannelIcon from "../../assets/icons/svg/SidebarChannels";
  // import BusinessAccountIcon from "../../assets/icons/svg/SidebarBusiness";
  // import ConversationIcon from "../../assets/icons/svg/SidebarConversation";
  // import ConversationLabelIcon from "../../assets/icons/svg/SidebarLabels";
  // import GroupIcon from "../../assets/icons/svg/SidebarGroups";
  
  
  const SubListItem = styled(ListItemButton)({
    "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
      // height: "40px",
      alignItems: "center",
      // paddingLeft:"18px"
    },
  });
  
  function AdminSideBarList({ open }) {
    const [isModalOpen, setIsModalOpen] = useState(false); // State for logout modal
  
  
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";
    const navigate = useNavigate();
    const location = useLocation();
  
    const [openMenus, setOpenMenus] = useState({
      channels: false,
      settings: false,
      templates: false,
      reports: false,
      insights: false,
    });
  
    const [privileges, setPrivileges] = useState({});
  
    useEffect(() => {
      const fetchPrivileges = async () => {
        const response = await getPrivileges();
        setPrivileges(response);
      };
      fetchPrivileges();
    }, []);
  
    const handleToggleMenu = (menu) => {
      setOpenMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
    };
  
    //LOGOUT FUNCTIONALITY - START
    const handleLogoutClick = () => {
      setIsModalOpen(true); // Open the modal
    };
  
    const handleLogout = () => {
      localStorage.clear();
      navigate("/");
      setIsModalOpen(false); // Close the modal after logout
    };
  
    const handleModalClose = () => {
      setIsModalOpen(false); // Close the modal
    };
  
    const handleLogoutConfirm = () => {
      handleLogout(); // Call the existing handleLogout function
    };
  
    //LOGOUT FUNCTIONALITY - END
  
    console.log("pathname");
    console.log(location.pathname);
    const isSelected = (path) => location.pathname === path;
  
    const isMainPage = (path) => location.pathname.startsWith(path);
  
    const sidebarItems = [
  
      
  
      {
        label: "Dashboard",
        icon: (
          <HomeSidebar
            sx={{
              fontSize: "1.22rem",
              color: isSelected("/Admin-Dashboard")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard",
      },
      {
        label: "Manage Agents",
        privilegeKey: "View_Agent",
        icon: (
          <UserIcon
            sx={{
              fontSize: "1.22rem",
              color: isSelected("/Admin-Dashboard/Manage-Agents")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard/Manage-Agents",
      },
      {
        label: "Manage Tenants",
        privilegeKey: "View_Tenant",
        icon: (
          <UserIcon
            sx={{
              fontSize: "1.22rem",
              color: isSelected("/Admin-Dashboard/Manage-Tenants")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard/Manage-Tenants",
      },
      {
        label: "Subscription Plans",
        privilegeKey: "View_Plan",
        icon: (
          <SubscriptionIcon
            sx={{
              fontSize: "1.22rem",
              color: isSelected("/Admin-Dashboard/Subscription-Plans")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard/Subscription-Plans",
      },
      // {
      //   label: "User Subscriptions",
      //   privilegeKey: "View_Plan",
      //   icon: (
      //     <UserTie
      //       sx={{
      //         fontSize: "1.22rem",
      //         color: isSelected("/Admin-Dashboard/User-Subscriptions")
      //           ? isDarkMode
      //             ? "#0292d0"
      //             : "#002539"
      //           : isDarkMode
      //             ? "white"
      //             : "#0292d0",
      //       }}
      //     />
      //   ),
      //   path: "/Admin-Dashboard/User-Subscriptions",
      // },
      {
        label: "Payment Transactions",
        privilegeKey: "View_Payment",
        icon: (
          <SidebarCase
            sx={{
              fontSize: "1.22rem",
              color: isSelected("/Admin-Dashboard/Payment-Transactions")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard/Payment-Transactions",
      },
      {
        label: "Roles and Permissions",
        privilegeKey: "View_Roles",
        icon: (
          <RolesIcon
            sx={{
              fontSize: "1.22rem",
              color: isSelected("/Admin-Dashboard/permissions")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard/permissions",
      },
      {
        label: "Manage Features",
        privilegeKey: "View_Roles",
        icon: (
          <FeaturesIcon
            sx={{
              fontSize: "1.30rem",
              color: isSelected("/Admin-Dashboard/features")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard/features",
      },
      {
        label: "Manage Users",
        privilegeKey: "View_Users",
        icon: (
          <UserIcon
            sx={{
              fontSize: "1.22rem",
              color: isSelected("/Admin-Dashboard/users")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard/users",
      },
      {
        label: "Analytics and Reports",
        privilegeKey: "View_Reports",
        icon: (
          <InsightIcon
            sx={{
              fontSize: "1.22rem",
              color: isSelected("/Admin-Dashboard/analytics-reports")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard/analytics-reports",
      },
      // {
      //   label: "Profile",
      //   icon: (
      //      <UserIcon
      //        sx={{
      //          fontSize: "1.22rem",
      //          color: isSelected("/profile")
      //            ? isDarkMode
      //              ? "#0292d0"
      //              : "#002539"
      //            : isDarkMode
      //            ? "white"
      //            : "#0292d0",
      //        }}
      //      />
      //    ),
      //   path: "/profile",
      // },
      {
        label: "Change Password",
        icon: (
          <SidebarLock
            sx={{
              fontSize: "1.22rem",
              color: isSelected("/Admin-Dashboard/change-password")
                ? isDarkMode
                  ? "#0292d0"
                  : "#002539"
                : isDarkMode
                  ? "white"
                  : "#0292d0",
            }}
          />
        ),
        path: "/Admin-Dashboard/change-password",
      },
      // {
      //    label: "Reports",
      //    icon: (
      //       <ReportIcon
      //          sx={{
      //             fontSize: "1.22rem",
      //             color: isMainPage("/report")
      //                ? isDarkMode
      //                   ? "#0292d0"
      //                   : "#002539"
      //                : isDarkMode
      //                ? "white"
      //                : "#0292d0",
      //          }}
      //       />
      //    ),
      //    path: "/report",
      //    submenu: [
      //       {
      //          label: "Bulk Send Report",
      //          path: "/report/bulk-send",
      //          icon: (
      //             <BulkSendIcon
      //                sx={{
      //                   fontSize: "1.22rem",
      //                   color: isSelected("/report/bulk-send")
      //                      ? isDarkMode
      //                         ? "#0292d0"
      //                         : "#002539"
      //                      : isDarkMode
      //                      ? "white"
      //                      : "#0292d0",
      //                }}
      //             />
      //          ),
      //       },
      //    ],
      //    stateKey: "reports",
      // },
  
      {
        label: "Logout",
        icon: <LogoutIcon sx={{ color: "#ff0000", fontSize: "1.22rem" }} />,
        action: handleLogoutClick,
        // action: handleLogout,
        color: "#ff0000",
      },
    ];
  
    return (
      <>
        <List>
          {sidebarItems.map((item) => (
          (!item.privilegeKey || privileges[item.privilegeKey]) && (
            <React.Fragment key={item.label}>
              <Box sx={{ display: "flex" }}>
                <Box
                  width={8}
                  height="auto"
                  bgcolor={
                    isMainPage(item.path)
                      ? isDarkMode
                        ? "#0292d0"
                        : "#002539"
                      : isDarkMode
                        ? "#202020"
                        : "#fff"
                  }
                  sx={{ borderRadius: "0px 10px 10px 0px" }}
                ></Box>
                <ListItemButton
                  onClick={
                    item.submenu
                      ? () => handleToggleMenu(item.stateKey)
                      : item.action || (() => navigate(item.path))
                  }
                  selected={isSelected(item.path)}
                  sx={{
                    "& .MuiListItemIcon-root": {
                      minWidth: "30px !important",
                      height: "40px",
                      alignItems: "center",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "background.primary", // Change this to your desired background color for selected items
                      color: "secondary.contrastText", // Change this to your desired text color for selected items
                      "& .MuiListItemIcon-root": {
                        color: "secondary.contrastText", // Change the icon color for selected items
                      },
                    },
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{ fontSize: "0.95rem" }}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: isSelected(item.path)
                        ? "text.sidebaractive"
                        : "primary.main",
                    }}
                  />
                </ListItemButton>
              </Box>
              {item.submenu && (
                <Collapse
                  in={openMenus[item.stateKey]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.submenu.map((subItem) => (
                      <SubListItem
                        key={subItem.label}
                        sx={{ pl: 4 }}
                        selected={isSelected(subItem.path)}
                        onClick={() => navigate(subItem.path)}
                      >
                        <ListItemIcon
                          sx={{
                            color: isSelected(item.path)
                              ? "text.sidebaractive"
                              : "primary.main",
                          }}
                        >
                          {subItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={subItem.label}
                          primaryTypographyProps={{
                            fontSize: "0.95rem",
                          }}
                          sx={{
                            color: isSelected(subItem.path)
                              ? "text.sidebaractive"
                              : "primary.main",
                          }}
                        />
                      </SubListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
            )
          ))}
        </List>
  
        <LogOutModal
          open={isModalOpen}
          onClose={handleModalClose}
          onConfirm={handleLogoutConfirm}
        />
      </>
    );
  }
  
  export default AdminSideBarList;
  