import { Avatar, Box, styled, Typography } from "@mui/material";
import React from "react";
import moment from "moment";

const Message = styled(Box)({
   display: "flex",
   flexDirection: "column",
   marginBottom: "5px",
});

function UnsupportedMsg({ content }) {
   const time = moment(content.createdAt).format("h:mm a");

   return (
      <Box
         key={content._id}
         sx={{
            height: "auto",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            maxWidth: "450px",
            justifyContent: "start",
         }}
      >
         {content.flag === "incoming" && (
            <Box p={1}>
               <Avatar
                  sx={{
                     width: "30px",
                     height: "30px",
                     backgroundColor: "black",
                     color: "#fff",
                  }}
               />
            </Box>
         )}
         <Message>
            <Box
               p={1}
               sx={{
                  maxWidth: "300px",
                  height: "auto",
                  minWidth: "70px",
                  backgroundColor: content.flag === "incoming" ? "background.incomingmsg" : "background.outgoingmsg",
                  borderRadius: content.flag === "incoming" ? "0px 20px 20px 20px" : "20px 0px 20px 20px", 
                  position: "relative",
                  paddingRight: "20px",
                  overflow: "hidden",
                  wordWrap: "break-word",
               }}
            >
               <Typography sx={{ fontSize: "13px", marginRight: "10px",
                      color: (theme) => content.flag === "incoming" ? theme.palette.text.primary : "#fff", 

                }}>
                  Unsupported Message
               </Typography>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "end",
                     alignItems: "center",
                  }}
               >
                  <Typography
                     sx={{
                        fontSize: "11px",
                        fontWeight: "400",
                        color:content.flag === "incoming" ? "grey" : "#fff",
                        textAlign: "start",
                     }}
                  >
                     {time}
                  </Typography>
               </Box>
            </Box>
         </Message>
       
      </Box>
   );
}

export default UnsupportedMsg;
