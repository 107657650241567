import { Avatar, Box, Typography, styled } from "@mui/material";
import moment from "moment";
import React from "react";
import { sendMessageStatus } from "../../../../utils/helperFuncion";

const StyledAudioBox = styled(Box)(({ theme }) => ({
 
  '& audio': {
    
    '&::-webkit-media-controls-panel': {
      backgroundColor: '#99e3ff',
  },
   
  }
}));

const Message = styled(Box)(({left,right})=>(({
  display: "flex",
  flexDirection: "column",
  marginBottom: "5px",
  paddingLeft:left||0,
  paddingRight:right||0
  
})));

  const url = process.env.REACT_APP_IMAGE_URL;


function AudioMessage({ content }) {
    const time = moment(content.createdAt).format("h:mm a");
    const statusIcon = sendMessageStatus(content?.status);
  const audioUrl = `${url}/${content?.message?.image?.path || content?.message?.audio?.path}`;



  const path = content?.message?.audio?.path;

  return (
    <Box
      key={content._id}
      sx={{
        height: "auto",
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        justifyContent: content.flag === "outgoing" ? "end" : "start",
      }}
    >
      {/* {content.flag === "incoming" && (
        <Box p={1}>
          <Avatar
            sx={{
              width: "30px",
              height: "30px",
              backgroundColor: "black",
              color: "#fff",
            }}
          />
        </Box>
      )} */}
      <Message left={content.flag === "incoming"?20:0} right={content.flag === "outgoing"?20:0}>
        <Box
          p={1}
          sx={{
            width: "auto",
            height: "auto",
            // backgroundColor: content.flag === "incoming" ? "#F4F4F6" : "#d9fdd3",
            backgroundColor: content.flag === "incoming" ? "background.incomingmsg" : "background.outgoingmsg",
            borderRadius: content.flag === "incoming" ? "0px 20px 20px 20px" : "20px 0px 20px 20px",
          }}
        >
          <StyledAudioBox>
            <audio controls>
              <source src={audioUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </StyledAudioBox>
          <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: "400",
                color:content.flag === "incoming" ? "grey" : "#fff",
                textAlign: content.flag === "incoming" ? "start" : "end",
              }}
            >
              {time}
            </Typography>
            {content.flag === "outgoing" && statusIcon}
          </Box>
        </Box>
      </Message>
      
    </Box>
  );
}

export default AudioMessage;
