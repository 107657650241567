import { Avatar, Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { sendMessageStatus } from "../../../../utils/helperFuncion";

const Message = styled(Box)(({ left, right }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "5px",
  paddingLeft: left || 0,
  paddingRight: right || 0,
}));

function InteractiveButtonMessage({ content }) {
  const { message_input } = content;

  let parcedMessage, type, bodyText, headerText, footerText, actions, buttons;
  if (message_input) {
    parcedMessage = JSON.parse(message_input);
    type = parcedMessage?.interactive?.type;
    bodyText = parcedMessage?.interactive?.body?.text;
    headerText = parcedMessage?.interactive?.header?.text;
    footerText = parcedMessage?.interactive?.footer?.text;
    actions = parcedMessage?.interactive?.action;
    buttons = parcedMessage?.interactive?.action?.buttons;
  }
  const reply = content?.message?.interactive?.button_reply;
  const statusIcon = sendMessageStatus(content?.status);
  const time = moment(content.createdAt).format("h:mm a");

  const theme = useTheme();

  return (
    <>
      {content?.flag === "incoming" && (
        <Box
          sx={{
            height: "auto",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            maxWidth: content.flag === "incoming" ? "450px" : null,
            justifyContent: content.flag === "outgoing" ? "end" : "start",
          }}
        >
          <Message
            left={content.flag === "incoming" ? 20 : 0}
            right={content.flag === "outgoing" ? 20 : 0}
          >
            <Box
              p={1}
              sx={{
                width: "auto",
                height: "auto",
                color: content.flag === "incoming" ? "text.primary" : "#fff",
                backgroundColor:
                  content.flag === "incoming"
                    ? "background.incomingmsg"
                    : "background.outgoingmsg",
                borderRadius:
                  content.flag === "incoming"
                    ? "0px 20px 20px 20px"
                    : "20px 0px 20px 20px",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>{reply?.title}</Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "400",
                    color: "#978f8f",
                    textAlign: content.flag === "incoming" ? "start" : "end",
                  }}
                >
                  {time}
                </Typography>
                {content.flag === "outgoing" && statusIcon}
              </Box>
            </Box>
          </Message>
          {/* {content.flag === "outgoing" && (
                  <Box p={1}>
                     <Avatar
                        sx={{
                           width: "30px",
                           height: "30px",
                           backgroundColor: "black",
                           color: "#fff",
                        }}
                     />
                  </Box>
               )} */}
        </Box>
      )}
      {content?.flag === "outgoing" && (
        <Box
          mt={1}
          sx={{
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Message
            left={content.flag === "incoming" ? 20 : 0}
            right={content.flag === "outgoing" ? 20 : 0}
          >
            <Box
              p={1}
              sx={{
                width: "auto",
                height: "auto",
                backgroundColor: "background.outgoingmsg",
                maxWidth: "300px",
                borderRadius: "20px 0px 20px 20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                {headerText}
              </Typography>
              <span
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "13px",
                  color: "#fff",
                }}
              >
                {bodyText}
              </span>

              {footerText && (
                <Typography
                  variant="body1"
                  sx={{ fontSize: "11px" }}
                  color="#fff"
                >
                  {footerText}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "400",
                    color: "#fff",
                    textAlign: content?.flag === "incoming" ? "start" : "end",
                  }}
                >
                  {time}
                </Typography>
                {content.flag === "outgoing" && statusIcon}
              </Box>
            </Box>
            {type === "cta_url" && (
              <Box mt={1 / 2} display="flex" justifyContent="space-between">
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.outgoingmsg,
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.background.outgoingbtnHover,
                    },
                  }}
                  borderRadius="10px"
                  width="100%"
                  textAlign="center"
                  //   border="1px solid grey"
                >
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      textTransform: "capitalize",
                      color: "#fff",
                      backgroundColor: "background.outgoingbtn",
                      "&:hover": {
                        backgroundColor: "background.outgoingbtnHover",
                      },
                    }}
                  >
                    {actions?.parameters?.display_text}
                  </Button>
                </Box>
              </Box>
            )}
            {buttons?.map((btn) => (
              <Box mt={1 / 2} display="flex" justifyContent="space-between">
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.outgoingmsg,
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.background.outgoingbtnHover,
                    },
                  }}
                  borderRadius="10px"
                  width="100%"
                  textAlign="center"
                  //   border="1px solid grey"
                >
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      textTransform: "capitalize",
                      color: "#fff",
                      backgroundColor: "background.outgoingbtn",
                      "&:hover": {
                        backgroundColor: "background.outgoingbtnHover",
                      },
                    }}
                  >
                    {btn?.reply?.title}
                  </Button>
                </Box>
              </Box>
            ))}
          </Message>
          {/* <Box p={1}>
                  <Avatar
                     sx={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "black",
                        color: "#fff",
                     }}
                  />
               </Box> */}
        </Box>
      )}
    </>
  );
}

export default InteractiveButtonMessage;
