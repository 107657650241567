import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUserToken } from "../middleware/authServices";
import { getPrivileges } from "../middleware/authServices";

const PrivateRoute = ({ children, privilegeKey }) => {
    const isAuthenticated = getUserToken();
    const privileges = getPrivileges();

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    if (privilegeKey && !privileges[privilegeKey]) {
        return <Navigate to="/not-authorized" />;
    }

    return children;
};

export default PrivateRoute;
