import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../../middleware/utils";
import { getClientID } from "../../../middleware/authServices";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchTickets = createAsyncThunk(
   "manage_ticket/fetchTickets",
   async (userId = getClientID(), { rejectWithValue }) => {
      try {
         const response = await apiClient.get(
            `${API_URL}/ticket/assigned/${userId}`
         );
         return response.data;
      } catch (err) {
         console.log(err);
         return rejectWithValue(
            err.response.data.message || "Failed to fetch tickets"
         );
      }
   }
);

export const updateTicketStatus = createAsyncThunk(
   "manage_ticket/updateTicketStatus",
   async ({ ticketId, status }, { rejectWithValue }) => {
      try {
         const response = await apiClient.patch(
            `${API_URL}/ticket/${ticketId}/status`,
            { status }
         );
         return response.data;
      } catch (err) {
         console.log(err);
         return rejectWithValue(
            err.response.data.message || "Failed to update tickets"
         );
      }
   }
);

export const updatePriority = createAsyncThunk(
   "manage_ticket/updatePriority",
   async ({ ticketId, priority }, { rejectWithValue }) => {
      try {
         const response = await apiClient.patch(
            `${API_URL}/ticket/${ticketId}/priority`,
            { priority }
         );
         return response.data;
      } catch (err) {
         console.log(err);
         return rejectWithValue(
            err.response.data.message || "Failed to update tickets"
         );
      }
   }
);

const initialState = {
   tickets: [],
   loading: false,
   error: null,
};

const manageAgentTicket = createSlice({
   name: "manage_ticket",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         // Fetch Agent
         .addCase(fetchTickets.pending, (state) => {
            state.loading = true;
         })
         .addCase(fetchTickets.fulfilled, (state, action) => {
            state.loading = false;
            state.tickets = action.payload.tickets;
            state.error = null;
         })
         .addCase(fetchTickets.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         })

         //Update status
         .addCase(updateTicketStatus.pending, (state) => {
            state.loading = true;
         })
         .addCase(updateTicketStatus.fulfilled, (state, action) => {
            state.loading = false;
            const updatedTicket = action.payload;
            state.tickets = state.tickets.map((ticket) =>
               ticket._id === updatedTicket._id ? updatedTicket : ticket
            );
            state.error = null;
         })
         .addCase(updateTicketStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});

export default manageAgentTicket.reducer;
