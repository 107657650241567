import { Avatar, Box, Typography, styled } from "@mui/material";
import React from "react";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";

import userImage from "../../../assets/images/user-img.svg";
import { getAllMessagesByChatId, resetMessageState, saveSelectedChat } from "../../../store/slices/clientSlice/messageSlice";
import { updateNewMessageCount } from "../../../store/slices/clientSlice/chatSlice";


const CustomBox = {
   height: "66px",
   // marigin: "15px",
   display: "flex",
   cursor: "pointer",
   // borderLeft: `5px solid ${dynamicColor}`,
 
}

const ActiveCustomBox = {
   height: "66px",
   // margin: "15px",
   display: "flex",
   cursor: "pointer",
   backgroundColor: "background.selectedchat",
   borderRadius: "10px",
   color: "#fff",
};

function ChatList({ chatData, searchText }) {
   const dispatch = useDispatch();
   const { selectedChat } = useSelector((state) => state.messages);
   const date = moment(chatData?.chat?.last_message_at).format("L");
   const { chat } = chatData || {};

   const handleGetAllMessagesByChatId = async (chatId, chatData) => {
      dispatch(resetMessageState());
      dispatch(saveSelectedChat(chatData));

      await dispatch(getAllMessagesByChatId({ chatId, page: 1 }));
      dispatch(updateNewMessageCount({chatId,count:0}))
      // await dispatch(getAllChats({ searchKey: searchText }));
   };

   return (
      <Box
         sx={selectedChat?._id === chat?._id ? ActiveCustomBox : CustomBox}
         onClick={() => handleGetAllMessagesByChatId(chat?._id, chat)}
      >
         <Box
            display="flex"
            margin={1}
            justifyContent="space-between"
            width="100%"
            height="50px"
         >
            <Box display="flex" alignItems="center">
               <Avatar
                  src={userImage}
                  sx={{
                     backgroundColor: "background.profilebgchat",
                     border: chat?.label
                        ? `2px solid ${chat.label.color}`
                        : "none",
                     color: "white",
                     width: "45px",
                     height: "45px",
                     "& img": {
                        width: "41%",
                        height: "55%",
                     },
                  }}
               />
               <Box
                  ml={1.5}
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "center",
                     width: "100%",
                  }}
               >
                  <Typography
                     fontWeight="400"
                     sx={{
                        color:
                           selectedChat._id === chat._id
                              ? "#fff"
                              : "text.primary",
                     }}
                  >
                     {chat?.name || chat?.phone_number}
                  </Typography>

                  {/* {chat?._id !== "new" && (
                     <Typography color="#999" fontSize="13px" fontWeight="400">
                        {parseText(lastMessageText)}
                     </Typography>
                  )} */}
                  <Typography
                     fontSize="12px"
                     sx={{ color: "text.primary", opacity: "0.8" }}
                     fontWeight="300"
                  >
                     {/* Hello */}
                  </Typography>
               </Box>
            </Box>
            <Box
               sx={{
                  width: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "end",
               }}
            >
               {chatData?.chat?.last_message_at && (
                  <Typography
                     color={selectedChat._id === chat._id ? "#fff" : "#999"}
                     fontSize="10px"
                     fontWeight="400"
                     mt={1}
                  >
                     {date}
                  </Typography>
               )}

               <Box display="flex" gap={2} alignItems="end">
                  {chatData?.messageCount && chatData?.messageCount !== 0 ? (
                     <Avatar
                        sx={{
                           marginTop: "6px",
                           height: "18px",
                           width: "18px",
                           backgroundColor:
                              selectedChat._id === chat._id ? "" : "#0293D2",
                           fontSize: "9px",
                           color: "text.wpcount",
                        }}
                     >
                        {chatData?.messageCount}
                     </Avatar>
                  ) : (
                     ""
                  )}
                  {/* {chat?.label&&<FontAwesomeIcon icon="fa-solid fa-flag" style={{color:chat.label?.color}} />} */}
               </Box>
            </Box>
         </Box>
      </Box>
   );
}

export default ChatList;
