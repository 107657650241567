import {
   AppBar,
   Avatar,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   ListItemButton,
   ListItemText,
   Slide,
   Toolbar,
   Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import userImage from "../../../../assets/images/user-img.svg";

const url = process.env.REACT_APP_IMAGE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

function MediaPreviewModal({ open, content, handleClose }) {
   const { message } = content;
   const videoPath = message?.video?.path || message?.image?.path;
   const caption =
      message?.video?.caption ||
      message?.image?.caption ||
      message?.document?.caption;

   return (
      <div>
         <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose}>
            <DialogTitle display="flex" justifyContent="space-between">
               <Box
                  height={20}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  p={2}
               >
                  <Avatar
                     src={userImage}
                     sx={{
                        backgroundColor: "background.profilebgchat",

                        color: "white",
                        width: "45px",
                        height: "45px",
                        "& img": {
                           width: "41%",
                           height: "55%",
                        },
                     }}
                  />
                  <Typography>{content?.display_phone_number}</Typography>
               </Box>
               <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
            </DialogTitle>
            <DialogContent>
               <DialogContentText></DialogContentText>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     p: 3,
                  }}
               >
                  <video
                     style={{
                        objectFit: "contain",
                        maxHeight:"500px"
                     }}
                     controls
                  >
                     <source src={`${url}/${videoPath}`} type="video/mp4" />
                  </video>
               </Box>
            </DialogContent>
         </Dialog>
      </div>
   );
}

export default MediaPreviewModal;
