import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import * as chatService from "../../../services/chatService";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { styled } from "@mui/material/styles";

// Phone input style
const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
   border: `1px solid ${theme.palette.border.buttonborder}`,
   borderRadius:"5px",
   paddingLeft:"9px",
   transition: 'border-color 0.3s ease', 
   "&:focus-within": { 
      borderColor: '#0292d0', 
      outline: 'none', 
    },
    "&:hover": { 
      borderColor: '#0292d0', 
    },
  "& input": {
    backgroundColor: theme.palette.background.bgbutton,
    color: theme.palette.dateRangePicker.textColor,
    borderTopRightRadius:"5px",
    borderBottomRightRadius:"5px",
  },
}));
// Phone input style


const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalBox: {
    width: "415px",
    height: "auto",
    backgroundColor: (theme) => theme.palette.background.modal,
    borderRadius: "8px",
    padding: "30px",
    position: "relative",
    textAlign: "center",
    "@media (max-width: 600px)": {
      width: "314px",
    },
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    color: (theme) => theme.palette.grey[500],
  },
};
const inputStyle = {
  height: "34px",
  border: "1px solid #8080808a",
  borderRadius: "5px",
  paddingLeft: "5px",
};

const PhoneInputchat = {
   borderRadius: "5px !important",
   border: "none",
   outline: "none",
   height: "29px",
   paddingLeft: "20px",
   backgroundColor:"black",
   fontWeight: 600,
};

function AddContacts({ onClose }) {
  const theme = useTheme();
  const [newContact, setNewContact] = useState("");

  const schema = yup.object().shape({
    firstname: yup.string().min(3).required("first name is required"),
    lastname: yup.string().min(1),
    email: yup.string(),
    // phoneNumber: yup
    //    .string()
    //    .matches(
    //       /^[1-9]{1}[0-9]{1,2}[0-9]{7,15}$/, // Regex to match country code and phone number
    //       "Phone number must start with country code (without +) and be valid"
    //    )
    //    .required("Phone number is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // form on submit function for create role
  const onSubmit = async (data) => {
    if (!isValidPhoneNumber(newContact)) {
      toast.error(" Please enter valid mobile number.");
      return;
    }
    const number = newContact.replace("+", "");
    data.phoneNumber = number;

    try {
      // const response = await chatService.createChat(data);
      // if (response && response.status === "success") {
      //   toast.success("Contact Created");
      //   onClose();
      // } else {
      //   toast.error(response.message || "Contact creation failed");
      // }
    } catch (err) {
    }
  };

  return (
    <Box>
      <Toaster />
      <Box sx={styles.modalBox}>
        <IconButton sx={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Box textAlign="center" padding={2}>
          <Typography sx={{ color: "text.primary", fontSize: "25px" }}>
            Add Contact
          </Typography>
          <Typography
            variant="body2"
            sx={(theme) => ({
              opacity: 0.8,
              fontWeight: 400,
              fontSize: "14px",
              color: theme.palette.text.darkgrey,
              "& span": {
                fontWeight: 600,
              },
            })}
          >
            Enter all the details to <span>Create</span> a new contact
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              spacing={2}
              sx={{ height: "100%", marginTop: "14px" }}
            >
              <Grid item xs>
                <TextField
                  fullWidth
                  size="small"
                  id="firstname"
                  label="First Name"
                  error={!!errors.firstname}
                  helperText={errors.firstname ? errors.firstname.message : ""}
                  {...register("firstname")}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  size="small"
                  id="lastname"
                  label="Last Name"
                  error={!!errors.lastname}
                  helperText={errors.lastname ? errors.lastname.message : ""}
                  {...register("lastname")}
                />
              </Grid>
              <Grid item xs>
                {/* <TextField
                           fullWidth
                           size="small"
                           id="phonenumber"
                           label="Phone number"
                           error={!!errors.phoneNumber}
                           helperText={
                              errors.phoneNumber
                                 ? errors.phoneNumber.message
                                 : ""
                           }
                           {...register("phoneNumber")}
                        /> */}

                <StyledPhoneInput
                  defaultCountry="IN"
                  value={newContact}
                  onChange={setNewContact}
                  placeholder="Phone number"
                  className="phone-inputcontact"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  size="small"
                  id="email"
                  label="Email"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                  {...register("email")}
                />
              </Grid>
              <Grid item xs display="flex" justifyContent="end">
                <Button
                  sx={{ width: "140px", borderRadius: "5px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
}

export default AddContacts;
