import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { CircularProgress, styled } from "@mui/material";
import { useTheme } from "@emotion/react";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 432,
   height: 227,
   bgcolor: "background.paper",
   borderRadius: "20px",
   boxShadow: 24,
   p: 4,
};

const CancelBtn = styled(Button)(({ theme }) => ({
   color: theme.palette.mode === "dark" ? "#fff" : "black",
   borderColor: theme.palette.mode === "dark" ? "#646464" : "#646464",
   backgroundColor: theme.palette.mode === "dark" ? "#222222" : "#f0f0f0",
   textTransform: "capitalize",
   height: "40px",
   borderRadius: "5px",
   width: "110px",
   marginTop:"1px",
   '&:hover': {
      backgroundColor: theme.palette.mode === "dark" ? "#333333" : "#231f1f",
      color: theme.palette.mode === "dark" ? "#fff" : "#fff",
      
    },
}));

const DeleteBtn = styled(Button)(({ theme }) => ({
   backgroundColor: "#ff0000",
   color: "#fff",
   textTransform: "capitalize",
   width: "110px",
   height: "40px",
   borderRadius: "5px",
   '&:hover': {
      backgroundColor: "#950000",
    },
}));

const IconBox = styled(Box)({
   height: 64,
   width: 64,
   backgroundColor: "#FFE6E6",
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   borderRadius: "10px",
});

function WarningModal({
   open,
   setOpen,
   modalName,
   modalContent,
   fun,
   loading,
}) {
   const theme = useTheme();
   const isDarkMode = theme.palette.mode === "dark";

   const handleClose = () => setOpen(false);

   if (!open) return;
   return (
      <div>
         <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
               backdrop: {
                  timeout: 500,
               },
            }}
         >
            <Fade in={open}>
               <Box sx={style}>
                  <Box
                     width="100%"
                     display="flex"
                     alignItems="center"
                     justifyContent="center"
                  >
                     <IconBox>
                        <WarningAmberRoundedIcon
                           sx={{ fontSize: "34px", color: "red" }}
                        />
                     </IconBox>
                  </Box>
                  <Box
                     display="flex"
                     flexDirection="column"
                     alignItems="center"
                     textAlign="center"
                     mt={2}
                  >
                     <Typography variant="h6" sx={{ color: "text.primary" }}>
                        {modalName} ?
                     </Typography>
                     <Typography
                        id="transition-modal-description"
                        sx={{ mt: 1 }}
                        variant="body2"
                        color="text.primary"
                     >
                       {modalContent||"Are you sure you want to delete. This action cannot be undone"} 
                     </Typography>
                  </Box>
                  <Box mt={2} display="flex" justifyContent="center" gap={2}>
                     <CancelBtn variant="outlined" onClick={handleClose}>
                        Cancel
                     </CancelBtn>
                     <DeleteBtn variant="contained" onClick={() => fun()}>
                        {loading ? (
                           <CircularProgress sx={{ color: "#fff" }} size={25} />
                        ) : (
                           "Confirm"
                        )}
                     </DeleteBtn>
                  </Box>
               </Box>
            </Fade>
         </Modal>
      </div>
   );
}

export default WarningModal;
