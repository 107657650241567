import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../../middleware/utils';
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAgent = createAsyncThunk("agent/getAllAgent", async (query = '') => {
  try {
    const response = await apiClient.get(`${API_URL}/agent?q=${query}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const fetchAllAgentDetails = createAsyncThunk("agent/agent-all-details", async (agentData, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(`${API_URL}/agent/agent-all-details`, agentData);
    return response.data;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err.response.data.message || 'Failed to Fetch');
  }
});

export const createAgent = createAsyncThunk("agent/createAgent", async (agentData, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(`${API_URL}/agent/create-agent`, agentData);
    return response.data;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err.response.data.message || 'Failed to Create');
  }
});

export const updateAgent = createAsyncThunk("agent/updateAgent", async (agentData, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(`${API_URL}/agent/update-agent`, agentData);
    return response.data;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err.response.data.message || 'Failed to Update');
  }
});

export const deleteAgent = createAsyncThunk("agent/deleteAgent", async (agentData, { rejectWithValue }) => {
  try {
    await apiClient.post(`${API_URL}/agent/delete-agent`, agentData);
    return agentData.id;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err.response.data.message || 'Failed to Delete');
  }
});

const initialState = {
  agent: [],
  loading: false,
  error: null,
};

const manageAgentSlice = createSlice({
  name: "manage_agent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Agent
      .addCase(fetchAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.agent = action.payload;
        state.error = null;
      })
      .addCase(fetchAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch All Agent Details
      .addCase(fetchAllAgentDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllAgentDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.agent = action.payload;
        state.error = null;
      })
      .addCase(fetchAllAgentDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create Agent
      .addCase(createAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.agent = [...state.agent, action.payload];
        state.error = null;
      })
      .addCase(createAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Agent
      .addCase(updateAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.agent = [...state.agent, action.payload];
        state.error = null;
      })
      .addCase(updateAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Delete Agent
      .addCase(deleteAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.agent = state.agent.filter(agent => agent._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default manageAgentSlice.reducer;
