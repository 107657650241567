import { Box, Typography, Button, Grid, IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { styled, useTheme } from "@mui/material/styles";
import toast, { Toaster } from "react-hot-toast";
import { handleDownloadBulkContact } from "../../../../utils/helperFuncion";
import apiClient from "../../../../middleware/utils";

const VisuallyHiddenInput = styled("input")({
   clip: "rect(0 0 0 0)",
   clipPath: "inset(50%)",
   height: 1,
   overflow: "hidden",
   position: "absolute",
   bottom: 0,
   left: 0,
   whiteSpace: "nowrap",
   width: 1,
});
const csvbtnStyle = (theme) => ({
   width: "200px",
   backgroundColor: theme.palette.background.bgbutton,
   textTransform: "capitalize",
   border: "0.5px solid #0293d2",
   color: "#0293d2",
   paddingLeft: "22px",
   paddingRight: "22px",
   borderRadius: "5px",
   "&:hover": {
      backgroundColor: "#0293d2",
      color: "#fff",
      border: "0.5px solid #0293d2",
   },
});

const styles = {
   overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
   },
   modalBox: {
      width: "447px",
      height: "auto",
      backgroundColor: (theme) => theme.palette.background.modal,
      borderRadius: "8px",
      padding: "30px",
      position: "relative",
      textAlign: "center",
      "@media (max-width: 600px)": {
         width: "314px",
      },
   },
   closeButton: {
      position: "absolute",
      top: "8px",
      right: "8px",
      color: (theme) => theme.palette.grey[500],
   },
};

function ImportContact({ onClose }) {
   const theme = useTheme();

   const fileInputRef = useRef(null);
   const [loading, setLoading] = useState(false);
   const [savedContacts, setSavedContacts] = useState([]);
   const [csvfile, setcsvFile] = useState(null);

   // Trigger the file input to open the file select dialog
   const handleClick = () => {
      fileInputRef.current.click();
   };

   // Function to handle file upoload
   const handleFileUpload = async () => {
      if (!csvfile) return;
      const formData = new FormData();
      formData.append("contacts", csvfile, csvfile.name);
      setLoading(true);

      try {
         const response = await apiClient.post(
            `${process.env.REACT_APP_API}/contacts`,
            formData
         );
         setLoading(false);
         if (response && response.data.status === "success") {
            setSavedContacts(response.data.results);
            setcsvFile(null);
            onClose()
            toast.success("File Uploaded");
         }
      } catch (err) {
         setLoading(false);
         toast.error(err.message || "Somthing went wrong");
      }
   };

   const handleRemoveFIle = () => setcsvFile(null);

   const handleFileChange = (event) => {
      // Handle selected file(s) here
      const file = event.target.files[0];
      setcsvFile(file);
      // handleFileUpload(file);
   };

   return (
      <Box>
         <Box sx={styles.modalBox}>
            <IconButton sx={styles.closeButton} onClick={onClose}>
               <CloseIcon />
            </IconButton>
            <Box textAlign="center" padding={3}>
               <Toaster />

               <Typography
                  variant="h5"
                  sx={{ color: "text.primary", fontSize: "25px" }}
               >
                  Import Contact
               </Typography>
               <Typography
                  variant="body2"
                  sx={(theme) => ({
                     opacity: 0.8,
                     fontWeight: 400,
                     fontSize: "14px",
                     color: theme.palette.text.darkgrey,
                     "& span": {
                        fontWeight: 600,
                     },
                  })}
               >
                  Download the sample contact <span>csv file</span> to upload
                  the contact
               </Typography>
            </Box>
            <Grid
               container
               justifyContent="space-between"
               spacing={2}
               padding={1}
            >
               <Grid item xs={12} sm={6}>
                  <Button
                     variant="text"
                     sx={{
                        width: "200px",
                        color: "primary.main",
                        borderRadius: "5px",
                        border: "0.5px solid transparent",
                        "&:hover": {
                           color: "white",
                        },
                     }}
                     onClick={handleDownloadBulkContact}
                  >
                     Download .csv Sample
                  </Button>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Button
                     variant="outlined"
                     onClick={handleClick}
                     sx={csvbtnStyle(theme)}
                  >
                     Upload .csv File
                  </Button>
                  <VisuallyHiddenInput
                     type="file"
                     accept={".csv"}
                     onChange={handleFileChange}
                     ref={fileInputRef}
                  />
               </Grid>
               {csvfile && (<Box pl={6} mt={2} display="flex" gap={5}>
                  
                     <span style={{ fontSize: "13px" }}>
                        {csvfile?.name}File Uploaded
                     </span>

                     <span style={{fontSize:"13px",color:"red",cursor:"pointer"}} onClick={handleRemoveFIle}>remove</span>
                 
               </Box> )}

               <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="end"
                  sx={{ paddingRight: "6px" }}
               >
                  <Button
                     onClick={() => handleFileUpload()}
                     sx={{ width: "140px", borderRadius: "5px" }}
                     variant="contained"
                     color="primary"
                     disabled={loading?true:false}
                  >
                     {loading?"Uploading...":"Submit"}
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
}

export default ImportContact;
