import { SvgIcon } from "@mui/material";
import React from "react";

function TicketsIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2705_4181)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.98143 2.1431C9.38424 2.13231 8.79078 2.2394 8.23502 2.45823C7.67926 2.67706 7.17211 3.00334 6.74259 3.4184C6.31308 3.83345 5.96962 4.32913 5.73189 4.87707C5.49416 5.42501 5.36681 6.01446 5.35714 6.61167V12.8574C5.35714 13.2363 5.20663 13.5996 4.93872 13.8675C4.67081 14.1354 4.30745 14.286 3.92857 14.286H2.14286C1.57454 14.286 1.02949 14.0602 0.627628 13.6583C0.225765 13.2565 0 12.7114 0 12.1431L0 9.28595C0 8.71763 0.225765 8.17259 0.627628 7.77073C1.02949 7.36886 1.57454 7.1431 2.14286 7.1431H3.21429V6.58595C3.22732 5.70794 3.41319 4.8411 3.76128 4.03493C4.10936 3.22877 4.61285 2.49907 5.24298 1.88751C5.87311 1.27595 6.61755 0.79451 7.43376 0.47069C8.24998 0.146869 9.12199 -0.0129906 10 0.000240221C10.878 -0.0129906 11.75 0.146869 12.5662 0.47069C13.3825 0.79451 14.1269 1.27595 14.757 1.88751C15.3872 2.49907 15.8906 3.22877 16.2387 4.03493C16.5868 4.8411 16.7727 5.70794 16.7857 6.58595V7.1431H17.8571C18.4255 7.1431 18.9705 7.36886 19.3724 7.77073C19.7742 8.17259 20 8.71763 20 9.28595V12.1431C20 12.7114 19.7742 13.2565 19.3724 13.6583C18.9705 14.0602 18.4255 14.286 17.8571 14.286H16.7857V15.0002C16.7859 15.9656 16.4306 16.8973 15.7877 17.6174C15.1448 18.3375 14.2592 18.7957 13.3 18.9045C13.1137 19.2371 12.8421 19.514 12.5131 19.7066C12.1842 19.8992 11.8098 20.0006 11.4286 20.0002H9.28571C8.71739 20.0002 8.17235 19.7745 7.77049 19.3726C7.36862 18.9707 7.14286 18.4257 7.14286 17.8574C7.14286 17.2891 7.36862 16.744 7.77049 16.3422C8.17235 15.9403 8.71739 15.7145 9.28571 15.7145H11.4286C12.2029 15.7145 12.8814 16.1245 13.2571 16.7402C13.6509 16.6497 14.0023 16.4284 14.254 16.1124C14.5057 15.7963 14.6428 15.4043 14.6429 15.0002V6.61024C14.6331 6.00995 14.5044 5.41756 14.2642 4.86732C14.0241 4.31707 13.6773 3.81987 13.2438 3.40448C12.8104 2.98909 12.2989 2.66373 11.7389 2.44722C11.1789 2.23071 10.5816 2.12735 9.98143 2.1431Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2705_4181">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default TicketsIcon;
