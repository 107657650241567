import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { createContext, useEffect, useMemo, useState } from "react";
import { getDesignTokens } from "./theme";

export const ThemeContext = createContext(null);

export const ThemeContextProvider = ({ children }) => {
   const [themeMode, setThemeMode] = useState("light");
   // const [theme, setTheme] = useState(getDesignTokens("light"));
   console.log(themeMode);

   const switchThemeMode = () => {
      setThemeMode((prev) => (prev === "light" ? "dark" : "light"));
   };

   // useEffect(() => {
   //    const newTheme = createTheme(getDesignTokens(themeMode));
   //    setTheme(newTheme);
   // }, [themeMode]); 
   
   
   const theme = useMemo(() => createTheme(getDesignTokens(themeMode)), [themeMode]);
   
  useEffect(() => {
   if (theme) {
     // Apply the theme background color to the body
     document.body.style.backgroundColor = theme.palette.background.body;
   }
 }, [theme]);

   return (
      <ThemeContext.Provider value={{ themeMode, switchThemeMode }}>
         <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
   );
};
