import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
   StepConnector,
   stepConnectorClasses,
   styled,
   Tab,
   Tabs,
} from "@mui/material";

import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import bulkSendImage from "../../../../assets/images/bulksend.svg";
import apiClient from "../../../../middleware/utils";
import CustomTabPanel from "../../common/CustomTabPanel";
import { getAllGroups } from "../../../../store/slices/clientSlice/groupSlice";
import { getAllChats } from "../../../../store/slices/clientSlice/chatSlice";
import BulkTemplateViewModal from "../BulkTemplateViewModel";
import StepperListContact from "./StepperListContact";
// import { fetchBulkSendTemplateByName } from "../../../services/templateService";



const MainBox = styled(Box)({
   margin: "30px 180px",
   "@media (max-width: 800px)": {
      margin: "30px 30px",
   },
});

const backBtn = (theme) => ({
   width: "auto",
   backgroundColor: theme.palette.background.bgbutton,
   textTransform: "capitalize",
   border: "0.5px solid #0293d2",
   color: "#0293d2",
   paddingLeft: "22px",
   paddingRight: "22px",
   borderRadius: "5px",
   height: "40px",
   "&:hover": {
      backgroundColor: "#0293d2",
      color: "#fff",
      border: "0.5px solid #0293d2",
   },
});

const steps = ["Select template", "Add value", "Select contact"];

const CustomDatePicker = styled(DateTimePicker)`
   .MuiInputBase-root {
      height: 40px; /* Adjust the height as needed */
      overflow: hidden; /* Hide overflow */
   }
   .MuiInput-input::placeholder {
      text-align: center; /* Center-align the placeholder text */
   }

   .MuiPickersPopper-container {
      height: auto; /* Adjust the calendar pop-up height as needed */
      overflow: hidden; /* Hide overflow */
   }
`;

const TemplateNameBox = styled(Box)(({ theme }) => ({
   // height:"50px",
   backgroundColor: theme.palette.background.backgroundgrey,
   display: "flex",
   padding: "15px 32px",
   marginTop: 5,
   justifyContent: "space-between",
   borderRadius: "5px",
   alignItems: "center",
   cursor: "pointer",
}));

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
   [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
   },
   [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
         // backgroundImage:
         //    "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
         backgroundColor: theme.palette.primary.main,
      },
   },
   [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
         // backgroundImage:
         //    "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
         backgroundColor: theme.palette.primary.main,
      },
   },
   [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
         theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
   },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
   backgroundColor: theme.palette.mode === "dark" ? "#EBF4FB" : "#EBF4FB",
   zIndex: 1,
   color: theme.palette.primary.main,
   width: 50,
   height: 50,
   display: "flex",
   borderRadius: "50%",
   justifyContent: "center",
   alignItems: "center",
   // backgroundColor: "#EBF4FB",

   ...(ownerState.active && {
      border: `1px solid ${theme.palette.background.backgroundblue}`,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      backgroundColor: "#EBF4FB",
      color: theme.palette.primary.main,
   }),
   ...(ownerState.completed && {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",

      // backgroundImage:
      //    "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
   }),
}));

export default function HorizontalLinearStepper({
   templates,
   handleClose,
   activeStep,
   setActiveStep,
   handleBack,
}) {
   const theme = useTheme();
   const isDarkMode = theme.palette.mode === "dark";
   const dispatch = useDispatch();
   const [value, setValue] = useState(0);
   const [selectedTemplates, setSelectedTemplates] = useState({});
   const [newTemplateData, setNewTemplateData] = useState({});
   const [selectedContacts, setSelectedContacts] = useState([]);
   const [selectedGroups, setSelectedGroups] = useState([]);
   const [scheduledDate, setScheduledDate] = useState(null);
   const [loading, setLoading] = useState(false);

   const { groups } = useSelector((state) => state.group);
   const allContacts = useSelector((state) => state.contacts.contacts);

   const currentDateTime = dayjs();
   const minDateTime = currentDateTime.add(1, "hour");

   const handleChange = (event, newValue) => {
      if (newValue === 0) setScheduledDate(null);
      setValue(newValue);
   };

   const handleSelectTemplate = (template) => {
      setSelectedTemplates(template);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   };

   useEffect(() => {
      dispatch(getAllGroups());
   }, []);

   // Whenever the selectedgroup state is updated take the selected groupId and copy
   // the contacts to the selected contact state(and remove the duplicate)

   const handleGroupSelection = () => {
      let updatedContacts = [...selectedContacts];

      selectedGroups.forEach((groupId) => {
         const group = groups.find((group) => group._id === groupId);

         if (group) {
            // Check if group contacts are already in the selectedContacts array
            const groupContactsExist = group.contacts.every((contact) =>
               selectedContacts.includes(contact)
            );

            if (groupContactsExist) {
               // If all group contacts exist, remove them
               updatedContacts = updatedContacts.filter(
                  (contact) => !group.contacts.includes(contact)
               );
            } else {
               // If not all group contacts exist, add them
               updatedContacts = [...updatedContacts, ...group.contacts];
            }
         }
      });

      setSelectedContacts(updatedContacts);
   };

   //  useEffect(() => {
   //     handleGroupSelection();
   //     console.log(selectedContacts)
   //  }, [selectedGroups]);

   const handleBulkSendTemplate = async () => {
      try {
         newTemplateData.append("contacts", JSON.stringify(selectedContacts));
         if (scheduledDate) {
            // const formatedDate = scheduledDate?.format("YYYY-MM-DD");
            const isoDate = dayjs(scheduledDate).toISOString();
            newTemplateData.append("scheduledTime", isoDate);
         }
         setLoading(true);
         const response = await apiClient.post(
            `/messages/bulk-send`,
            newTemplateData
         );

         setLoading(false);
         if (response && response?.data?.status === "success") {
            handleReset();
            dispatch(getAllChats({ page: 1 }));
            // handleClose();
            toast.success("Message sending has been initiated.");
         } else {
            toast.error("Unable to send message");
         }
      } catch (err) {
         setLoading(false);
         toast.error("something went wrong");
      }
   };

   const handleNext = async () => {
      if (activeStep === 2 && selectedContacts.length < 1) {
         toast.error("Please select atleast one contact");
         return;
      }
      //1) function to fetch the bulk send data if there is any message send within one minutes ago
      // const response = await fetchBulkSendTemplateByName(
      //    selectedTemplates?.name
      // );

      // if (!response || response?.data?.length === 0) {
      //    setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // } else {
      //    const allContacts = response?.data?.flatMap((obj) => obj.contacts);

      //    // Remove duplicates using Set()
      //    const uniqueContacts = [...new Set(allContacts)];
      //    const existingContacts = uniqueContacts.filter((num) =>
      //       selectedContacts.includes(num)
      //    );
      //    if (existingContacts.length > 0) {
      //       toast.error(
      //          `${existingContacts}You cannot send the message to these contacts right now , please try after one minute`
      //       );
      //    } else {
      //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //    }
      // }
   };

   // const handleBack = () => {
   //    setActiveStep((prevActiveStep) => prevActiveStep - 1);
   // };

   const handleReset = () => {
      setActiveStep(0);
      setNewTemplateData({});
      setSelectedContacts([]);
      setSelectedTemplates({});
      setSelectedGroups([]);
   };

   function ColorlibStepIcon(props) {
      const { active, completed, className } = props;
      const icons = {
         1: <SettingsIcon />,
         2: <GroupAddIcon />,
         3: <VideoLabelIcon />,
      };

      return (
         <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
         >
            {/* {icons[String(props.icon)]} */}
            <Typography>{props.icon}</Typography>
         </ColorlibStepIconRoot>
      );
   }

   return (
      <MainBox width="inherit">
         <Toaster
            toastOptions={{
               success: {
                  duration: 3000,
               },
               error: {
                  duration: 5000,
               },
            }}
         />
         {/* <Box sx={{position:"fixed",width:'inherit'}}>
            <Stepper
               activeStep={activeStep}
               alternativeLabel
               connector={<ColorlibConnector />}
            >
               {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                     <Step key={label} {...stepProps}>
                        <StepLabel
                           sx={{
                              "& .MuiStepLabel-label": {
                                 color: "primary.main",
                                 fontSize: "12px",
                              },
                           }}
                           {...labelProps}
                           StepIconComponent={ColorlibStepIcon}
                        >
                           {label}
                        </StepLabel>
                     </Step>
                  );
               })}
            </Stepper>
         </Box> */}
         {activeStep === steps.length ? (
            <React.Fragment>
               <Typography sx={{ mt: 2, mb: 1,color:"text.primary" }}>
                  All steps completed
               </Typography>
               <Box sx={{ display: "flex", flexDirection: "column", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Box>
                     <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                     >
                        <Tab label="Send Now" {...a11yProps(0)} />
                        <Tab label="Schedule for Later" {...a11yProps(1)} />
                     </Tabs>
                     <CustomTabPanel
                        value={value}
                        index={0}
                        style={{
                           backgroundColor:
                              theme.palette.background.backgroundgrey,
                        }}
                     >
                        <Typography fontSize="" marginBottom={2} color="text.primary">
                           Click the send button to send the messages to the
                           selected contacts.
                        </Typography>
                        {/* <BlackCustomButton
                           value={loading ? "sending..." : "Send"}
                           width="100px"
                           fun={handleBulkSendTemplate}
                        /> */}
                        <Button
                           variant="contained"
                           color="primary"
                           onClick={handleBulkSendTemplate}
                           disabled={loading?true:false}
                        >
                           {loading ? "sending..." : "Send"}
                        </Button>
                        {/* <Button sx={{marginTop:"10px",color:"#ff"}} variant="contained" onClick={() => handleBulkSendTemplate()}>
                           Send
                        </Button> */}
                     </CustomTabPanel>
                     <CustomTabPanel
                        value={value}
                        index={1}
                        style={{
                           backgroundColor:
                              theme.palette.background.backgroundgrey,
                        }}
                     >
                        <Typography fontSize="" marginBottom={2} color="text.primary">
                           You can schedule this bulk send message for the
                           future.
                        </Typography>
                        <Box>
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                 components={["DatePicker", "DatePicker"]}
                              >
                                 <CustomDatePicker
                                    label="Select Date"
                                    value={scheduledDate}
                                    onChange={(newValue) =>
                                       setScheduledDate(newValue)
                                    }
                                    minDate={minDateTime}
                                    // minTime={dayjs()}
                                 />
                              </DemoContainer>
                           </LocalizationProvider>
                        </Box>
                     </CustomTabPanel>
                  </Box>
                  <br />
                  <Box
                     width="100%"
                     display="flex"
                     height="50px"
                     justifyContent="space-between"
                  >
                     {activeStep === steps.length && (
                        <Button
                           variant="outlined"
                           disabled={activeStep === 0}
                           onClick={handleBack}
                           sx={backBtn(theme)}
                        >
                           Back
                        </Button>
                     )}
                     {scheduledDate && (
                        <Box
                           mt={2}
                           width="100%"
                           display="flex"
                           justifyContent="end"
                        >
                           {/* <BlackCustomButton
                              value="Send"
                              width="100px"
                              fun={handleBulkSendTemplate}
                           /> */}
                           <Button
                              variant="contained"
                              onClick={handleBulkSendTemplate}
                           >
                              {loading ? "sending..." : "Send"}
                           </Button>
                        </Box>
                     )}

                     {/* <Button
                        sx={{ backgroundColor: "text.primary" }}
                        onClick={handleReset}
                     >
                        Reset
                     </Button> */}
                     {/* <Button onClick={() => handleBulkSendTemplate()}>
                        Send
                     </Button> */}
                  </Box>
               </Box>
            </React.Fragment>
         ) : (
            <React.Fragment>
               {/* <Typography sx={{ mt: 2, mb: 1 }}>
                  Step {activeStep + 1}
               </Typography> */}

               {activeStep === 0 && (
                  <Box>
                     {templates?.map((template) => (
                        <TemplateNameBox
                           key={template?.id}
                           onClick={() => handleSelectTemplate(template)}
                        >
                           {/* <FontAwesomeIcon icon="fa-regular fa-layer-group" /> */}
                           <Box gap={2} display="flex" alignItems="center">
                              {/* <FontAwesomeIcon
                      style={{
                        cursor: "pointer",
                      }}
                      icon="fa-solid fa-layer-group"
                    /> */}
                              <img
                                 src={bulkSendImage}
                                 alt="Bulk Chat"
                                 style={{
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                    filter: isDarkMode ? "invert(1)" : "none",
                                 }}
                              />
                              <Typography variant="body2" color="text.primary">
                                 {template?.name}
                              </Typography>
                           </Box>

                           <ArrowForwardIosIcon sx={{ color: theme => theme.palette.text.primary }}/>
                        </TemplateNameBox>
                     ))}
                  </Box>
               )}
               {activeStep === 1 && (
                  <BulkTemplateViewModal
                     selectedTemplate={selectedTemplates}
                     setNewTemplateData={setNewTemplateData}
                     handleNext={handleNext}
                     handleBack={handleBack}
                  />
               )}
               {activeStep === 2 && (
                  <StepperListContact
                     allContacts={allContacts}
                     selectedContacts={selectedContacts}
                     setSelectedContacts={setSelectedContacts}
                     handleBack={handleBack}
                     groups={groups}
                     setSelectedGroups={setSelectedGroups}
                     selectedGroups={selectedGroups}
                     handleGroupSelection={handleGroupSelection}
                  />
               )}
               <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {activeStep === steps.length - 1 && (
                     <Button
                        variant="outlined"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        //  sx={{ mr: 1 }}
                        sx={backBtn(theme)}
                     >
                        Back
                     </Button>
                  )}
                  <Box sx={{ flex: "1 1 auto" }} />

                  {activeStep === steps.length - 1 && (
                     <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{
                           textTransform: "capitalize",
                           borderRadius: "5px",
                        }}
                     >
                        Finish
                     </Button>
                  )}
               </Box>
            </React.Fragment>
         )}
      </MainBox>
   );
}
