import {
  Alert,
  Avatar,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  StyledEngineProvider,
  TextField,
  Typography,
  styled, 
  IconButton,Tooltip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import BlockIcon from "@mui/icons-material/Block";

// import { blockUnblockContact } from "../../../services/chatService";
import userImage from '../../../../assets/images/user-img.svg';
import CloseIcon from "@mui/icons-material/Close";
import apiClient from "../../../../middleware/utils";
import { saveSelectedChat } from "../../../../store/slices/clientSlice/messageSlice";
import WarningModal from "../../common/WarningModal";
import AssignLabelModal from "../Modals/AssignLabelModal";
import { getAllChats } from "../../../../store/slices/clientSlice/chatSlice";



const styles = {
 
  modalBox: {
    width: "415px",
    height: "200px",
    backgroundColor: (theme) => theme.palette.background.modal,
    borderRadius: "8px",
    padding: "16px",
    position: "relative",
    textAlign: "center",
    "@media (max-width: 600px)": {
      width: "314px",
    },
  },
 
};

const HeaderBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.wpdivider}`,
  padding: "15px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
const LableItem = styled(Box)({
  width: 0,
  height: 0,
  borderTop: "13px solid transparent",
  borderRight: "26px solid #426a8dcf",
  borderBottom: "13px solid transparent",
});

const CustomTextField = styled(TextField)({
  focused: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "green", // Customize the focus color here
    },
  },
  backgroundColor: "#fff",
  "& .MuiInputBase-root": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "#fff" },
  },
});

const savebtn = (theme) => ({
  backgroundColor: theme.palette.primary.iconmain,
  marginTop:"2px",
  marginLeft: "15px",
  textTransform: "capitalize",
  border: "0.5px solid #0293d2",
  fontWeight: "500",
  borderRadius: "5px",
  color: theme.palette.text.whitetext,
  paddingLeft: "40px",
  paddingRight: "40px",
  boxShadow: "none !important",
  "&:hover": {
    backgroundColor: "background.iconhover",
    color: "#fff",
    border: `0.5px solid ${theme.palette.background.iconhover}`,
  },
  "@media (max-width: 600px)": {
    marginRight: 0,
    fontSize: "0.925rem",

  },
});

function ChatHeader({ selectedChat, lastMessageTime }) {
  const [name, setName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddLabel, setopenAddLabel] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [openBlockModal, setOpenModal] = useState(false);


  const { labels } = useSelector((state) => state.labels);
  const { isGroupChat } = useSelector((state) => state.chat);

  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setName(selectedChat?.name);
    const { labelCategory } = selectedChat?.label || {};
    if (labelCategory) {
      setLabelName(labelCategory);
    } else setLabelName("");
  }, [selectedChat]);

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClickProfile = (event) => {
    handleClose();
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleNameChange = (e) => setName(e.target.value);
  const handleUpdateName = async () => {
    try {
      const response = await apiClient.put(`/contacts/update-contact`, {
        chatId: selectedChat?._id,
        name: name,
      });

      if (response && response?.data?.contact) {
        toast.success("Updated successfully");
        dispatch(saveSelectedChat(response?.data?.contact));
        dispatch(getAllChats({page:1}));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBlockContact = async () => {
    const isBlock = selectedChat?.isBlocked;

    // const response = await blockUnblockContact(selectedChat?._id, !isBlock);

    // if (response.status === "success") {
    //   dispatch(saveSelectedChat(response.data));
    //   toast.success(`Contact ${isBlock ? "unblocked" : "blocked"}`);
    //   setOpenModal(false);
    // } else {
    //   toast.error("Something went wrong");
    // }
  };

  return (
    <>
      <Toaster />

      <HeaderBox>
        <Box display="flex" justifyContent="space-between">
          <Avatar
            id="basic-button"
            src={userImage}  
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              backgroundColor: "background.profilebg",
              color: "white",
              width: "45px",
              height: "45px",
              cursor:"pointer",
              '& img': {
                width: '39%;',      
                height: '49%', 
              },
            }}
          />
          <Box ml={1.8}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                color: "text.primary",
              }}
            >
              {isGroupChat? selectedChat.group_name: selectedChat?.name}
            </Typography>
            <Typography
              fontSize={selectedChat?.name ? "12px" : "16px"}
              fontWeight="300"
              sx={{ color: "text.primary", opacity: "0.6" }}
            >
              {selectedChat?.phone_number}
            </Typography>
            {selectedChat?.chat?.last_message_at && (
              <Typography fontSize="11px" fontWeight="400px" color="grey">
                Last Seen {lastMessageTime}
              </Typography>
            )}
            {/* <Button onClick={() => handleLoadMore()}>Load More</Button> */}
          </Box>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ 
              "aria-labelledby": "basic-button",
            }}
          >
            <Box >
              <Box width={350} sx={{padding:"0px 12px 19px",backgroundColor:"background.modal"}}>
                <Box p={2} display="flex" justifyContent="space-between">
                  <Typography fontWeight={500} sx={{ marginTop: "10px"}}>Contact Info</Typography>

                  <IconButton
                    sx={{
                      right: "-11px",
                      top: "-1px",
                      color: (theme) => theme.palette.grey[500],
                    }}
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>

                  {/* <CancelIcon onClick={() => setOpenProfile(false)} /> */}
                </Box>
                {/* <Box
                  width="100%"
                  height={150}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Avatar
                    sx={{
                      width: "150px",
                      height: "150px",
                    }}
                  />
                </Box> */}

                <Box
                  width="100%"
                  height={150}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Avatar
                    src={userImage}  
                    alt="User Image"
                    sx={{
                      width: "68px",        
                      height: "82px",       
                      backgroundColor: "background.profilebg", 
                      padding: "30px 38px",
                      '& img': {
                        width: '93%',      
                        height: '100%', 
                      },
                    }}
                  />
                </Box>


                <Box pl={2} pr={2} pb={2}>
                  {/* <Typography>Name</Typography> */}
                  {/* <CustomTextField
                    id="filled-start-adornment"
                    fullWidth
                    value={name} 
                    onChange={handleNameChange}
                    sx={{
                      backgroundColor: "#fff",
                      "& .MuiInputBase-root": {
                        backgroundColor: "#fff",
                        ":hover": { backgroundColor: "#fff" },
                      },
                    }}
                    variant="standard"
                  /> */}
                  <TextField
                    id="filled-start-adornment"
                    fullWidth
                    label="Name"
                    value={name} 
                    size="small"
                    onChange={handleNameChange}
                    sx={{
                      marginTop:"12px",
                      // backgroundColor: "#fff",
                      "& .MuiInputBase-root": {
                        // backgroundColor: "#fff",
                        // ":hover": { backgroundColor: "#fff" },
                      },
                    }}
                    variant="outlined"
                  />

                  {/* <Typography mt={2}>Phone Number</Typography> */}
                  {/* <CustomTextField
                    id="filled-start-adornment"
                    fullWidth
                    disabled
                    value={selectedChat?.phone_number}
                    sx={{
                      backgroundColor: "#fff",
                      "& .MuiInputBase-root": {
                        backgroundColor: "#fff",
                        ":hover": { backgroundColor: "#fff" },
                      },
                    }}
                    variant="standard"
                  /> */}
                  <TextField
                    id="filled-start-adornment"
                    label="Phone Number"
                    fullWidth
                    disabled
                    size="small"
                    value={selectedChat?.phone_number}
                    sx={{
                      marginTop:"12px",
                      // backgroundColor: "#fff",
                      "& .MuiInputBase-root": {
                        // backgroundColor: "#fff",
                        // ":hover": { backgroundColor: "#fff" },
                      },
                    }}
                    variant="outlined"
                  />

                  <Box display="flex" sx={{marginTop:"15px"}}>
                    <Typography sx={{paddingRight:"5px"}}>Category</Typography>
                    <Box display="flex">
                      {labelName && <LableItem></LableItem>}
                      {labelName && (
                        <Box height={26} width={180} bgcolor="#426a8dcf">
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "13px",
                              marginLeft: "10px",
                            }}
                          >
                            {labelName}
                          </span>
                        </Box>
                      )}
                      <Tooltip title="Edit Label" placement="top" arrow >
                        <EditIcon
                          sx={{ marginLeft: "10px", cursor: "pointer",marginTop:"0px", 
                            "&:hover": {
                            color: "#0292d0",
                          } }}
                          onClick={() => setopenAddLabel(true)}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                
                </Box>

                <Box display="flex" justifyContent="space-between">
                  {selectedChat?.name !== name && (
                    <Button sx={savebtn} onClick={handleUpdateName}>
                      Save
                    </Button>
                  )}
                  <MenuItem
                    sx={{ color: "text.block" }}
                    onClick={() => setOpenModal(true)}
                  >
                    <ListItemIcon>
                      <BlockIcon sx={{  color: "text.block" }} />
                    </ListItemIcon>
                    <ListItemText>
                      {selectedChat?.isBlocked ? "Unblock" : "Block"}{" "}
                      {selectedChat?.name || "Contact"}
                    </ListItemText>
                  </MenuItem>    
                </Box>
               
              </Box>
            </Box>
          </Menu>
        </Box>

        {/* <MoreVertIcon
          // id="basic-button"
          // aria-controls={open ? "basic-menu" : undefined}
          // aria-haspopup="true"
          // aria-expanded={open ? "true" : undefined}
          // onClick={handleClick}
          sx={{ color: (theme) => theme.palette.text.primary }}
        /> */}

        <AssignLabelModal
          openAddLabel={openAddLabel}
          setopenAddLabel={setopenAddLabel}
          selectedChat={selectedChat}
        />
        <WarningModal
          open={openBlockModal}
          setOpen={setOpenModal}
          modalName="Block/Unblock"
          modalContent={`Are you sure you want to ${
            selectedChat?.isBlocked ? "Unblock" : "Block"
          } this contact?`}
          fun={handleBlockContact}
        />

         

      </HeaderBox>
    </>
  );
}

export default ChatHeader;
