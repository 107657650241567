import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { lazy } from "react";
import NotFound from "../components/common/NotFound.js";
import NotAuthorized from "../components/common/NotAuthorized.js";
import PrivateRoute from "./privateRoutes.js";
import EditFeature from "../components/Admin/Features/EditFeature.js";
import EditSubscriptionPlan from "../components/Admin/SubscriptionPlans/EditSubscriptionPlan.js";
import WhatsappChat from "../components/Client/Inbox/WhatsappChat.js";
import AppLayout from "../components/Client/Layout/AppLayout.js";

//admin side - start
const NewLogin = lazy(() => import("../components/Admin/Login/NewLogin.js"));
const SignUp = lazy(() => import("../components/Admin/Login/SignUp.js"));
const ForgotPwd = lazy(() =>
   import("../components/Admin/Login/NewForgotPassword")
);
const ResetPwd = lazy(() =>
   import("../components/Admin/Login/NewResetPassword")
);
const OTPVerification = lazy(() =>
   import("../components/Admin/Login/OTPVerification")
);

const Dashboard = lazy(() =>
   import("../components/Admin/Dashboard/Dashboard.js")
);
const AgentMain = lazy(() =>
   import("../components/Admin/ManageAgents/AgentMain.js")
);
const AgentProfile = lazy(() =>
   import("../components/Admin/ManageAgents/AgentProfile.js")
);
const AgentDashboard = lazy(() =>
   import("../components/Admin/ManageAgents/AgentDashboard.js")
);
const ActiveClients = lazy(() =>
   import("../components/Admin/ManageAgents/ActiveClients.js")
);
const ClientDashboard = lazy(() =>
   import("../components/Admin/ManageAgents/ClientDashboard.js")
);
const TenantsMain = lazy(() =>
   import("../components/Admin/ManageTenants/TenantsMain.js")
);
const SubscriptionMain = lazy(() =>
   import("../components/Admin/SubscriptionPlans/SubscriptionMain.js")
);
const ClientCreatePlan = lazy(() =>
   import("../components/Admin/SubscriptionPlans/ClientCreatePlan.js")
);
const UserSubscriptionsMain = lazy(() =>
   import("../components/Admin/UserSubscriptions/UserSubscriptionsMain.js")
);
const PaymentMain = lazy(() =>
   import("../components/Admin/PaymentTransactions/PaymentMain.js")
);
const Permissions = lazy(() =>
   import("../components/Admin/permission/Permissions.js")
);
const UserMain = lazy(() => import("../components/Admin/users/UserMain.js"));
const AnalyticsMain = lazy(() =>
   import("../components/Admin/Analytics/AnalyticsMain.js")
);
// const ProfileMain=lazy(()=>import("../components/Admin/Profile/ProfileMain.js"))
const ChangePasswordMain = lazy(() =>
   import("../components/Admin/ChangePassword/ChangePasswordMain.js")
);
const FeaturesMain = lazy(() =>
   import("../components/Admin/Features/FeaturesMain.js")
);
//admin side - end

//agent side route - start
const DashboardAgent = lazy(() =>
   import("../components/Agent/DashboardAgent/DashboardAgent.js")
);
const ManageClientsMain = lazy(() =>
   import("../components/Agent/ManageClients/ManageClientsMain.js")
);
const SubscriptionPlanMain = lazy(() =>
   import("../components/Agent/SubscriptionPlans/SubscriptionPlanMain.js")
);
const PaymentTransactionsMain = lazy(() =>
   import("../components/Agent/PaymentTransactions/PaymentTransactionsMain.js")
);
const RoleMain = lazy(() =>
   import("../components/Agent/RolesPermissions/RoleMain.js")
);
const ManageUserMain = lazy(() =>
   import("../components/Agent/ManageUsers/ManageUserMain.js")
);
const TicketsMain = lazy(() =>
   import("../components/Agent/Tickets/TicketsMain.js")
);
const AnalyticsMainAgent = lazy(() =>
   import("../components/Agent/Analytics-Reports/AnalyticsMainAgent.js")
);
const PasswordChangeMain = lazy(() =>
   import("../components/Agent/PasswordChange/PasswordChangeMain.js")
);
//agent side route - end

//client side route -start
const DashboardClient = lazy(() =>
   import("../components/Client/Dashboard/ClientDashBoard.js")
);
//client side route - end

const Body = () => {
   const theme = useTheme();

   const appRouter = createBrowserRouter([
      //Server side Routes - start
      {
         path: "/signup",
         element: <SignUp />,
      },
      {
         path: "/",
         element: <NewLogin />,
      },
      {
         path: "/ForgotPassword",
         element: <ForgotPwd />,
      },
      {
         path: "/ResetPassword",
         element: <ResetPwd />,
      },
      {
         path: "/OTPVerification",
         element: <OTPVerification />,
      },
      //Server side Routes
      {
         path: "/Admin-Dashboard",
         element: (
            <PrivateRoute>
               <Dashboard />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/Manage-Agents",
         element: (
            <PrivateRoute privilegeKey="View_Agent">
               <AgentMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/Agent-Profile",
         element: (
            <PrivateRoute>
               <AgentProfile />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/Agent-Dashboard",
         element: (
            <PrivateRoute>
               <AgentDashboard />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/Active-Clients/:id",
         element: (
            <PrivateRoute>
               <ActiveClients />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/Client-Dashboard/:id",
         element: (
            <PrivateRoute>
               <ClientDashboard />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/Manage-Tenants",
         element: (
            <PrivateRoute privilegeKey="View_Tenant">
               <TenantsMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/Subscription-Plans",
         element: (
            <PrivateRoute privilegeKey="View_Plan">
               <SubscriptionMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/EditSubscription-Plan",
         element: (
            <PrivateRoute privilegeKey="View_Plan">
               <EditSubscriptionPlan />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/client-createplan",
         element: (
            <PrivateRoute>
               <ClientCreatePlan />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/User-Subscriptions",
         element: (
            <PrivateRoute>
               <UserSubscriptionsMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/Payment-Transactions",
         element: (
            <PrivateRoute privilegeKey="View_Payment">
               <PaymentMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/permissions",
         element: (
            <PrivateRoute privilegeKey="View_Roles">
               <Permissions />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/users",
         element: (
            <PrivateRoute privilegeKey="View_Users">
               <UserMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/analytics-reports",
         element: (
            <PrivateRoute privilegeKey="View_Reports">
               <AnalyticsMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/features",
         element: (
            <PrivateRoute privilegeKey="View_Reports">
               <FeaturesMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Admin-Dashboard/edit-feature",
         element: (
            <PrivateRoute privilegeKey="View_Reports">
               <EditFeature />
            </PrivateRoute>
         ),
      },
      // {
      //   path: "/profile",
      //   element: <ProfileMain />,
      // },
      //
      {
         path: "/Admin-Dashboard/change-password",
         element: (
            <PrivateRoute>
               <ChangePasswordMain />
            </PrivateRoute>
         ),
      },

      {
         path: "/not-authorized",
         element: <NotAuthorized />,
      },
      {
         path: "*",
         element: <NotFound />,
      },
      //Server side Routes - end

      //Agent side Routes - start
      {
         path: "/Agent-Dashboard/Dashboard-Agent",
         element: (
            <PrivateRoute>
               <DashboardAgent />
            </PrivateRoute>
         ),
      },
      {
         path: "/Agent-Dashboard/Manage-Clients",
         element: (
            <PrivateRoute privilegeKey="View_Client">
               <ManageClientsMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Agent-Dashboard/Subscription-Plans",
         element: (
            <PrivateRoute privilegeKey="View_Plan">
               <SubscriptionPlanMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Agent-Dashboard/PaymentTransactions",
         element: (
            <PrivateRoute privilegeKey="View_Payment">
               <PaymentTransactionsMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Agent-Dashboard/Role",
         element: (
            <PrivateRoute privilegeKey="View_Roles">
               <RoleMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Agent-Dashboard/ManageUser",
         element: (
            <PrivateRoute privilegeKey="View_Users">
               <ManageUserMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Agent-Dashboard/Tickets",
         element: (
            <PrivateRoute privilegeKey="View_Tickets">
               <TicketsMain />
            </PrivateRoute>
         ),
      },
      {
         path: "/Agent-Dashboard/Analytics-Reports-Agent",
         element: (
            <PrivateRoute privilegeKey="View_Reports">
               <AnalyticsMainAgent />
            </PrivateRoute>
         ),
      },
      {
         path: "/Agent-Dashboard/change-password",
         element: (
            <PrivateRoute>
               <PasswordChangeMain />
            </PrivateRoute>
         ),
      },
      //Agent side Routes - end

      //Client side Routes -start
      {
         path: "/",
         element: <AppLayout />, // Use Layout here
         children: [
            {
               path: "/dashboard",
               element: (
                  <PrivateRoute privilegeKey="View_Reports">
                     <DashboardClient />
                  </PrivateRoute>
               ),
            },
            {
               path: "/chat",
               element: <WhatsappChat />,
            },

            //  {
            //     path: "/contacts",
            //     element: <Contacts />,
            //  },
            //  {
            //     path: "/campaign/new-campaign",
            //     element: <Campaigns />,
            //  },
            //  {
            //     path: "/templates",
            //     element: <TemplatesList />,
            //  },
            //  {
            //     path: "/templates/create-template",
            //     element: <CreateTemplate />,
            //  },
            //  {
            //     path: "/edit-template/:id",
            //     element: <EditTemplates />,
            //  },
            //  {
            //     path: "/bulk-send",
            //  },
            //  {
            //     path: "/campaign/report",
            //     element: <BulkSendReport />,
            //  },

            //  {
            //     path: "/bulk-send-view/:id",
            //     element: <BulkSendDetail />,
            //  },
            //  {
            //     path: "/insights",
            //     element: <Insights />,
            //  },
            //  {
            //     path: "/insights/broadcasts",
            //     element: <Broadcasts />,
            //  },
            //  {
            //     path: "/insights/conversations",
            //     element: <Conversations />,
            //  },
            //  {
            //     path: "/insights/messages",
            //     element: <Messages />,
            //  },
            //  {
            //     path: "/settings/conversation-labels",
            //     element: <ConverSationLabels />,
            //  },
            //  {
            //     path: "/settings/groups",
            //     element: <Groups />,
            //  },
            //  {
            //     path: "/templates/scheduled-messages",
            //     element: <SheduledBulkSend />,
            //  },
            //  {
            //     path: "/channels/whatsapp-channel",
            //     element: <WpChannel />,
            //  },
            //  {
            //     path: "/permissions",
            //     element: <Permissions />,
            //  },
            //  {
            //     path: "/users",
            //     element: <UserMain />,
            //  },
            //  {
            //     path: "/channels/whatsapp-business-account",
            //     element: <WpBusinessAccount />,
            //  },
         ],
      },

      //Client side end
   ]);
   return (
      <div style={{ backgroundColor: theme.palette.background.body }}>
         <RouterProvider router={appRouter} />
      </div>
   );
};

export default Body;
