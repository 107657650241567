import { Box, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import ChatMainContainer from "./ChatMainContainer";
import { useDispatch } from "react-redux";
import { getAllChats } from "../../../store/slices/clientSlice/chatSlice";

const TitleBox = styled(Box)({
   display: "flex",
   justifyContent: "space-between",
   alignItems: "center",
   height: "15px",
   margin: "15px 5px 15px 5px",
});

function WhatsappContainer() {
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(getAllChats({ page: 1 }));
   }, [dispatch]);

   return (
      <Box
         mt={8}
         sx={{
            backgroundColor: "#000",
            width: "100%",
            height: "90vh",
         }}
      >
         <ChatMainContainer />
      </Box>
   );
}

export default WhatsappContainer;
