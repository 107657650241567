import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../../middleware/utils";

export const getAllContacts = createAsyncThunk(
   "contacts/getAllContacts",
   async ({ searchKey = "", page = 1 }) => {
      const params = {
         page,
      };

      // Only include the search parameter if searchKey is provided
      if (searchKey) {
         params.search = searchKey;
      }
      try {
         const response = await apiClient.get(`/contacts`, { params });
         return response.data;
      } catch (err) {
         throw err;
      }
   }
);

const initialState = {
   contacts: [],
   page: 1,
   loading: null,
   error: null,
   isLoadMore: false,
};
const contactsSlice = createSlice({
   name: "contacts",
   initialState,
   reducers: {},
   extraReducers: (builder) =>
      builder
         .addCase(getAllContacts.pending, (state) => {
            state.loading = true;
         })
         .addCase(getAllContacts.fulfilled, (state, action) => {
            const { contacts, currentPage, totalContact, isLoadMore } =
               action.payload;

            if (currentPage === 1) {
               state.contacts = contacts;
            } else {
               state.contacts = [...state.contacts, ...contacts];
            }
            state.loading = false;
            state.page = currentPage;
            state.isLoadMore = isLoadMore;
            state.error = null;
         })
         .addCase(getAllContacts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         }),
});

export default contactsSlice.reducer;
