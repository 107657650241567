import CryptoJS from 'crypto-js';
const secretKey = process.env.REACT_APP_SECRET_KEY;

export const getUserToken = () => {
    const encryptedDataFromStorage = localStorage.getItem('token') || null;
    let token;
    if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
        token = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return token;
};

export const getTenantID = () => {
    const encryptedDataFromStorage = localStorage.getItem('tenantID') || null;
    let tenantID;
    if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
        tenantID = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return tenantID;
};

export const getRoleDashboard = () => {
    const encryptedDataFromStorage = localStorage.getItem('roledashboard') || null;
    let roledashboard;
    if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
        roledashboard = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return roledashboard;
};

export const getClientID = () => {
    const encryptedDataFromStorage = localStorage.getItem('clientID') || null;
    let clientID;
    if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
        clientID = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return clientID;
};

export const getAgentID = () => {
    const encryptedDataFromStorage = localStorage.getItem('agentID') || null;
    let agentID;
    if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
        agentID = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return agentID;
};

export const getClientName = () => {
    const encryptedDataFromStorage = localStorage.getItem('clientName') || null;
    let clientName;
    if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
        clientName = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return clientName;
};

export const getPrivileges = () => {
    const encryptedDataFromStorage = localStorage.getItem('privileges') || null;
    let privileges;
    if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
        privileges = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return privileges;
};

export const getAuth = () => {
    const encryptedDataFromStorage = localStorage.getItem('isAuthenticated') || null;
    let auth;
    if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
        auth = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return auth;
};