import {
   Box,
   Button,
   Checkbox,
   List,
   ListItem,
   ListItemButton,
   CircularProgress,
   ListItemIcon,
   ListItemText,
   Typography,
   styled,
   Tabs,
   Tab,
   Avatar,
   InputAdornment,
   TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import toast, { Toaster } from "react-hot-toast";
import { Search as SearchIcon } from "@mui/icons-material";

import Papa from "papaparse";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getAllContacts } from "../../../../store/slices/clientSlice/contactSlice";
import { handleDownload } from "../../../../utils/helperFuncion";

const csvbtnStyle = (theme) => ({
   width: "200px",
   backgroundColor: theme.palette.background.bgbutton,
   textTransform: "capitalize",
   border: "0.5px solid #0293d2",
   color: "#0293d2",
   paddingLeft: "22px",
   paddingRight: "22px",
   borderRadius: "5px",
   "&:hover": {
      backgroundColor: "#0293d2",
      color: "#fff",
      border: "0.5px solid #0293d2",
   },
});

const VisuallyHiddenInput = styled("input")({
   clip: "rect(0 0 0 0)",
   clipPath: "inset(50%)",
   height: 1,
   overflow: "hidden",
   position: "absolute",
   bottom: 0,
   left: 0,
   whiteSpace: "nowrap",
   width: 1,
});

function StepperListContact({
   allContacts,
   selectedContacts,
   setSelectedContacts,
   groups,
   setSelectedGroups,
   selectedGroups,
   // handleGroupSelection
}) {
   const theme = useTheme();
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const [value, setValue] = React.useState("one");
   const [searchText, setSearchText] = useState("");
   const { isLoadMore, page } = useSelector((state) => state.contacts);

   const handleChange = (event, newValue) => {
      setValue(newValue);
      setSelectedContacts([]);
   };

   const handleSearchChange = (e) => {
      setSearchText(e.target.value);
   };

   useEffect(() => {
      //Api will call only the the search text >2 letter
      // if (searchText.length < 2) return;

      //Timer function will call the search api if two letter typing gap is less than 200ms
      // setLoading(true);
      const timerId = setTimeout(async () => {
         // const response = await searchContactAPI(searchText);
         dispatch(getAllContacts({ searchKey: searchText, page: 1 }));
         // setLoading(false);

         //Storing the result into state in the index page
         // dispatch(setSearchedChat(response.result));
         // setSearchResult(result);
      }, 200);

      //Clearing the timer fuction
      return () => clearTimeout(timerId);
      // eslint-disable-next-line
   }, [searchText]);

   // const handleFileChange = (event) => {
   //    // Handle selected file(s) here
   //    const file = event.target.files[0];
   //    console.log(file)
   //    const reader = new FileReader();
   //    reader.onload = (event) => {
   //       const text = event.target.result;
   //       let parsedData = Papa.parse(text, { header: true }).data;
   //       parsedData = parsedData.filter((item) => item.Contacts);
   //       setSelectedContacts(parsedData.map((contact) => contact.Contacts));
   //    };

   //    reader.readAsText(file);

   //    // handleFileUpload(file);
   // };

   const handleFileChange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      setLoading(true)
      
      reader.onload = (event) => {
         const text = event.target.result;
   
         Papa.parse(text, {
            header: true,
            worker: true,  // Parse in a separate worker thread
            complete: (results) => {
               let parsedData = results.data.filter((item) => item.Contacts);
               setSelectedContacts(parsedData.map((contact) => contact.Contacts));
               setLoading(false)
            },
            error: (error) => {
               console.error("Error parsing CSV:", error);
               toast.error('Failed to upload contacts')
               setLoading(false)
            }
         });
      };

   
      reader.readAsText(file);
   };

   // function to handle select contacts from list
   const handleToggle = (value) => () => {
      const currentIndex = selectedContacts.indexOf(value);
      const newChecked = [...selectedContacts];

      if (currentIndex === -1) {
         newChecked.push(value);
      } else {
         newChecked.splice(currentIndex, 1);
      }

      setSelectedContacts(newChecked);
   };

   const handleGroupSelection = (newChecked) => {
      let updatedContacts = [...selectedContacts];

      newChecked.forEach((groupId) => {
         const group = groups.find((group) => group._id === groupId);

         if (group) {
            // Check if group contacts are already in the selectedContacts array
            const groupContactsExist = group.contacts.every((contact) =>
               selectedContacts.includes(contact)
            );

            if (groupContactsExist) {
               // If all group contacts exist, remove them
               updatedContacts = updatedContacts.filter(
                  (contact) => !group.contacts.includes(contact)
               );
            } else {
               // If not all group contacts exist, add them
               updatedContacts = [...updatedContacts, ...group.contacts];
            }
         }
      });

      setSelectedContacts(updatedContacts);
   };
   const handleGroupToggle = (value) => () => {
      const currentIndex = selectedGroups.indexOf(value);
      const newChecked = [...selectedGroups];

      if (currentIndex === -1) {
         newChecked.push(value);
      } else {
         newChecked.splice(currentIndex, 1);
      }

      setSelectedGroups(newChecked);
      handleGroupSelection(newChecked);

   };
   // Function to load more contacts
   const handleLoadMoreContact = () => {
      const pageNum = page + 1;

      dispatch(getAllContacts({ searchKey: searchText, page: pageNum }));
   };
   return (
      <>
         <Toaster />

         <Tabs
            value={value}
            onChange={handleChange}
            sx={{ color: "primary.main" }}
            indicatorColor="primary"
            aria-label="secondary tabs example"
         >
            <Tab
               value="one"
               sx={{ color: "primary.main", fontWeight: "fontWeightMedium" }}
               label="Upload csv"
            />
            <Tab
               value="two"
               sx={{ color: "primary.main", fontWeight: "fontWeightMedium" }}
               label="Select from Contact list"
            />
            <Tab
               value="three"
               sx={{ color: "primary.main", fontWeight: "fontWeightMedium" }}
               label="Select groups"
            />
         </Tabs>

         <Box>
            {value === "one" && (
               <Box
                  sx={{
                     backgroundColor: theme.palette.background.backgroundgrey,
                  }}
                  minHeight={120}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={2}
               >
                  {selectedContacts.length > 0 ? (
                     <Box>
                        {/* {selectedContacts.slice(0, 5).map((contact) => (
                           <>
                              <Typography> {contact}</Typography>
                              <hr />
                           </>
                        ))}
                        {selectedContacts.length > 5 && (
                           <Typography>...</Typography>
                        )} */}
                        <Typography variant="body1" color="primary.main">
                           Uploaded Contacts: {selectedContacts.length}
                        </Typography>
                     </Box>
                  ) : (
                     <>
                        <Button
                           sx={csvbtnStyle(theme)}
                           component="label"
                           role={undefined}
                           variant="outlined"
                           disabled={loading ? true : false}
                           tabIndex={-1}
                           startIcon={<CloudUploadIcon />}
                        >
                           {loading ? (
                              <>
                                 {"Uploading.. "}
                                 <CircularProgress
                                    style={{
                                       height: "24px",
                                       width: "24px",
                                       color: "grey",
                                    }}
                                 />
                              </>
                           ) : (
                              "Upload .csv file"
                           )}
                           <VisuallyHiddenInput
                              type="file"
                              accept={".csv"}
                              onChange={handleFileChange}
                           />
                        </Button>
                        <Button
                           variant="text"
                           sx={{
                              width: "200px",
                              color: "primary.main",
                              borderRadius: "5px",
                              border: "0.5px solid transparent",
                              "&:hover": {
                                 color: "white",
                              },
                           }}
                           onClick={() => handleDownload()}
                        >
                           Download .csv Sample
                        </Button>
                     </>
                  )}
               </Box>
            )}
            {value === "two" && (
               <Box
                  sx={{
                     backgroundColor: theme.palette.background.backgroundgrey,
                  }}
               >
                  <Box
                     display="flex"
                     alignItems="center"
                     justifyContent="space-between"
                     mt={1}
                     p={3}
                     // borderRadius="20px"
                     sx={{
                        backgroundColor:
                           theme.palette.background.backgroundgrey,
                     }}
                  >
                     <TextField
                        sx={{
                           color: "#fff",
                           backgroundColor: "background.whatsappsearch",
                           borderRadius: "20px",
                           "& .MuiOutlinedInput-root": {
                              borderRadius: "20px",
                           },
                        }}
                        placeholder="Search..."
                        //  variant="standard"
                        fullWidth
                        size="small"
                        value={searchText}
                        onChange={handleSearchChange}
                        // onKeyPress={handleKeyPress}
                        startIcon={
                           <SearchIcon
                              sx={{
                                 color: "inherit",
                                 "&:hover": {
                                    color: "#0292d0",
                                 },
                              }}
                           />
                        }
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end"></InputAdornment>
                           ),
                        }}
                     />
                     {/* <Box><img src={SortIcon} alt="sort" /></Box> */}
                  </Box>
                  <Box
                     sx={{
                        backgroundColor:
                           theme.palette.background.backgroundgrey,
                     }}
                     maxHeight={280}
                     overflow="scroll"
                  >
                     <List
                        sx={{
                           width: "100%",

                           // bgcolor: "background.paper",
                        }}
                     >
                        {allContacts?.map((value) => {
                           const labelId = `checkbox-list-label-${value}`;

                           return (
                              <ListItem key={value.phone_number} disablePadding>
                                 <ListItemButton
                                    role={undefined}
                                    onClick={handleToggle(value.phone_number)}
                                    dense
                                 >
                                    <ListItemIcon sx={{}}>
                                       <Avatar />
                                    </ListItemIcon>
                                    <ListItemText
                                       sx={{ color: "text.primary" }}
                                       id={labelId}
                                       primary={
                                          value.name || value.phone_number
                                       }
                                    />
                                    <ListItemIcon>
                                       <Checkbox
                                          edge="start"
                                          checked={
                                             selectedContacts.indexOf(
                                                value.phone_number
                                             ) !== -1
                                          }
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{
                                             "aria-labelledby":
                                                value.phone_number,
                                          }}
                                       />
                                    </ListItemIcon>
                                 </ListItemButton>
                              </ListItem>
                           );
                        })}
                     </List>
                  </Box>
                  <Box
                     width="100%"
                     display="flex"
                     justifyContent="end"
                     bgcolor="background.body"
                     p={1}
                  >
                     {allContacts?.length >= 10 && (
                        <Button
                           variant="outlined"
                           sx={{ marginRight: "5px" }}
                           onClick={handleLoadMoreContact}
                        >
                           Load more ...
                        </Button>
                     )}
                  </Box>
               </Box>
            )}

            {value === "three" && (
               <Box
                  sx={{
                     backgroundColor: theme.palette.background.backgroundgrey,
                  }}
                  maxHeight={280}
                  overflow="scroll"
               >
                  <List
                     sx={{
                        width: "100%",

                        // bgcolor: "background.paper",
                     }}
                  >
                     {groups?.map((value) => {
                        const labelId = `checkbox-list-label-${value._id}`;

                        return (
                           <ListItem key={value.phone_number} disablePadding>
                              <ListItemButton
                                 role={undefined}
                                 onClick={handleGroupToggle(value._id)}
                                 dense
                              >
                                 <ListItemIcon sx={{}}>
                                    <Avatar />
                                 </ListItemIcon>
                                 <ListItemText
                                    sx={{ color: "text.primary" }}
                                    id={labelId}
                                    primary={value.group_name}
                                 />
                                 <ListItemIcon>
                                    <Checkbox
                                       edge="start"
                                       checked={
                                          selectedGroups.indexOf(value._id) !==
                                          -1
                                       }
                                       tabIndex={-1}
                                       disableRipple
                                       inputProps={{
                                          "aria-labelledby": value._id,
                                       }}
                                    />
                                 </ListItemIcon>
                              </ListItemButton>
                           </ListItem>
                        );
                     })}
                  </List>
               </Box>
            )}
         </Box>
      </>
   );
}

export default StepperListContact;
